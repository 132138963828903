import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@app/shared/services/translate.service';
import { WebApplicationConfigurationService } from '@app/shared/services/web-application-configuration.service';
import { environment as env } from '@env/environment';
import { LangChangeEvent } from '@ngx-translate/core';
import { sprintf } from 'sprintf-js';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  termsAndConditionsUrl: string;

  currentYear: string;

  version = env.buildVersion;

  buildDate = env.buildDate;

  constructor(private translateService: TranslateService,
    private webApplicationConfigurationService: WebApplicationConfigurationService) {

  }

  logVersion() {
    throw new Error(this.version);
  }

  ngOnInit(): void {
    this.currentYear = this.buildDate.substr(0, 4);

    this.translateService.onLangChange.subscribe((params: LangChangeEvent) => {
      this.setTermsAndConditionsUrl(params.lang);
    });
  }

  setTermsAndConditionsUrl(lang: string) {
    const selectedLang: string = lang.substr(0, 2).toLocaleUpperCase();
    this.webApplicationConfigurationService.getPublicPropertyValue('public.terms-and-conditions.url', null)
      .then(termsAndConditionsUrl => {
        this.termsAndConditionsUrl = sprintf(termsAndConditionsUrl, selectedLang);
      });
  }
}
