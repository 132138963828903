import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@app/shared/services/translate.service';
import { WebApplicationConfigurationService } from '@app/shared/services/web-application-configuration.service';
import { LangChangeEvent } from '@ngx-translate/core';
import { parse } from 'rss-to-json';
import { sprintf } from 'sprintf-js';

@Component({
  selector: 'app-shared-rss-alerts',
  templateUrl: './rss-alerts.component.html',
  styleUrls: ['./rss-alerts.component.scss']
})
export class RSSAlertsComponent implements OnInit {

  public rss: {
    title: any;
    description: any;
    link: any;
    image: any;
    category: any;
    items: any[];
  };

  private static readonly rssFeedURLpropertyName = 'public.alerts.rss.feed.url';
  private static readonly rssFeedPropertyPrefix = 'public.alerts.rss.feed';
  private static readonly rssUrlParamsPropertyName = 'public.alerts.rss.feed.url.parameters';
  private static readonly LANG_PARAM = 'lang';

  private readonly rssParametersMap: Map<string, string> = new Map();
  private readonly rssParametersDefaultValueMap: Map<string, string> = new Map();
  private readonly rssParametersMapping: Map<string, Map<string, string>> = new Map();


  constructor(
    private webApplicationConfigurationService: WebApplicationConfigurationService,
    private translateService: TranslateService) {
  }

  ngOnInit(): void {
    if (this.translateService.currentLang) {
      this.loadRssFeed(this.translateService.currentLang);
    }
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.loadRssFeed(event.lang);
    });
  }

  private loadRssFeed(lang: string) {
    this.webApplicationConfigurationService.getPublicPropertiesValueByPrefix(RSSAlertsComponent.rssFeedPropertyPrefix)
      .then((publicPropertiesMap) => {
        parse(this.getRssURL(publicPropertiesMap, lang.substring(0, 2)), {})
          .then((rss) => {
            this.rss = rss;
          });
      });
  }

  private getRssURL(publicProperitiesMap: Map<string, string>, currentUserLang?: string): string {
    if (publicProperitiesMap == null) {
      console.error('RSS configuration is not available.');
      return;
    }
    this.parsePropertyParams(publicProperitiesMap);
    let userLang = currentUserLang ? currentUserLang : this.translateService.currentLang ? this.translateService.currentLang.substr(0, 2) : null;

    const url = sprintf(publicProperitiesMap.get(RSSAlertsComponent.rssFeedURLpropertyName), this.getLanguageFromParameters(userLang));
    return url;
  }

  private getLanguageFromParameters(userLang: string): string | never {
    for (let entry of this.rssParametersMapping.get(RSSAlertsComponent.LANG_PARAM)) { // check the mapping
      if (userLang === entry[0]) {
        userLang = entry[1];
      }
    }
    if (this.rssParametersMap.get(RSSAlertsComponent.LANG_PARAM).split(',').includes(userLang)) {
      return userLang;
    } else if (this.rssParametersDefaultValueMap.has(RSSAlertsComponent.LANG_PARAM)) {
      return this.rssParametersDefaultValueMap.get(RSSAlertsComponent.LANG_PARAM);
    } else {
      throw new Error(`User language ${userLang} doesn't exist in the list of supported RSS languages.`);
    }
  }

  private parsePropertyParams(publicProperitiesMap: Map<string, string>) {
    if (publicProperitiesMap.has(RSSAlertsComponent.rssUrlParamsPropertyName)) {
      let paramNamesArray: string[] = publicProperitiesMap.get(RSSAlertsComponent.rssUrlParamsPropertyName).split(',');
      paramNamesArray.forEach(parameterName => {
        let paramNamePrefix = RSSAlertsComponent.rssFeedPropertyPrefix.concat(".", parameterName);
        let paramValueName = paramNamePrefix + '.values';
        let paramDefaultValueName = paramNamePrefix + '.defaultValue';
        if (publicProperitiesMap.has(paramValueName)) {
          this.rssParametersMap.set(parameterName, publicProperitiesMap.get(paramValueName));
        }
        if (publicProperitiesMap.has(paramDefaultValueName)) {
          this.rssParametersDefaultValueMap.set(parameterName, publicProperitiesMap.get(paramDefaultValueName));
        }
        //check if we have mapping for parameters
        let paramMappingPrefix = paramNamePrefix + '.mapping.';
        for (const [propertyName, propertyValue] of publicProperitiesMap.entries()) {
          if (propertyName.includes(paramMappingPrefix)) {
            let mapParamName = propertyName.substring(propertyName.lastIndexOf('.') + 1);
            if (this.rssParametersMapping.has(parameterName)) {
              this.rssParametersMapping.get(parameterName).set(mapParamName, propertyValue);
            } else {
              this.rssParametersMapping.set(parameterName, new Map([[mapParamName, propertyValue]]));
            }
          }
        }
      })
    }

  }




}
