import { Component, OnInit } from '@angular/core';
import { LangChangeEvent } from '@ngx-translate/core';
import { ErrorService } from './core/services/error.service';
import { MatomoService } from './core/services/matomo.service';
import { PosthogService } from './core/services/posthog.service';
import { TranslateService } from './shared/services/translate.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'tirepd-ng';
  isLoading = true;

  constructor(private translate: TranslateService,
    private errorService: ErrorService,
    private posthogService: PosthogService,
    private matomoService: MatomoService) {

    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en-GB');

    // clear the error box
    errorService.clear();

    // initialise PostHog Analytics
    posthogService.init();
    // initialise Matomo Analytics
    matomoService.init();
  }

  ngOnInit(): void {

    this.translate.onLangChange.subscribe((params: LangChangeEvent) => {
      this.isLoading = false;

    });

  }
}
