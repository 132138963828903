/**
 * TIR-EPD REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tirepd@iru.org
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Corridor for Transit Guarantee
 */
export interface TransitGuaranteeCorridor { 
    type?: TransitGuaranteeCorridor.TypeEnum;
    countryCodes?: Array<string>;
}
export namespace TransitGuaranteeCorridor {
    export type TypeEnum = 'one-way' | 'two-way';
    export const TypeEnum = {
        OneWay: 'one-way' as TypeEnum,
        TwoWay: 'two-way' as TypeEnum
    };
}


