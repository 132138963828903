<mat-card class="login-card">
  <mat-card-header>
    <mat-card-title>
      {{ 'login' | trackAndTranslate }}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="login-card-content">
    <div fxLayoutAlign="center center">
      <button mat-raised-button mat-button color="primary" type="button" type="submit" (click)="login()"
        [ngClass]="{'spinner': loading}" [disabled]="loading">
        <ng-container>{{ 'button.login' | trackAndTranslate }}</ng-container>
      </button>
    </div>
  </mat-card-content>
</mat-card>
