
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// set the idp before the application start and instanciate the auth-provider
const urlSearchParams = new URLSearchParams(window.location.search);
const idp = urlSearchParams.get('i');
if (idp) {
  sessionStorage.setItem('idp', idp);
}


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
