import { MarkedRenderer } from 'ngx-markdown';
import { take } from 'rxjs/operators';
import { MarkdownWebApplicationConfigurationService } from '../services/markdown-web-application-configuration.service';

export class MarkdownRenderer extends MarkedRenderer {

    private selfTargetPattern: RegExp;

    constructor(private markdownWebApplicationConfigurationService: MarkdownWebApplicationConfigurationService) {
        super();

        this.markdownWebApplicationConfigurationService.linkTargetSelfUrlpattern
            .pipe(take(1))
            .subscribe(value => {
                if (value) {
                    this.selfTargetPattern = new RegExp(value);
                }
            });

    }


    public link(href: string | null, title: string | null, text: string): string {
        return `<a href="${href || ''}" target='${href.match(this.selfTargetPattern) ? '_self' : '_blank'}' >${text}</a>`;
    }

    /*
    * replace :something|cssclass: by a material icon named "something" with the class "cssclass"
    * Example : :warning|warn: will be replaced by <i class="material-icons warn">warning</i>
    */
    public text(text: string): string {
        const pattern = /:([\w]+)(\|([\w]+))?:/g;
        return text.replace(pattern, '<i class="material-icons $3" color="accent">$1</i>');
    }
}
