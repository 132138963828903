/**
 * TIR-EPD REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tirepd@iru.org
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SellerType = 'iru' | 'association' | 'tobbund';

export const SellerType = {
    Iru: 'iru' as SellerType,
    Association: 'association' as SellerType,
    Tobbund: 'tobbund' as SellerType
};

