import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthAuthService } from '@app/core/services/auth-auth.service';
import { RedirectAfterLoginService } from '@app/core/services/redirect-after-login.service';
import { environment } from '@env/environment';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  error: string;

  constructor(
    private router: Router,
    private authAuthService: AuthAuthService,
    private redirectAfterLoginService: RedirectAfterLoginService
  ) {

  }

  ngOnInit(): void {
    this.authAuthService.inAppLogin()
      .then(() => {
        const isAuthenticated = this.authAuthService.hasValidAccessToken() && this.authAuthService.hasValidIdToken();
        if (isAuthenticated) {
          if (environment.debug) {
            console.log(`isAuthenticated=${isAuthenticated}`);
          }
          this.router.navigateByUrl('/home');
        } else if (this.redirectAfterLoginService.readRouterUrl()) {
          this.authAuthService.login();
        }
      });

  }

}
