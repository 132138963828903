/**
 * T REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: it_helpdesk@iru.org
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Identifies the type of the document.
 */
export type TransitMovementSearchSort = 'id';

export const TransitMovementSearchSort = {
    Id: 'id' as TransitMovementSearchSort
};

