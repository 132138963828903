import env from '../../target/filtered/environments/buildenv';

export const environment = {
  debug: false,
  autoSelectScope: true,
  production: true,
  API_BASE_PATH: '/tirepd-api',
  T_API_BASE_PATH: '/t-api',
  deploymentContextPath: '/tirepd', // fill during the build
  buildVersion: env.buildVersion,
  buildDate: env.buildDate,
  legacyApplicationPortNumber: 0,
  envName: 'PROD'
};
