import { Injectable } from '@angular/core';

import {
    AccessToken,
    FilterProfile,
    TenantProfiles,
    TokenExchangeResponse,
    TokenExchangeService,
    UserProfiles
} from '@api/sts/index';

import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { WebApplicationConfigurationService } from '@app/shared/services/web-application-configuration.service';
import { OAuthErrorEvent, OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import jwt_decode from 'jwt-decode';
import { environment } from '../../../environments/environment';
import { SelectScopeDialogComponent } from '../components/select-scope/select-scope-dialog.component';
import { MatomoService } from './matomo.service';
import { PosthogService } from './posthog.service';
import { StsApplicationTokenCacheService } from './sts-application-token-cache.service';
import { UserContextService } from './user-context.service';
import { Feature, FeatureService } from '@app/shared/services/feature.service';
import { SettingsService as TSettingsService } from '@api/t/index';
import { forkJoin, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

export interface UserProfile {
    username: string;
    displayName: string;
    emailAddress: string;
    tenant: string;
    filter?: string;
    profile: string[] | undefined;
}



@Injectable({
    providedIn: 'root'
})
export class AuthAuthService {

    public constructor(private oauthService: OAuthService,
        private oauthStorage: OAuthStorage,
        private tokenExchangeService: TokenExchangeService,
        private webApplicationConfigurationService: WebApplicationConfigurationService,
        private userContextService: UserContextService,
        private featureService: FeatureService,
        private tSettingsService: TSettingsService,
        private router: Router,
        private location: Location,
        private stsApplicationTokenCacheService: StsApplicationTokenCacheService,
        private posthogService: PosthogService,
        private matomoService: MatomoService,
        public dialog: MatDialog
    ) {
        this.oauthService.events.subscribe(async event => {
            if (event instanceof OAuthErrorEvent) {
                await this.oauthService.revokeTokenAndLogout();
            } else if (event.type === 'token_received') {
                if (this.oauthStorage.getItem('api_access_token')) {
                    await this.exchangeToken();
                    this.stsApplicationTokenCacheService.clearTokenCache();
                }
            }
        });
    }

    public hasValidApiAccessToken(): boolean {
        return !!this.oauthStorage.getItem('api_access_token');
    }

    public hasValidAccessToken(): boolean {
        return this.oauthService.hasValidAccessToken();
    }

    public hasValidIdToken(): boolean {
        return this.oauthService.hasValidIdToken();
    }

    private cleanOauthStorageBeforeLogin(): void {
        this.oauthStorage.removeItem('scopes');
        this.oauthStorage.removeItem('api_access_token');
        this.oauthStorage.removeItem('api_access_token_obj');
    }

    public async login(): Promise<boolean> {
        this.cleanOauthStorageBeforeLogin();
        return this.oauthService.loadDiscoveryDocumentAndLogin();
    }

    public async inAppLogin(): Promise<boolean> {
        this.cleanOauthStorageBeforeLogin();
        return this.oauthService.loadDiscoveryDocumentAndTryLogin();
    }

    get identityClaims(): any {
        return this.oauthService.getIdentityClaims();
    }

    public logout(redirectUrl = 'public/login'): void {
        this.oauthStorage.removeItem('userProfile');
        this.oauthStorage.removeItem('scope');
        sessionStorage.removeItem('idp');


        this.oauthService.logoutUrl = this.getPostLogoutRedirectURI(redirectUrl);
        this.oauthService.revokeTokenAndLogout({}, true);

        this.posthogService.reset();
        this.matomoService.reset();
    }

    public getPostLogoutRedirectURI(uri: string): string {
        return window.location.origin + this.location.prepareExternalUrl(this.router.createUrlTree([uri]).toString());
    }

    public invalidateApiAccessToken(): void {
        this.oauthStorage.removeItem('api_access_token');
        this.oauthStorage.removeItem('api_access_token_obj');
    }

    public async exchangeToken(): Promise<boolean> {
        try {
            await this.webApplicationConfigurationService.getPublicPropertyValue('public.authorization-config.audience', null)
                .then(async audience => {
                    const response: TokenExchangeResponse = await this.tokenExchangeService.exchangeTokens('urn:ietf:params:oauth:grant-type:token-exchange', undefined,
                        audience, this.scope,
                        'urn:ietf:params:oauth:token-type:access_token', this.oauthService.getIdToken(),
                        'urn:ietf:params:oauth:token-type:id_token').toPromise();
                    this.oauthStorage.setItem('api_access_token', response.access_token);
                    const accessToken: AccessToken = jwt_decode(response.access_token);
                    const json = JSON.stringify(accessToken, null, 4);
                    this.oauthStorage.setItem('api_access_token_obj', json);
                    if (environment.debug) {
                        console.log(json);
                    }
                    return new Promise((resolve) => resolve(true));
                });
        } catch (e) {
            console.error(e);
            return new Promise((resolve) => resolve(false));
        }
    }



    get scopes(): string[] {
        const scopes: string | null = this.oauthStorage.getItem('scopes');
        return scopes ? JSON.parse(scopes) as string[] : [];
    }

    set scopes(scopes: string[]) {
        this.oauthStorage.setItem('scopes', JSON.stringify(scopes, null, 4));
    }

    get scope(): string | null {
        return this.oauthStorage.getItem('scope');
    }

    set scope(scope: string | null) {
        if (scope) {
            this.oauthStorage.setItem('scope', scope);
        } else {
            this.oauthStorage.removeItem('scope');
        }
    }

    public selectScope(): Promise<string | null> {
        return new Promise(async (resolve) => {

            if (!this.scopes || this.scopes.length === 0) {
                this.scope = '';
            } else if (this.scopes.length === 1) {
                this.scope = this.scopes[0];
            } else {
                const dialogRef = this.dialog.open(SelectScopeDialogComponent, {
                    width: '450px',
                    closeOnNavigation: false,
                    disableClose: true,
                    data: {
                        scopes: this.scopes
                    }
                });
                await dialogRef.afterClosed().toPromise().then(result => {
                    if (result) {
                        this.scope = result;
                    }
                });

            }

            resolve(this.scope);
        });

    }

    get userProfile(): UserProfile | null {
        return this.oauthStorage.getItem('userProfile') ?
            JSON.parse(this.oauthStorage.getItem('userProfile') as string) as UserProfile : null;
    }

    set userProfile(userProfile: UserProfile | null) {
        if (userProfile) {
            this.oauthStorage.setItem('userProfile', JSON.stringify(userProfile, null, 4));

            forkJoin([
                this.featureService.isFeatureEnabled(Feature.FeatureEnum.tUseTobbUndGuarantee),
                this.tSettingsService.getTAuthorizedPartyIndicator(null, 'response').pipe(catchError(err => of({ok: false})), map(r => r.ok))
            ])
            .subscribe(async ([tUseTobbUndGuaranteeFeatureIndicator , getTAuthorizedPartyIndicator]) => {
                const bctIndicator = tUseTobbUndGuaranteeFeatureIndicator && getTAuthorizedPartyIndicator;
            
                this.posthogService.identify(userProfile.username, userProfile.tenant, bctIndicator);
                this.matomoService.identify(userProfile.username, userProfile.tenant, bctIndicator);
            });
        } else {
            this.oauthStorage.removeItem('userProfile');
            this.posthogService.reset();
            this.matomoService.reset();
        }
    }


    public selectDisabledUserProfile(): Promise<UserProfile | null> {
        return new Promise<UserProfile | null>(async resolve => {
            if (!this.userProfile) {
                const accessToken = this.accessToken;
                this.userProfile = {
                    username: accessToken.idToken.preferred_username,
                    tenant: null,
                    filter: null,
                    profile: null,
                    emailAddress: accessToken.idToken.email,
                    displayName: accessToken.idToken.name
                };
            }
            await this.userContextService.setDisabledUser(this.userProfile);
            resolve(this.userProfile);

        });
    }

    public selectUserProfile(): Promise<UserProfile | null> {
        return new Promise<UserProfile | null>(async (resolve, reject) => {
            if (!this.userProfile) {
                const userProfiles: UserProfiles | null = this.userProfiles;
                const tenantProfiles: TenantProfiles | null = this.tenantProfiles;
                const filterProfiles: FilterProfile | null = this.filterProfiles;
                const accessToken = this.accessToken;
                if (userProfiles && tenantProfiles && filterProfiles) {
                    this.userProfile = {
                        username: userProfiles.username,
                        tenant: tenantProfiles.tenant,
                        filter: filterProfiles.filter,
                        profile: filterProfiles.profile,
                        emailAddress: accessToken.idToken.email,
                        displayName: accessToken.idToken.name
                    };

                }
            }
            await this.userContextService.setUser(this.userProfile)
                .then(() => resolve(this.userProfile))
                .catch(() => reject());
        });
    }

    get bootstrapUrl(): string {
        return this.oauthStorage.getItem('bootstrapUrl') ? this.oauthStorage.getItem('bootstrapUrl') as string : '/mgmt/apps';
    }

    set bootstrapUrl(bootstrapUrl: string) {
        this.oauthStorage.setItem('bootstrapUrl', bootstrapUrl);
    }

    navigateToBootstrapPage(): void {
        this.router.navigateByUrl(this.bootstrapUrl);
    }

    navigateToScopePage(): void {
        this.router.navigateByUrl('authentication/scopes');
    }

    navigateToInternalErrorPage(): void {
        this.oauthService.revokeTokenAndLogout();
    }

    navigateToDisabledPage(): void {
        this.router.navigateByUrl('/new-user');
    }

    public navigateToChangePasswordPage() {
        this.webApplicationConfigurationService.getPropertyValue('public.authentication-config.changePasswordUrl')
            .then(value => {
                if (value) {
                    window.open(value);
                }
            });
    }

    get accessToken(): AccessToken | null {
        const accessToken: string | null = this.oauthStorage.getItem('api_access_token_obj');
        return accessToken ? JSON.parse(accessToken) as AccessToken : null;
    }

    get userProfiles(): UserProfiles | null {
        if (this.accessToken) {
            return this.accessToken.usersProfiles && this.accessToken.usersProfiles.length >= 1 ? this.accessToken.usersProfiles[0] : null;
        }
        return null;
    }

    get tenantProfiles(): TenantProfiles | null {
        if (this.userProfiles) {
            return this.userProfiles.tenants && this.userProfiles.tenants.length >= 1 ? this.userProfiles.tenants[0] : null;
        }
        return null;
    }

    get filterProfiles(): FilterProfile | null {
        if (this.tenantProfiles) {
            return this.tenantProfiles.filterProfiles && this.tenantProfiles.filterProfiles.length >= 1
                ? this.tenantProfiles.filterProfiles[0] : null;
        }
        return null;
    }

    public get profile(): string[] | null | undefined {
        return this.userProfile ? this.userProfile.profile : null;
    }
}
