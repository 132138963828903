<div class="layout-container" [dir]="textDir" *ngIf="initialized">
  <ng-container *ngIf="!userContextService.isDisabled()">
    <app-markdown *ngIf="loginMarkdownServiceProperties != null" [markdownService]="loginMarkdownService"
      [markdownServiceProperties]="loginMarkdownServiceProperties"></app-markdown>
  </ng-container>


  <div class="logo" [routerLink]="['/home']"></div>

  <div class="top-bar gradient">
    <div fxFlex fxLayoutAlign="start" style="width: 180px;">
    </div>

    <div *ngIf="displayProfileFillingTip" fxFlex style="margin-top: 6px;">
      <div class="fill-profile">
        <div>
          <span [routerLink]="['/company/organisations/details/' + userContextService.user.tenant.split('/').join('-')]"
            [queryParams]="{'default-tab': 'profile'}">
            {{ 'companyinfo.content-layout.publish-notification' | trackAndTranslate }}
          </span>
          <mat-icon>fiber_new</mat-icon>
        </div>
      </div>
    </div>


    <div fxLayout="row" fxLayoutAlign="end center" style="margin-top: 0px;">


      <app-internationalization [publicMode]="false"></app-internationalization>

      <span class="display-name">{{ userContextService.user?.username }}</span>
      <app-user-profile></app-user-profile>

    </div>
  </div>


  <div class="banner" style="padding: 30px">

    <div fxFlex fxLayoutAlign="space-between center">
      <div class="tenant-name">{{ (userContextService.organisation | async)?.party.localizedParties[0].name }}</div>
      <div>
        <img *ngIf="userContextService.tenant.type" alt="" src='./assets/{{ associationLogoPath }}' />
      </div>
    </div>

  </div>

  <app-nav *ngIf="userContextService.organisation | async"></app-nav>

  <br/>

  <app-error></app-error>

  <router-outlet></router-outlet>
</div>

<app-footer></app-footer>
