import { Injectable } from '@angular/core';
import { Router, Scroll } from '@angular/router';
import { TranslateService } from '@app/shared/services/translate.service';

/*
* Service that offers methods to manage the errors displayed by the ErrorComponent.
* Looking for methods about the processing of the errors ? Try ErrorService of the shared module.
*/

@Injectable({
    providedIn: 'root'
})
export class ErrorService {

    private _errors: string[] = [];

    constructor(private router: Router,
        private translateService: TranslateService) {
        router.events.subscribe((event) => {
            // clear the errors when the user changes the page
            this.clear();

            // eventually display an error located in the state
            if (event instanceof Scroll) {
                if (history.state.errors != null && history.state.errors.length > 0) {
                    this.errors = history.state.errors;
                }
            }
        });
    }

    clear(): void {
        this._errors = [];
    }

    set errors(errors: string[]) {
        if (errors == null || errors.length === 0) {
            return;
        }
        if (Array.isArray(errors)) {
            this._errors = errors.map(error => {
                return this.translateService.instantWithLegacyParameters(error, false);
            });

            window.scrollTo(0, 0);
        }
    }

    get errors(): string[] {
        return this._errors;
    }
}
