import { Injectable } from '@angular/core';
import { AuthenticationService } from '@api/index';

@Injectable({ providedIn: 'root' })
export class IdentityProviderService {
    constructor(private authenticationService: AuthenticationService) { }


    public getConfigurationKey(key: string, useDefault = false): string {
        const prefix = 'public.authentication-config';
        const idp = !useDefault && sessionStorage.getItem('idp') ? `${sessionStorage.getItem('idp')}.` : '';

        return `${prefix}.${idp}${key}`;
    }
}