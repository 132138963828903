/**
 * TIR-EPD REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tirepd@iru.org
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PartyProperty { 
    category?: PartyProperty.CategoryEnum;
    categoryCountryCode?: string;
    categoryAssociationId?: number;
    name: string;
    value: string;
    immutable?: boolean;
}
export namespace PartyProperty {
    export type CategoryEnum = 'tax-identification-number' | 'tax-office' | 'eori' | 'statistics-identification-number';
    export const CategoryEnum = {
        TaxIdentificationNumber: 'tax-identification-number' as CategoryEnum,
        TaxOffice: 'tax-office' as CategoryEnum,
        Eori: 'eori' as CategoryEnum,
        StatisticsIdentificationNumber: 'statistics-identification-number' as CategoryEnum
    };
}


