<div>
  <h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    {{ 'scope.select.title' | trackAndTranslate }}</h1>
  <div mat-dialog-content>
    <p>{{ 'scope.select.content' | trackAndTranslate }}:</p>
    <p>
      <mat-form-field>
        <mat-select [formControl]="scope">
          <mat-option *ngFor="let scope of data.scopes" [value]="scope">
            {{ scope }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </p>
  </div>
</div>
<div mat-dialog-actions>
  <button [disabled]="!scope.valid" mat-raised-button mat-button (click)="onScopeSelect()" type="button">{{
    'button.confirm' |
    trackAndTranslate }}</button>

</div>
