import { Location } from '@angular/common';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthAuthService } from '../services/auth-auth.service';

@Injectable()
export class HttpUnauthorizedInterceptor implements HttpInterceptor {

    constructor(private router: Router,
        private location: Location, private authenticationService: AuthAuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request)
            .pipe(
                tap((event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                        // do stuff with response if you want
                    }
                }, (err: any) => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status === 401) {
                            if (err.url.endsWith('/tirepd-api/organisation')) {
                                this.authenticationService.logout('public/disabled-user');
                            } else if (this.router.routerState.snapshot.url) {
                                this.authenticationService.logout();
                            }

                            return throwError(err);
                        }
                    }
                })
            );
    }
}
