import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DisabledUserComponent } from './disabled-user/disabled-user.component';
import { EmailValidationComponent } from './email-validation/email-validation.component';
import { LoginComponent } from './login/login.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: '/public/login',
    pathMatch: 'full'
  },
  {
    path: '',
    children: [
      {
        path: 'email-validation',
        component: EmailValidationComponent
      },
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'disabled-user',
        component: DisabledUserComponent
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PublicRoutingModule { }
