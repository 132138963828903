import { ErrorHandler, Injectable } from "@angular/core";
import { PosthogService } from "../services/posthog.service";

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private postHogService: PosthogService) {
  }

  handleError(error: any): void {
    if (error.message) {
      console.log(error.message);
    }
    this.postHogService.captureError(error).then(() => {
      console.error(error);
    });
  }
}
