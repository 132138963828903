<mat-card class="login-card">
  <mat-card-header>
    <mat-card-title>
      {{ 'new.header' | trackAndTranslate }}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="login-card-content">
    <div>
      <span>{{'contact.your' | trackAndTranslate}}
        <a href="{{'issuing.association.url' | trackAndTranslate}}" target="_blank">{{'issuing.association' | trackAndTranslate}}</a>
        {{'to.obtain.an.account' | trackAndTranslate}}
        <a href="{{'website.url' | trackAndTranslate}}" target="_blank">{{'website' | trackAndTranslate}}</a>
        {{'for.more.information' | trackAndTranslate}}
        <p>
          {{'email.us.for.technical.support' | trackAndTranslate}}
          <a href="mailto:{{'email.address' | trackAndTranslate}}">{{'email.address' | trackAndTranslate}}</a>
        </p>
      </span>
    </div>
  </mat-card-content>
</mat-card>
