<div class="user-profile-row">
  <div class="button-row">
    <button mat-mini-fab color="primary" [matMenuTriggerFor]="menu">
      <mat-icon>account_circle</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <div mat-menu-item style="pointer-events:none">
        <mat-icon>face</mat-icon>
        {{ userContextService.user?.displayName }}
      </div>
      <hr />
      <button mat-menu-item (click)="onChangePassword()" *ngIf="canChangePassword">
        <mat-icon>lock_reset</mat-icon>
        <span>{{'settings.menu.change.password' | trackAndTranslate}}</span>
      </button>
      <button mat-menu-item (click)="onLogout()">
        <mat-icon>logout</mat-icon>
        <span>{{'logout' | trackAndTranslate}}</span>
      </button>
    </mat-menu>
  </div>
</div>
