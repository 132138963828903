import { ElementRef, Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dataTranslationKey',
  pure: false
})
@Injectable({
  providedIn: 'root'
})
export class DataTranslationKeyPipe
  implements PipeTransform {

    //private _element: ElementRef;

    //constructor(private injElement: ElementRef) {
    //  this._element = injElement;
    //}

    //public set element(theElement: ElementRef) {
    //  this._element = theElement;
    //}

    constructor(private element: ElementRef) {
    }

    transform(translationKey: string): string {
      if (this.element && this.element.nativeElement && this.element.nativeElement.parentElement && this.element.nativeElement.parentElement.dataset) {
        var existingValue = this.element.nativeElement.parentElement.dataset.translationKey;
        if (existingValue && !existingValue.includes(translationKey)) {
          this.element.nativeElement.parentElement.dataset.translationKey = existingValue + '; ' + translationKey;
        }
        else {
          this.element.nativeElement.parentElement.dataset.translationKey = translationKey;
        }
      }

      return translationKey;
    }
}
