import { Component } from '@angular/core';


@Component({
  selector: 'app-login-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss']
})
export class NewUserComponent {

  constructor() {
  }

}
