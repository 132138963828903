import { Component, Inject } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  scopes: string[];
}


@Component({
  selector: 'app-select-scope-dialog',
  templateUrl: './select-scope-dialog.component.html',
  styleUrls: ['./select-scope-dialog.component.scss']
})
export class SelectScopeDialogComponent {

  public scope: UntypedFormControl = new UntypedFormControl('', [Validators.required])

  constructor(
    public dialogRef: MatDialogRef<SelectScopeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }



  onScopeSelect(): void {
    this.dialogRef.close(this.scope.value);
  }
}
