<div class="main-component-container" *ngIf="status">
    <h1>{{ 'public.profile.email-validation.title' | trackAndTranslate }}</h1>


    <div fxLayout="column" fxLayoutAlign="space-around center" fxLayoutGap="20px">

        <div>
            <h2>{{ 'public.profile.email-validation.' + status | trackAndTranslate}}</h2>
        </div>

        <div>
            <button type="button" mat-raised-button color="primary" (click)="toLoginPage()">{{ 'button.login' |
                trackAndTranslate}}</button>
        </div>
    </div>


</div>
