/**
 * TIR-EPD REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tirepd@iru.org
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Code } from './code';


/**
 * Information about the Reference to a Document
 */
export interface DocumentReferenceAdditionalInformationType { 
    typeCode: Code;
    valueType: DocumentReferenceAdditionalInformationType.ValueTypeEnum;
    listValues?: Array<string>;
}
export namespace DocumentReferenceAdditionalInformationType {
    export type ValueTypeEnum = 'list' | 'text';
    export const ValueTypeEnum = {
        List: 'list' as ValueTypeEnum,
        Text: 'text' as ValueTypeEnum
    };
}


