import { Injectable } from '@angular/core';
import { TranslateService as NGXTranslateService } from '@ngx-translate/core';
import { sprintf } from 'sprintf-js';


@Injectable({
    providedIn: 'root',
})
export class TranslateService extends NGXTranslateService {

    public instantWithLegacyParameters(key: string, alwaysDisplayParameters: boolean, ...parameters: string[]): string {
        const label: string = this.instant(key);
        let result = label;
        if (parameters != null && parameters.length > 0) {
            // method one with variables defined as %1s, %2s, ...
            parameters.forEach((parameter, index) => {
                result = result.replace(`%${index + 1}$s`, parameter.replace(/%/g, '%%'));
            });

            // method 2 with variables defined as %s ...
            result = sprintf(result, ...parameters);


            if (alwaysDisplayParameters && result === label) {
                result = `${label} ${parameters.join(',')}`;
            }
        }


        return result;
    }
}
