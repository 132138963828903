import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment,
    UrlTree
} from '@angular/router';

import { WebApplicationConfigurationService } from '@app/shared/services/web-application-configuration.service';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthAuthService } from '../services/auth-auth.service';
import { AuthorizationService } from '../services/authorization.service';
import { ExternalApiModuleWrapper } from '../services/external.api.module.wrapper.service';
import { RedirectAfterLoginService } from '../services/redirect-after-login.service';

@Injectable()
export class RouteGuard implements CanActivate, CanActivateChild, CanLoad {
    constructor(private router: Router, private authAuthService: AuthAuthService,
        private authorizationService: AuthorizationService,
        private externalApiModuleWrapper: ExternalApiModuleWrapper,
        private webApplicationConfigurationService: WebApplicationConfigurationService,
        private redirectAfterLoginService: RedirectAfterLoginService) {
    }

    public async canActivate(activatedRoute: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): Promise<boolean> {
        let canActivate = false;
        let isAuthenticated = this.authAuthService.hasValidAccessToken() && this.authAuthService.hasValidIdToken();
        if (environment.debug) {
            console.log(`isAuthenticated=${isAuthenticated}`);
        }
        const hasValidApiAccessToken: boolean = this.authAuthService.hasValidApiAccessToken();
        if (environment.debug) {
            console.log(`hasValidApiAccessToken=${hasValidApiAccessToken}`);
        }
        let scopes: string[] = this.authAuthService.scopes;
        if (environment.debug) {
            console.log(`scopes=${JSON.stringify(scopes)}`);
        }
        let scope: string | null = this.authAuthService.scope;
        if (environment.debug) {
            console.log(`scope=${scope}`);
        }
        try {
            if (!isAuthenticated) {
                await this.authAuthService.inAppLogin();
                isAuthenticated = this.authAuthService.hasValidAccessToken() && this.authAuthService.hasValidIdToken();
                if (environment.debug) {
                    console.log(`isAuthenticated=${isAuthenticated}`);
                }
            }
            if (isAuthenticated && hasValidApiAccessToken) {
                await this.authAuthService.selectUserProfile();
            }
            if (isAuthenticated && !hasValidApiAccessToken) {
                let audience = null;
                await this.webApplicationConfigurationService.getPublicPropertyValue('public.authorization-config.audience', null)
                    .then(confAudience => {
                        audience = confAudience;
                    });
                await this.externalApiModuleWrapper.waitStsInitialized();
                scopes = await this.externalApiModuleWrapper.stsScopeService().searchScopes(undefined, audience).toPromise();
                this.authAuthService.scopes = scopes;
                if (!scope) {
                    await this.authAuthService.selectScope().then(result => scope = result);
                }
                if (scope && scopes.includes(scope)) {
                    let organisationFailToLoad = false;
                    await this.authAuthService.exchangeToken();
                    await this.authAuthService.selectUserProfile()
                        .catch((err) => {
                            organisationFailToLoad = true;
                            this.authAuthService.logout('public/disabled-user');
                        });
                    if (organisationFailToLoad) {
                        return new Promise((resolve) => resolve(false));
                    }
                }
            }
        } catch (e) {
            console.error(e);
            this.authAuthService.invalidateApiAccessToken();
        }
        if (!isAuthenticated) {
            this.router.navigateByUrl('/public/login');
        } else if (scopes.length === 0) {
            this.authAuthService.navigateToDisabledPage();
        } else if (!scope || !scopes.includes(scope)) {
            console.error('Multiple scope management is not implemented.');
            this.authAuthService.logout();
            // this.authAuthService.bootstrapUrl = routerState.url;
            // this.authAuthService.navigateToScopePage();
        } else {
            canActivate = this.isRouteAllowed(activatedRoute.routeConfig);
        }
        if (environment.debug) {
            console.log(`${routerState.url}: canActivate=${canActivate}`);
        }
        if (!canActivate) {
            this.redirectAfterLoginService.routerUrl = routerState.url;
        }
        return new Promise((resolve) => resolve(canActivate));
    }

    public async canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return this.canActivate(childRoute, state);
    }

    public isRouteAllowed(route: Route | null): boolean {
        const auth = route && route.data ? route.data.auth : undefined;
        return auth ? this.authorizationService.canAccess(auth.action) : true;
    }

    public canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (route.data && route.data.options && route.data.options.onlyDev) {
            return environment.debug; // if prod = false it will load module
        }
        return true;
    }
}
