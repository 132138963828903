
import { Observable, forkJoin, of } from 'rxjs';
import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import * as rename from '../../../../target/assets-i18n-rename.json';


export class MultipleFileTranslateHttpLoader implements TranslateLoader {
    constructor(
        private http: HttpClient,
        public resources: { prefix: string, suffix: string, renameIndicator: boolean }[] = [
            { prefix: '/assets/i18n/', suffix: '.json', renameIndicator: true }]
    ) { }


    public getTranslation(lang: string): Observable<any> {
        return forkJoin(
            this.resources.map(config => {
                const orig = `${lang}${config.suffix}`;
                const r: string = config.renameIndicator ? rename['default'][orig] : orig;

                return this.http.get(`${config.prefix}${r}`)
                    .pipe(catchError(err => of(`Error loading ${config.prefix}${lang}${config.suffix} : ${err.message}`)));
            })
        )
            .pipe(
                map((response) => {
                    return response.reduce((a, b) => {
                        if (typeof a === 'string' && typeof b === 'string') {
                            console.error(a);
                            console.error(b);
                            return {};
                        } else if (typeof a === 'string') {
                            console.error(a);
                            return b;
                        } else if (typeof b === 'string') {
                            console.error(b);
                            return a;
                        } else {
                            return Object.assign(a, b);
                        }
                    });
                })
            );
    }
}
