/**
 * TIR-EPD REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tirepd@iru.org
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CustomsOffice } from './customsOffice';


export interface TransportOperationTerminationCustomsOffice { 
    customsOffice?: CustomsOffice;
    type?: TransportOperationTerminationCustomsOffice.TypeEnum;
    stepReference?: number;
}
export namespace TransportOperationTerminationCustomsOffice {
    export type TypeEnum = 'destination' | 'exit' | 'departure__before_partial_load';
    export const TypeEnum = {
        Destination: 'destination' as TypeEnum,
        Exit: 'exit' as TypeEnum,
        DepartureBeforePartialLoad: 'departure__before_partial_load' as TypeEnum
    };
}


