import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrganisationService } from '@api/index';


@Component({
  selector: 'app-login',
  templateUrl: './email-validation.component.html',
  styleUrls: ['./email-validation.component.scss']
})
export class EmailValidationComponent implements OnInit {

  public status: 'success' | 'expired' | 'error';

  constructor(private organisation: OrganisationService, private activedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    const validationToken: string = this.activedRoute.snapshot.queryParams['validationToken'];
    if (!validationToken) {
      this.status = 'error';
      return;
    }
    this.organisation.validateBusinessProfileContactEmailAddress(validationToken, 'response').subscribe(
      succcess => {
        this.status = 'success';
      },
      (error: HttpErrorResponse) => {
        if (error.status === 410) {
          this.status = 'expired';
        } else {
          this.status = 'error';
        }
      }
    );
  }

  public toLoginPage(): void {
    window.location.href = '/';
  }
}
