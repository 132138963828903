import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { Component, Input, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { Locale, LocaleService } from '@api/index';
import { UserContextService } from '@app/core/services';
import { DataReferenceService } from '@app/shared/services/data-reference.service';
import { TranslateService } from '@app/shared/services/translate.service';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';



@Component({
  selector: 'app-internationalization',
  templateUrl: './internationalization.component.html',
  styleUrls: ['./internationalization.component.scss']
})
export class InternationalizationComponent implements OnInit {

  static EN_GB_LOCALE: Locale = { code: 'en-GB', name: 'English' };

  static LANG_SESSION_STORAGE_NAME = 'lang';

  public locales: Locale[];
  public selectedLocale: Locale = { code: null };

  @Input()
  public publicMode: boolean;


  constructor(private localeService: LocaleService,
    private translateService: TranslateService,
    private dataReferenceService: DataReferenceService,
    private userContextService: UserContextService,
    private adapter: DateAdapter<any>,
    private ngxAdapter: NgxMatDateAdapter<any>) {
    this.selectedLocale.code = this.translateService.getDefaultLang();
  }

  ngOnInit() {
    forkJoin([
      this.getLocales(),
      this.getLocaleCode()
    ]
    ).pipe(
      catchError(err => of(err))
    ).subscribe(
      ([locales, localeCode]) => {
        this.locales = locales == null ? [InternationalizationComponent.EN_GB_LOCALE] : locales;

        this.selectedLocale = this.locales.find(locale => locale.code === localeCode);

        this.translateService.use(localeCode);
        this.adapter.setLocale(this.selectedLocale.code);
        this.ngxAdapter.setLocale(this.selectedLocale.code);
        if (!this.publicMode) {
          this.addTranslation(localeCode);
        }
      });
  }

  private getLocales(): Promise<Locale[]> {
    return new Promise(resolve => {
      this.localeService.getLocales().subscribe(
        locales => resolve(locales),
        err => resolve(null));
    });
  }

  private getLocaleCode(): Promise<string> {
    return new Promise(resolve => {
      if (this.publicMode) {
        const localeCode = sessionStorage.getItem(InternationalizationComponent.LANG_SESSION_STORAGE_NAME);
        resolve(localeCode ? localeCode : InternationalizationComponent.EN_GB_LOCALE.code);
      } else {
        this.userContextService.organisation
          .subscribe(organisation => {
            if (organisation) {

              this.localeService.getProfile().subscribe(userProfile => {
                const localeCode = userProfile == null ? InternationalizationComponent.EN_GB_LOCALE.code : userProfile.localeCode;
                resolve(localeCode);
              });
            } else {
              const localeCode = sessionStorage.getItem(InternationalizationComponent.LANG_SESSION_STORAGE_NAME);
              resolve(localeCode ? localeCode : InternationalizationComponent.EN_GB_LOCALE.code);
            }
          });
      }

    });
  }

  updateLangSessionStorage(): Promise<void> {
    return new Promise(resolve => {
      sessionStorage.setItem(InternationalizationComponent.LANG_SESSION_STORAGE_NAME, this.selectedLocale.code);
      resolve(null);
    });
  }

  onLocaleChange() {
    this.adapter.setLocale(this.selectedLocale.code);
    this.ngxAdapter.setLocale(this.selectedLocale.code);

    if (this.publicMode) {
      forkJoin([
        this.translateService.use(this.selectedLocale.code),
        this.updateLangSessionStorage()
      ]).subscribe(() => {
        // nothing to do
      });
    } else {
      forkJoin([
        this.translateService.use(this.selectedLocale.code),
        this.localeService.saveLocale(this.selectedLocale),
        this.updateLangSessionStorage()
      ]).subscribe(() => {
        this.addTranslation(this.selectedLocale.code);
      });
    }

  }

  // add some translation keys that are not available as it from backend
  private addTranslation(lang: string) {
    if (this.userContextService.hasPermission('read-epd')) {
      this.dataReferenceService.getCustomsUnions().subscribe(customsUnions => {
        customsUnions.forEach(customsUnion => {
          this.translateService.setTranslation(lang, {
            ['country.' + customsUnion.name]: `${customsUnion.name} - ${this.translateService.instant('customs.union')}`
          }, true);

        });
      });
    }
  }

}
