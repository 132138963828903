/**
 * TIR-EPD REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tirepd@iru.org
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { ApplicationMarkdownExtension } from '../model/applicationMarkdownExtension';
// @ts-ignore
import { ApplicationMarkdownExtensionPredeclarationStepLocation } from '../model/applicationMarkdownExtensionPredeclarationStepLocation';
// @ts-ignore
import { AttachmentDocumentType } from '../model/attachmentDocumentType';
// @ts-ignore
import { AttachmentReference } from '../model/attachmentReference';
// @ts-ignore
import { AttachmentSupport } from '../model/attachmentSupport';
// @ts-ignore
import { Code } from '../model/code';
// @ts-ignore
import { CommodityClassificationAdditionalItemProperties } from '../model/commodityClassificationAdditionalItemProperties';
// @ts-ignore
import { CommodityClassificationDocumentReferenceTypes } from '../model/commodityClassificationDocumentReferenceTypes';
// @ts-ignore
import { ConsignmentDocumentApplicability } from '../model/consignmentDocumentApplicability';
// @ts-ignore
import { ContainerSizesTypes } from '../model/containerSizesTypes';
// @ts-ignore
import { CustomsOffice } from '../model/customsOffice';
// @ts-ignore
import { CustomsOfficeRole } from '../model/customsOfficeRole';
// @ts-ignore
import { DescribedCode } from '../model/describedCode';
// @ts-ignore
import { DescribedCodeTreeNode } from '../model/describedCodeTreeNode';
// @ts-ignore
import { DocumentReferenceAdditionalInformationType } from '../model/documentReferenceAdditionalInformationType';
// @ts-ignore
import { MovementCodeApplicability } from '../model/movementCodeApplicability';
// @ts-ignore
import { OrganisationPredeclarationSettings } from '../model/organisationPredeclarationSettings';
// @ts-ignore
import { PackagingType } from '../model/packagingType';
// @ts-ignore
import { Predeclaration } from '../model/predeclaration';
// @ts-ignore
import { PredeclarationId } from '../model/predeclarationId';
// @ts-ignore
import { PredeclarationNewPart } from '../model/predeclarationNewPart';
// @ts-ignore
import { PredeclarationSentOverallStatus } from '../model/predeclarationSentOverallStatus';
// @ts-ignore
import { PredeclarationSort } from '../model/predeclarationSort';
// @ts-ignore
import { PredeclarationStatus } from '../model/predeclarationStatus';
// @ts-ignore
import { PredeclarationTransportOperationMessageSubmission } from '../model/predeclarationTransportOperationMessageSubmission';
// @ts-ignore
import { PrintableDocumentTemplate } from '../model/printableDocumentTemplate';
// @ts-ignore
import { Rule } from '../model/rule';
// @ts-ignore
import { SearchedPredeclarations } from '../model/searchedPredeclarations';
// @ts-ignore
import { Shipment } from '../model/shipment';
// @ts-ignore
import { SortOrder } from '../model/sortOrder';
// @ts-ignore
import { TransitGuarantee } from '../model/transitGuarantee';
// @ts-ignore
import { TransitGuaranteeFormat } from '../model/transitGuaranteeFormat';
// @ts-ignore
import { TransitGuaranteeLegalFramework } from '../model/transitGuaranteeLegalFramework';
// @ts-ignore
import { TransportOperation } from '../model/transportOperation';
// @ts-ignore
import { TransportOperationMessageExchange } from '../model/transportOperationMessageExchange';
// @ts-ignore
import { TransportOperationMessageExchangeActionType } from '../model/transportOperationMessageExchangeActionType';
// @ts-ignore
import { ValidationIssueMessage } from '../model/validationIssueMessage';
// @ts-ignore
import { VehicleRegistrationNumberValueCountryPrefixes } from '../model/vehicleRegistrationNumberValueCountryPrefixes';
// @ts-ignore
import { VehicleTypeAdditionalPropertyValues } from '../model/vehicleTypeAdditionalPropertyValues';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class EpdService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Create a pre-declaration
     * @param predeclaration the pre-declaration content
     * @param bypassValidation indicates that no validation should be performed.
     * @param bypassSave indicates that no save should be performed.
     * @param createAs defaults to \&#39;new\&#39;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createEpd(predeclaration: Predeclaration, bypassValidation?: boolean, bypassSave?: boolean, createAs?: 'new' | 'duplicate' | 'amendment', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<any>;
    public createEpd(predeclaration: Predeclaration, bypassValidation?: boolean, bypassSave?: boolean, createAs?: 'new' | 'duplicate' | 'amendment', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<any>>;
    public createEpd(predeclaration: Predeclaration, bypassValidation?: boolean, bypassSave?: boolean, createAs?: 'new' | 'duplicate' | 'amendment', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<any>>;
    public createEpd(predeclaration: Predeclaration, bypassValidation?: boolean, bypassSave?: boolean, createAs?: 'new' | 'duplicate' | 'amendment', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (predeclaration === null || predeclaration === undefined) {
            throw new Error('Required parameter predeclaration was null or undefined when calling createEpd.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (bypassValidation !== undefined && bypassValidation !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>bypassValidation, 'bypassValidation');
        }
        if (bypassSave !== undefined && bypassSave !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>bypassSave, 'bypassSave');
        }
        if (createAs !== undefined && createAs !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>createAs, 'createAs');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (create-epd) required
        localVarCredential = this.configuration.lookupCredential('create-epd');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/epds`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: predeclaration,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a attachment related to this consignment
     * @param guid the identifier of the pre-declaration
     * @param uniqueConsignmentReference the unique consignment reference
     * @param attachmentId the attachment identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteConsignmentAttachment(guid: string, uniqueConsignmentReference: string, attachmentId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<any>;
    public deleteConsignmentAttachment(guid: string, uniqueConsignmentReference: string, attachmentId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<HttpResponse<any>>;
    public deleteConsignmentAttachment(guid: string, uniqueConsignmentReference: string, attachmentId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<HttpEvent<any>>;
    public deleteConsignmentAttachment(guid: string, uniqueConsignmentReference: string, attachmentId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined,}): Observable<any> {
        if (guid === null || guid === undefined) {
            throw new Error('Required parameter guid was null or undefined when calling deleteConsignmentAttachment.');
        }
        if (uniqueConsignmentReference === null || uniqueConsignmentReference === undefined) {
            throw new Error('Required parameter uniqueConsignmentReference was null or undefined when calling deleteConsignmentAttachment.');
        }
        if (attachmentId === null || attachmentId === undefined) {
            throw new Error('Required parameter attachmentId was null or undefined when calling deleteConsignmentAttachment.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (update-epd) required
        localVarCredential = this.configuration.lookupCredential('update-epd');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/epds/${this.configuration.encodeParam({name: "guid", value: guid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/consignments/${this.configuration.encodeParam({name: "uniqueConsignmentReference", value: uniqueConsignmentReference, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/attachments/${this.configuration.encodeParam({name: "attachmentId", value: attachmentId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete the pre-declaration
     * @param guid the identifier of the pre-declaration
     * @param xPredeclarationDateTime the timestamp when the predeclaration was last updated. Mandatory if consignmentId is set.
     * @param consignmentId the identifier of the consignment (in the designated pre-declaration) to delete
     * @param consolidatedShipmentId the identifier of the consolidated shipment (in the designated pre-declaration and consignment) to delete
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteEpd(guid: string, xPredeclarationDateTime?: string, consignmentId?: number, consolidatedShipmentId?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<Predeclaration>;
    public deleteEpd(guid: string, xPredeclarationDateTime?: string, consignmentId?: number, consolidatedShipmentId?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<Predeclaration>>;
    public deleteEpd(guid: string, xPredeclarationDateTime?: string, consignmentId?: number, consolidatedShipmentId?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<Predeclaration>>;
    public deleteEpd(guid: string, xPredeclarationDateTime?: string, consignmentId?: number, consolidatedShipmentId?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (guid === null || guid === undefined) {
            throw new Error('Required parameter guid was null or undefined when calling deleteEpd.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (consignmentId !== undefined && consignmentId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>consignmentId, 'consignmentId');
        }
        if (consolidatedShipmentId !== undefined && consolidatedShipmentId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>consolidatedShipmentId, 'consolidatedShipmentId');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xPredeclarationDateTime !== undefined && xPredeclarationDateTime !== null) {
            localVarHeaders = localVarHeaders.set('X-Predeclaration-DateTime', String(xPredeclarationDateTime));
        }

        let localVarCredential: string | undefined;
        // authentication (update-epd) required
        localVarCredential = this.configuration.lookupCredential('update-epd');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/epds/${this.configuration.encodeParam({name: "guid", value: guid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<Predeclaration>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a attachment related to this pre-declaration
     * @param guid the identifier of the pre-declaration
     * @param attachmentId the attachment identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deletePredeclarationAttachment(guid: string, attachmentId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<any>;
    public deletePredeclarationAttachment(guid: string, attachmentId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<HttpResponse<any>>;
    public deletePredeclarationAttachment(guid: string, attachmentId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<HttpEvent<any>>;
    public deletePredeclarationAttachment(guid: string, attachmentId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined,}): Observable<any> {
        if (guid === null || guid === undefined) {
            throw new Error('Required parameter guid was null or undefined when calling deletePredeclarationAttachment.');
        }
        if (attachmentId === null || attachmentId === undefined) {
            throw new Error('Required parameter attachmentId was null or undefined when calling deletePredeclarationAttachment.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (update-epd) required
        localVarCredential = this.configuration.lookupCredential('update-epd');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/epds/${this.configuration.encodeParam({name: "guid", value: guid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/attachments/${this.configuration.encodeParam({name: "attachmentId", value: attachmentId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get an export of existing consolidated shipments that serve as template for import
     * @param guid the identifier of the pre-declaration
     * @param uniqueConsignmentReference the unique consignment reference
     * @param epdCountryCode the countries to which EPD will be sent
     * @param epdSafetyAndSecurityDataIndicator indicates if any EPD will be sent with SafetyAndSecurityData
     * @param epdContainerIndicator indicates if the EPD has at least one goods item container
     * @param type the Excel template file format
     * @param localeCode the locale code for the labels in the Excel (defaults to British English)
     * @param v2format if the Excel template should be fetched in v2 format (defaults to \&#39;false\&#39;)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportConsolidatedShipmentTemplate(guid: string, uniqueConsignmentReference: string, epdCountryCode: Array<string>, epdSafetyAndSecurityDataIndicator: boolean, epdContainerIndicator: boolean, type: 'xls' | 'xlsx', localeCode?: string, v2format?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.ms-excel' | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',}): Observable<Blob>;
    public exportConsolidatedShipmentTemplate(guid: string, uniqueConsignmentReference: string, epdCountryCode: Array<string>, epdSafetyAndSecurityDataIndicator: boolean, epdContainerIndicator: boolean, type: 'xls' | 'xlsx', localeCode?: string, v2format?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.ms-excel' | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',}): Observable<HttpResponse<Blob>>;
    public exportConsolidatedShipmentTemplate(guid: string, uniqueConsignmentReference: string, epdCountryCode: Array<string>, epdSafetyAndSecurityDataIndicator: boolean, epdContainerIndicator: boolean, type: 'xls' | 'xlsx', localeCode?: string, v2format?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.ms-excel' | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',}): Observable<HttpEvent<Blob>>;
    public exportConsolidatedShipmentTemplate(guid: string, uniqueConsignmentReference: string, epdCountryCode: Array<string>, epdSafetyAndSecurityDataIndicator: boolean, epdContainerIndicator: boolean, type: 'xls' | 'xlsx', localeCode?: string, v2format?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/vnd.ms-excel' | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',}): Observable<any> {
        if (guid === null || guid === undefined) {
            throw new Error('Required parameter guid was null or undefined when calling exportConsolidatedShipmentTemplate.');
        }
        if (uniqueConsignmentReference === null || uniqueConsignmentReference === undefined) {
            throw new Error('Required parameter uniqueConsignmentReference was null or undefined when calling exportConsolidatedShipmentTemplate.');
        }
        if (epdCountryCode === null || epdCountryCode === undefined) {
            throw new Error('Required parameter epdCountryCode was null or undefined when calling exportConsolidatedShipmentTemplate.');
        }
        if (epdSafetyAndSecurityDataIndicator === null || epdSafetyAndSecurityDataIndicator === undefined) {
            throw new Error('Required parameter epdSafetyAndSecurityDataIndicator was null or undefined when calling exportConsolidatedShipmentTemplate.');
        }
        if (epdContainerIndicator === null || epdContainerIndicator === undefined) {
            throw new Error('Required parameter epdContainerIndicator was null or undefined when calling exportConsolidatedShipmentTemplate.');
        }
        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling exportConsolidatedShipmentTemplate.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (epdCountryCode) {
            epdCountryCode.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'epdCountryCode');
            })
        }
        if (epdSafetyAndSecurityDataIndicator !== undefined && epdSafetyAndSecurityDataIndicator !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>epdSafetyAndSecurityDataIndicator, 'epdSafetyAndSecurityDataIndicator');
        }
        if (epdContainerIndicator !== undefined && epdContainerIndicator !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>epdContainerIndicator, 'epdContainerIndicator');
        }
        if (type !== undefined && type !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>type, 'type');
        }
        if (localeCode !== undefined && localeCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>localeCode, 'localeCode');
        }
        if (v2format !== undefined && v2format !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>v2format, 'v2format');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (read-epd) required
        localVarCredential = this.configuration.lookupCredential('read-epd');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/vnd.ms-excel',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let localVarPath = `/epds/${this.configuration.encodeParam({name: "guid", value: guid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/consignments/${this.configuration.encodeParam({name: "uniqueConsignmentReference", value: uniqueConsignmentReference, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/consolidatedShipments`;
        return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find commodity code list based on exact listURI and value
     * @param code the list of codes to search
     * @param described whether to add descriptions in the result or not. Defaults to false.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findCommodityCodes(code: Array<Code>, described?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<Array<DescribedCode>>;
    public findCommodityCodes(code: Array<Code>, described?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<Array<DescribedCode>>>;
    public findCommodityCodes(code: Array<Code>, described?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<Array<DescribedCode>>>;
    public findCommodityCodes(code: Array<Code>, described?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling findCommodityCodes.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (described !== undefined && described !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>described, 'described');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (read-epd) required
        localVarCredential = this.configuration.lookupCredential('read-epd');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/global/commodity-codes`;
        return this.httpClient.request<Array<DescribedCode>>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: code,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search commodity code list based on criteria
     * @param term (start of) commodity code or (part of) description
     * @param listURI the listURI of the commodity classification codes
     * @param exactTermIndicator indicates whether the seach is exact or not
     * @param localeCode the locale (as a code) in which to limit description search
     * @param maxResults how many codes should match the \&#39;term\&#39; parameter (defaults to commodity.code.search.maxResults) 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCommodityCodes(term: string, listURI?: string, exactTermIndicator?: boolean, localeCode?: string, maxResults?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<Array<DescribedCodeTreeNode>>;
    public getCommodityCodes(term: string, listURI?: string, exactTermIndicator?: boolean, localeCode?: string, maxResults?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<Array<DescribedCodeTreeNode>>>;
    public getCommodityCodes(term: string, listURI?: string, exactTermIndicator?: boolean, localeCode?: string, maxResults?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<Array<DescribedCodeTreeNode>>>;
    public getCommodityCodes(term: string, listURI?: string, exactTermIndicator?: boolean, localeCode?: string, maxResults?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (term === null || term === undefined) {
            throw new Error('Required parameter term was null or undefined when calling getCommodityCodes.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (listURI !== undefined && listURI !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>listURI, 'listURI');
        }
        if (term !== undefined && term !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>term, 'term');
        }
        if (exactTermIndicator !== undefined && exactTermIndicator !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>exactTermIndicator, 'exactTermIndicator');
        }
        if (localeCode !== undefined && localeCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>localeCode, 'localeCode');
        }
        if (maxResults !== undefined && maxResults !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>maxResults, 'maxResults');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (read-epd) required
        localVarCredential = this.configuration.lookupCredential('read-epd');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/global/commodity-codes`;
        return this.httpClient.request<Array<DescribedCodeTreeNode>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the list of file extensions, max size, etc. supported per countries
     * @param countryCode the countries for which we look for the support of attachment
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConsignmentAttachmentSupport(countryCode?: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<Array<AttachmentSupport>>;
    public getConsignmentAttachmentSupport(countryCode?: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<Array<AttachmentSupport>>>;
    public getConsignmentAttachmentSupport(countryCode?: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<Array<AttachmentSupport>>>;
    public getConsignmentAttachmentSupport(countryCode?: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (countryCode) {
            countryCode.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'countryCode');
            })
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (default) required
        localVarCredential = this.configuration.lookupCredential('default');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/global/consignment/attachments`;
        return this.httpClient.request<Array<AttachmentSupport>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the types of additional information based on the type of document reference for a consignment
     * @param country the countries where the consignment information will be visible
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConsignmentDocumentReferenceAdditionalInformationTypes(country: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<Array<DocumentReferenceAdditionalInformationType>>;
    public getConsignmentDocumentReferenceAdditionalInformationTypes(country: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<Array<DocumentReferenceAdditionalInformationType>>>;
    public getConsignmentDocumentReferenceAdditionalInformationTypes(country: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<Array<DocumentReferenceAdditionalInformationType>>>;
    public getConsignmentDocumentReferenceAdditionalInformationTypes(country: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (country === null || country === undefined) {
            throw new Error('Required parameter country was null or undefined when calling getConsignmentDocumentReferenceAdditionalInformationTypes.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (country) {
            country.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'country');
            })
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (default) required
        localVarCredential = this.configuration.lookupCredential('default');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/global/consignment-document-reference-additional-information-types`;
        return this.httpClient.request<Array<DocumentReferenceAdditionalInformationType>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the list of types of document for a consignment
     * @param country the countries where the consignment information will be visible
     * @param applicability defaults to consignment if not set
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConsignmentDocumentReferenceTypes(country: Array<string>, applicability?: ConsignmentDocumentApplicability, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<Array<Code>>;
    public getConsignmentDocumentReferenceTypes(country: Array<string>, applicability?: ConsignmentDocumentApplicability, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<Array<Code>>>;
    public getConsignmentDocumentReferenceTypes(country: Array<string>, applicability?: ConsignmentDocumentApplicability, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<Array<Code>>>;
    public getConsignmentDocumentReferenceTypes(country: Array<string>, applicability?: ConsignmentDocumentApplicability, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (country === null || country === undefined) {
            throw new Error('Required parameter country was null or undefined when calling getConsignmentDocumentReferenceTypes.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (applicability !== undefined && applicability !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>applicability, 'applicability');
        }
        if (country) {
            country.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'country');
            })
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (default) required
        localVarCredential = this.configuration.lookupCredential('default');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/global/consignment-document-reference-types`;
        return this.httpClient.request<Array<Code>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the list of types of payments means applicable for freight allowance charge of a consignment
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConsignmentPaymentMeansCodes(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<Array<Code>>;
    public getConsignmentPaymentMeansCodes(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<Array<Code>>>;
    public getConsignmentPaymentMeansCodes(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<Array<Code>>>;
    public getConsignmentPaymentMeansCodes(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (default) required
        localVarCredential = this.configuration.lookupCredential('default');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/global/consignment-freightallowancecharge-paymentmeans-codes`;
        return this.httpClient.request<Array<Code>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the list of types of payments terms (aka conditions) applicable for a consignment
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConsignmentPaymentTermsCodes(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<Array<Code>>;
    public getConsignmentPaymentTermsCodes(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<Array<Code>>>;
    public getConsignmentPaymentTermsCodes(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<Array<Code>>>;
    public getConsignmentPaymentTermsCodes(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (default) required
        localVarCredential = this.configuration.lookupCredential('default');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/global/consignment-paymentterms-referenceeventcodes`;
        return this.httpClient.request<Array<Code>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getContainerSizesTypes(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<ContainerSizesTypes>;
    public getContainerSizesTypes(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<ContainerSizesTypes>>;
    public getContainerSizesTypes(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<ContainerSizesTypes>>;
    public getContainerSizesTypes(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (default) required
        localVarCredential = this.configuration.lookupCredential('default');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/global/container-sizes-types`;
        return this.httpClient.request<ContainerSizesTypes>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the list of customs offices
     * @param country only for selected country
     * @param role only for selected role
     * @param legalFramework only for selected legal framework
     * @param guaranteeFormat accepting the selected guarantee format
     * @param neighbouringCountry only for selected neighbouring country
     * @param neighbouringCode only for selected neighbouring customs office code
     * @param neighbouringLatitude the latitude of the point for the search customs offices arround.
     * @param neighbouringLongitude the longitude of the point for the search customs offices arround.
     * @param neighbouringDistance the maximum distance of the point for the search customs offices arround. In kilometers.
     * @param customsUnionName only for selected customs union
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCustomsOffices(country?: string, role?: CustomsOfficeRole, legalFramework?: TransitGuaranteeLegalFramework, guaranteeFormat?: TransitGuaranteeFormat, neighbouringCountry?: string, neighbouringCode?: string, neighbouringLatitude?: number, neighbouringLongitude?: number, neighbouringDistance?: number, customsUnionName?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<Array<CustomsOffice>>;
    public getCustomsOffices(country?: string, role?: CustomsOfficeRole, legalFramework?: TransitGuaranteeLegalFramework, guaranteeFormat?: TransitGuaranteeFormat, neighbouringCountry?: string, neighbouringCode?: string, neighbouringLatitude?: number, neighbouringLongitude?: number, neighbouringDistance?: number, customsUnionName?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<Array<CustomsOffice>>>;
    public getCustomsOffices(country?: string, role?: CustomsOfficeRole, legalFramework?: TransitGuaranteeLegalFramework, guaranteeFormat?: TransitGuaranteeFormat, neighbouringCountry?: string, neighbouringCode?: string, neighbouringLatitude?: number, neighbouringLongitude?: number, neighbouringDistance?: number, customsUnionName?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<Array<CustomsOffice>>>;
    public getCustomsOffices(country?: string, role?: CustomsOfficeRole, legalFramework?: TransitGuaranteeLegalFramework, guaranteeFormat?: TransitGuaranteeFormat, neighbouringCountry?: string, neighbouringCode?: string, neighbouringLatitude?: number, neighbouringLongitude?: number, neighbouringDistance?: number, customsUnionName?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (country !== undefined && country !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>country, 'country');
        }
        if (role !== undefined && role !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>role, 'role');
        }
        if (legalFramework !== undefined && legalFramework !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>legalFramework, 'legalFramework');
        }
        if (guaranteeFormat !== undefined && guaranteeFormat !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>guaranteeFormat, 'guaranteeFormat');
        }
        if (neighbouringCountry !== undefined && neighbouringCountry !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>neighbouringCountry, 'neighbouring-country');
        }
        if (neighbouringCode !== undefined && neighbouringCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>neighbouringCode, 'neighbouring-code');
        }
        if (neighbouringLatitude !== undefined && neighbouringLatitude !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>neighbouringLatitude, 'neighbouring-latitude');
        }
        if (neighbouringLongitude !== undefined && neighbouringLongitude !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>neighbouringLongitude, 'neighbouring-longitude');
        }
        if (neighbouringDistance !== undefined && neighbouringDistance !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>neighbouringDistance, 'neighbouring-distance');
        }
        if (customsUnionName !== undefined && customsUnionName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>customsUnionName, 'customsUnionName');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (read-epd) required
        localVarCredential = this.configuration.lookupCredential('read-epd');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/global/customs-offices`;
        return this.httpClient.request<Array<CustomsOffice>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the pre-declaration
     * @param guid the identifier of the pre-declaration
     * @param onBehalfOf the (optional) holder of the guarantee that wants the party to act on the EPD on his behalf
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEpd(guid: string, onBehalfOf?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<Predeclaration>;
    public getEpd(guid: string, onBehalfOf?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<Predeclaration>>;
    public getEpd(guid: string, onBehalfOf?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<Predeclaration>>;
    public getEpd(guid: string, onBehalfOf?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (guid === null || guid === undefined) {
            throw new Error('Required parameter guid was null or undefined when calling getEpd.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (onBehalfOf !== undefined && onBehalfOf !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>onBehalfOf, 'onBehalfOf');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (read-epd) required
        localVarCredential = this.configuration.lookupCredential('read-epd');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/epds/${this.configuration.encodeParam({name: "guid", value: guid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<Predeclaration>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the additional properties for a particular commodity code
     * @param commodityCodeListURI the listURI of the commodity classification code
     * @param commodityCodeValue the value of the commodity classification code
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEpdGoodsItemAdditionalPropertiesRule(commodityCodeListURI: string, commodityCodeValue: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<CommodityClassificationAdditionalItemProperties>;
    public getEpdGoodsItemAdditionalPropertiesRule(commodityCodeListURI: string, commodityCodeValue: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<CommodityClassificationAdditionalItemProperties>>;
    public getEpdGoodsItemAdditionalPropertiesRule(commodityCodeListURI: string, commodityCodeValue: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<CommodityClassificationAdditionalItemProperties>>;
    public getEpdGoodsItemAdditionalPropertiesRule(commodityCodeListURI: string, commodityCodeValue: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (commodityCodeListURI === null || commodityCodeListURI === undefined) {
            throw new Error('Required parameter commodityCodeListURI was null or undefined when calling getEpdGoodsItemAdditionalPropertiesRule.');
        }
        if (commodityCodeValue === null || commodityCodeValue === undefined) {
            throw new Error('Required parameter commodityCodeValue was null or undefined when calling getEpdGoodsItemAdditionalPropertiesRule.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (commodityCodeListURI !== undefined && commodityCodeListURI !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>commodityCodeListURI, 'commodityCodeListURI');
        }
        if (commodityCodeValue !== undefined && commodityCodeValue !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>commodityCodeValue, 'commodityCodeValue');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (read-epd) required
        localVarCredential = this.configuration.lookupCredential('read-epd');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/global/epd-goodsitem-additionalproperties-rule`;
        return this.httpClient.request<CommodityClassificationAdditionalItemProperties>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the document reference types for a particular commodity code
     * @param commodityCodeListURI the listURI of the commodity classification code
     * @param commodityCodeValue the value of the commodity classification code
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEpdGoodsItemDocumentReferenceTypes(commodityCodeListURI: string, commodityCodeValue: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<CommodityClassificationDocumentReferenceTypes>;
    public getEpdGoodsItemDocumentReferenceTypes(commodityCodeListURI: string, commodityCodeValue: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<CommodityClassificationDocumentReferenceTypes>>;
    public getEpdGoodsItemDocumentReferenceTypes(commodityCodeListURI: string, commodityCodeValue: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<CommodityClassificationDocumentReferenceTypes>>;
    public getEpdGoodsItemDocumentReferenceTypes(commodityCodeListURI: string, commodityCodeValue: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (commodityCodeListURI === null || commodityCodeListURI === undefined) {
            throw new Error('Required parameter commodityCodeListURI was null or undefined when calling getEpdGoodsItemDocumentReferenceTypes.');
        }
        if (commodityCodeValue === null || commodityCodeValue === undefined) {
            throw new Error('Required parameter commodityCodeValue was null or undefined when calling getEpdGoodsItemDocumentReferenceTypes.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (commodityCodeListURI !== undefined && commodityCodeListURI !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>commodityCodeListURI, 'commodityCodeListURI');
        }
        if (commodityCodeValue !== undefined && commodityCodeValue !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>commodityCodeValue, 'commodityCodeValue');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (read-epd) required
        localVarCredential = this.configuration.lookupCredential('read-epd');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/global/epd-goodsitem-documentreference-types`;
        return this.httpClient.request<CommodityClassificationDocumentReferenceTypes>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the pre-declaration documents, per typeCode
     * @param guid the identifier of the pre-declaration
     * @param typeCode the type of document
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEpdMRNs(guid: string, typeCode: 'MRN', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<Array<TransportOperation>>;
    public getEpdMRNs(guid: string, typeCode: 'MRN', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<Array<TransportOperation>>>;
    public getEpdMRNs(guid: string, typeCode: 'MRN', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<Array<TransportOperation>>>;
    public getEpdMRNs(guid: string, typeCode: 'MRN', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (guid === null || guid === undefined) {
            throw new Error('Required parameter guid was null or undefined when calling getEpdMRNs.');
        }
        if (typeCode === null || typeCode === undefined) {
            throw new Error('Required parameter typeCode was null or undefined when calling getEpdMRNs.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (typeCode !== undefined && typeCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>typeCode, 'typeCode');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (update-epd) required
        localVarCredential = this.configuration.lookupCredential('update-epd');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/epds/${this.configuration.encodeParam({name: "guid", value: guid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/transport/operations/documentReferences`;
        return this.httpClient.request<Array<TransportOperation>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the list of markdowns to show on epd step screen
     * @param guid the identifier of the pre-declaration
     * @param step the pre-declaration step
     * @param localeCode the user locale
     * @param stepLocation the location inside the pre-declaration step ; useful for complex steps like consigments
     * @param countryTransitOperationCode the message types per country where the epd is sent. Encoded as ISO3 + \&#39;.\&#39; + MOVEMENT_CODE + \&#39;.\&#39; + MESSAGETYPE
     * @param itineraryCountryCode the ISO3 code of the country in the itinerary
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEpdMarkdowns(guid: string, step: number, localeCode: string, stepLocation?: ApplicationMarkdownExtensionPredeclarationStepLocation, countryTransitOperationCode?: Array<string>, itineraryCountryCode?: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<Array<ApplicationMarkdownExtension>>;
    public getEpdMarkdowns(guid: string, step: number, localeCode: string, stepLocation?: ApplicationMarkdownExtensionPredeclarationStepLocation, countryTransitOperationCode?: Array<string>, itineraryCountryCode?: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<Array<ApplicationMarkdownExtension>>>;
    public getEpdMarkdowns(guid: string, step: number, localeCode: string, stepLocation?: ApplicationMarkdownExtensionPredeclarationStepLocation, countryTransitOperationCode?: Array<string>, itineraryCountryCode?: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<Array<ApplicationMarkdownExtension>>>;
    public getEpdMarkdowns(guid: string, step: number, localeCode: string, stepLocation?: ApplicationMarkdownExtensionPredeclarationStepLocation, countryTransitOperationCode?: Array<string>, itineraryCountryCode?: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (guid === null || guid === undefined) {
            throw new Error('Required parameter guid was null or undefined when calling getEpdMarkdowns.');
        }
        if (step === null || step === undefined) {
            throw new Error('Required parameter step was null or undefined when calling getEpdMarkdowns.');
        }
        if (localeCode === null || localeCode === undefined) {
            throw new Error('Required parameter localeCode was null or undefined when calling getEpdMarkdowns.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (step !== undefined && step !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>step, 'step');
        }
        if (stepLocation !== undefined && stepLocation !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>stepLocation, 'stepLocation');
        }
        if (localeCode !== undefined && localeCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>localeCode, 'localeCode');
        }
        if (countryTransitOperationCode) {
            countryTransitOperationCode.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'countryTransitOperationCode');
            })
        }
        if (itineraryCountryCode) {
            itineraryCountryCode.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'itineraryCountryCode');
            })
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (read-epd) required
        localVarCredential = this.configuration.lookupCredential('read-epd');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/epds/${this.configuration.encodeParam({name: "guid", value: guid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/markdowns`;
        return this.httpClient.request<Array<ApplicationMarkdownExtension>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the list of rules to handle pre-declarations
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEpdRules(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<Array<Rule>>;
    public getEpdRules(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<Array<Rule>>>;
    public getEpdRules(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<Array<Rule>>>;
    public getEpdRules(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (read-epd) required
        localVarCredential = this.configuration.lookupCredential('read-epd');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/global/epd-rules`;
        return this.httpClient.request<Array<Rule>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the pre-declaration status
     * @param guid the identifier of the pre-declaration
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEpdStatus(guid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<PredeclarationStatus>;
    public getEpdStatus(guid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<PredeclarationStatus>>;
    public getEpdStatus(guid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<PredeclarationStatus>>;
    public getEpdStatus(guid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (guid === null || guid === undefined) {
            throw new Error('Required parameter guid was null or undefined when calling getEpdStatus.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (read-epd) required
        localVarCredential = this.configuration.lookupCredential('read-epd');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/epds/${this.configuration.encodeParam({name: "guid", value: guid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/metadata/status`;
        return this.httpClient.request<PredeclarationStatus>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the list of types of unit of measurements
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMeasurementUnitCodes(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<Array<Code>>;
    public getMeasurementUnitCodes(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<Array<Code>>>;
    public getMeasurementUnitCodes(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<Array<Code>>>;
    public getMeasurementUnitCodes(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (default) required
        localVarCredential = this.configuration.lookupCredential('default');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/global/measurement-unit-codes`;
        return this.httpClient.request<Array<Code>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the list of movement codes
     * @param applicability applicability of the movement code
     * @param country only for selected country
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMovementCodes(applicability: MovementCodeApplicability, country: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<Array<string>>;
    public getMovementCodes(applicability: MovementCodeApplicability, country: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<Array<string>>>;
    public getMovementCodes(applicability: MovementCodeApplicability, country: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<Array<string>>>;
    public getMovementCodes(applicability: MovementCodeApplicability, country: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (applicability === null || applicability === undefined) {
            throw new Error('Required parameter applicability was null or undefined when calling getMovementCodes.');
        }
        if (country === null || country === undefined) {
            throw new Error('Required parameter country was null or undefined when calling getMovementCodes.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (read-epd) required
        localVarCredential = this.configuration.lookupCredential('read-epd');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/global/movement-codes/${this.configuration.encodeParam({name: "applicability", value: applicability, in: "path", style: "simple", explode: false, dataType: "MovementCodeApplicability", dataFormat: undefined})}/${this.configuration.encodeParam({name: "country", value: country, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<Array<string>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the list of file extensions, max size, etc. supported
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPredeclarationAttachmentSupport(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<AttachmentSupport>;
    public getPredeclarationAttachmentSupport(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<AttachmentSupport>>;
    public getPredeclarationAttachmentSupport(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<AttachmentSupport>>;
    public getPredeclarationAttachmentSupport(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (default) required
        localVarCredential = this.configuration.lookupCredential('default');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/global/epd/attachments`;
        return this.httpClient.request<AttachmentSupport>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the list of registration number prefixes (by country)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRegistrationNumberPrefixes(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<Array<VehicleRegistrationNumberValueCountryPrefixes>>;
    public getRegistrationNumberPrefixes(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<Array<VehicleRegistrationNumberValueCountryPrefixes>>>;
    public getRegistrationNumberPrefixes(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<Array<VehicleRegistrationNumberValueCountryPrefixes>>>;
    public getRegistrationNumberPrefixes(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (default) required
        localVarCredential = this.configuration.lookupCredential('default');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/global/registration-number-prefixes`;
        return this.httpClient.request<Array<VehicleRegistrationNumberValueCountryPrefixes>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the transport operations message exchanges
     * @param guid the identifier of the pre-declaration
     * @param noReplacedBy whether to load the message exchanges pointed by \&quot;replacedBy\&quot; property
     * @param noTerminationMessage whether to load the messages related to the operation termination
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTOMessageExchanges(guid: string, noReplacedBy?: boolean, noTerminationMessage?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<Array<TransportOperationMessageExchange>>;
    public getTOMessageExchanges(guid: string, noReplacedBy?: boolean, noTerminationMessage?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<Array<TransportOperationMessageExchange>>>;
    public getTOMessageExchanges(guid: string, noReplacedBy?: boolean, noTerminationMessage?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<Array<TransportOperationMessageExchange>>>;
    public getTOMessageExchanges(guid: string, noReplacedBy?: boolean, noTerminationMessage?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (guid === null || guid === undefined) {
            throw new Error('Required parameter guid was null or undefined when calling getTOMessageExchanges.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (noReplacedBy !== undefined && noReplacedBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>noReplacedBy, 'noReplacedBy');
        }
        if (noTerminationMessage !== undefined && noTerminationMessage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>noTerminationMessage, 'noTerminationMessage');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (read-epd) required
        localVarCredential = this.configuration.lookupCredential('read-epd');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/epds/${this.configuration.encodeParam({name: "guid", value: guid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/transport/operations/message-exchanges`;
        return this.httpClient.request<Array<TransportOperationMessageExchange>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the list of types of packaging
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTransportHandlingUnitPackagingTypes(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<Array<PackagingType>>;
    public getTransportHandlingUnitPackagingTypes(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<Array<PackagingType>>>;
    public getTransportHandlingUnitPackagingTypes(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<Array<PackagingType>>>;
    public getTransportHandlingUnitPackagingTypes(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (default) required
        localVarCredential = this.configuration.lookupCredential('default');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/global/transporthandlingunit-packagingtypes`;
        return this.httpClient.request<Array<PackagingType>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the list of types of document for driver
     * @param citizenship citizenship of the driver
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVehicleDriverDocumentReferenceTypes(citizenship?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<Array<Code>>;
    public getVehicleDriverDocumentReferenceTypes(citizenship?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<Array<Code>>>;
    public getVehicleDriverDocumentReferenceTypes(citizenship?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<Array<Code>>>;
    public getVehicleDriverDocumentReferenceTypes(citizenship?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (citizenship !== undefined && citizenship !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>citizenship, 'citizenship');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (default) required
        localVarCredential = this.configuration.lookupCredential('default');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/global/driver-document-reference-types`;
        return this.httpClient.request<Array<Code>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the list of vehicle makes
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVehicleMakes(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<Array<string>>;
    public getVehicleMakes(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<Array<string>>>;
    public getVehicleMakes(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<Array<string>>>;
    public getVehicleMakes(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (default) required
        localVarCredential = this.configuration.lookupCredential('default');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/global/vehicle-makes`;
        return this.httpClient.request<Array<string>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the list of vehicle additional properties values (grouped by type)
     * @param propertyName the name of the property
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVehicleProperties(propertyName: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<Array<VehicleTypeAdditionalPropertyValues>>;
    public getVehicleProperties(propertyName: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<Array<VehicleTypeAdditionalPropertyValues>>>;
    public getVehicleProperties(propertyName: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<Array<VehicleTypeAdditionalPropertyValues>>>;
    public getVehicleProperties(propertyName: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (propertyName === null || propertyName === undefined) {
            throw new Error('Required parameter propertyName was null or undefined when calling getVehicleProperties.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (default) required
        localVarCredential = this.configuration.lookupCredential('default');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/global/vehicle-additional-properties/${this.configuration.encodeParam({name: "propertyName", value: propertyName, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<Array<VehicleTypeAdditionalPropertyValues>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Import existing and new consolidated shipments in consignment
     * @param guid the identifier of the pre-declaration
     * @param uniqueConsignmentReference the unique consignment reference
     * @param bypassValidation indicates that the goods items should be inserted as-is
     * @param bypassSave indicates that no save should be performed.
     * @param epd 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public importConsolidatedShipmentTemplate(guid: string, uniqueConsignmentReference: string, bypassValidation?: boolean, bypassSave?: boolean, epd?: string, file?: Blob, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<Array<Shipment>>;
    public importConsolidatedShipmentTemplate(guid: string, uniqueConsignmentReference: string, bypassValidation?: boolean, bypassSave?: boolean, epd?: string, file?: Blob, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<Array<Shipment>>>;
    public importConsolidatedShipmentTemplate(guid: string, uniqueConsignmentReference: string, bypassValidation?: boolean, bypassSave?: boolean, epd?: string, file?: Blob, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<Array<Shipment>>>;
    public importConsolidatedShipmentTemplate(guid: string, uniqueConsignmentReference: string, bypassValidation?: boolean, bypassSave?: boolean, epd?: string, file?: Blob, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (guid === null || guid === undefined) {
            throw new Error('Required parameter guid was null or undefined when calling importConsolidatedShipmentTemplate.');
        }
        if (uniqueConsignmentReference === null || uniqueConsignmentReference === undefined) {
            throw new Error('Required parameter uniqueConsignmentReference was null or undefined when calling importConsolidatedShipmentTemplate.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (bypassValidation !== undefined && bypassValidation !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>bypassValidation, 'bypassValidation');
        }
        if (bypassSave !== undefined && bypassSave !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>bypassSave, 'bypassSave');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (update-epd) required
        localVarCredential = this.configuration.lookupCredential('update-epd');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any, filename?: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (epd !== undefined) {
            localVarFormParams = localVarFormParams.append('epd', <any>epd) as any || localVarFormParams;
        }
        if (file !== undefined) {
            localVarFormParams = localVarFormParams.append('file', <any>file, 'utf-8\'\'' + encodeURIComponent((<File>file).name)) as any || localVarFormParams;
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/epds/${this.configuration.encodeParam({name: "guid", value: guid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/consignments/${this.configuration.encodeParam({name: "uniqueConsignmentReference", value: uniqueConsignmentReference, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/consolidatedShipments`;
        return this.httpClient.request<Array<Shipment>>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Load a guarantee
     * @param guaranteeNumber the guarantee number
     * @param holderId the id of the holder of the guarantee ; if none, the organisation of the logged-in user
     * @param transitCountryCode the codes of the countries where the transit guarantee will be used ; if not set, then tokenConsumption is loaded for all possible countries 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public loadGuarantee(guaranteeNumber: string, holderId?: string, transitCountryCode?: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<TransitGuarantee>;
    public loadGuarantee(guaranteeNumber: string, holderId?: string, transitCountryCode?: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<TransitGuarantee>>;
    public loadGuarantee(guaranteeNumber: string, holderId?: string, transitCountryCode?: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<TransitGuarantee>>;
    public loadGuarantee(guaranteeNumber: string, holderId?: string, transitCountryCode?: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (guaranteeNumber === null || guaranteeNumber === undefined) {
            throw new Error('Required parameter guaranteeNumber was null or undefined when calling loadGuarantee.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (holderId !== undefined && holderId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>holderId, 'holderId');
        }
        if (transitCountryCode) {
            transitCountryCode.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'transitCountryCode');
            })
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (read-epd) required
        localVarCredential = this.configuration.lookupCredential('read-epd');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/guarantees/${this.configuration.encodeParam({name: "guaranteeNumber", value: guaranteeNumber, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<TransitGuarantee>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Load the content of the technical message pointed by the business message
     * @param guid the identifier of the pre-declaration
     * @param opseq the transport operation sequence in the pre-declaration
     * @param mxId the identifier of the message exchange
     * @param msgId the identifier of the message
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public loadTOMxMessageContent(guid: string, opseq: number, mxId: string, msgId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream',}): Observable<Blob>;
    public loadTOMxMessageContent(guid: string, opseq: number, mxId: string, msgId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream',}): Observable<HttpResponse<Blob>>;
    public loadTOMxMessageContent(guid: string, opseq: number, mxId: string, msgId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream',}): Observable<HttpEvent<Blob>>;
    public loadTOMxMessageContent(guid: string, opseq: number, mxId: string, msgId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/octet-stream',}): Observable<any> {
        if (guid === null || guid === undefined) {
            throw new Error('Required parameter guid was null or undefined when calling loadTOMxMessageContent.');
        }
        if (opseq === null || opseq === undefined) {
            throw new Error('Required parameter opseq was null or undefined when calling loadTOMxMessageContent.');
        }
        if (mxId === null || mxId === undefined) {
            throw new Error('Required parameter mxId was null or undefined when calling loadTOMxMessageContent.');
        }
        if (msgId === null || msgId === undefined) {
            throw new Error('Required parameter msgId was null or undefined when calling loadTOMxMessageContent.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (read-epd) required
        localVarCredential = this.configuration.lookupCredential('read-epd');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/octet-stream'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let localVarPath = `/epds/${this.configuration.encodeParam({name: "guid", value: guid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/transport/operations/${this.configuration.encodeParam({name: "opseq", value: opseq, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/message-exchanges/${this.configuration.encodeParam({name: "mxId", value: mxId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/messages/${this.configuration.encodeParam({name: "msgId", value: msgId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update the pre-declaration description
     * @param guid the identifier of the pre-declaration
     * @param body the new description
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchDescription(guid: string, body: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<any>;
    public patchDescription(guid: string, body: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<HttpResponse<any>>;
    public patchDescription(guid: string, body: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<HttpEvent<any>>;
    public patchDescription(guid: string, body: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined,}): Observable<any> {
        if (guid === null || guid === undefined) {
            throw new Error('Required parameter guid was null or undefined when calling patchDescription.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling patchDescription.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (update-epd) required
        localVarCredential = this.configuration.lookupCredential('update-epd');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/epds/${this.configuration.encodeParam({name: "guid", value: guid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/metadata/description`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                body: body,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Save the predeclaration process preferences
     * @param organisationPredeclarationSettings the predeclaration preferences
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchEPDPreferences(organisationPredeclarationSettings: OrganisationPredeclarationSettings, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<any>;
    public patchEPDPreferences(organisationPredeclarationSettings: OrganisationPredeclarationSettings, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<HttpResponse<any>>;
    public patchEPDPreferences(organisationPredeclarationSettings: OrganisationPredeclarationSettings, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<HttpEvent<any>>;
    public patchEPDPreferences(organisationPredeclarationSettings: OrganisationPredeclarationSettings, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined,}): Observable<any> {
        if (organisationPredeclarationSettings === null || organisationPredeclarationSettings === undefined) {
            throw new Error('Required parameter organisationPredeclarationSettings was null or undefined when calling patchEPDPreferences.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (update-epd) required
        localVarCredential = this.configuration.lookupCredential('update-epd');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/organisation/appSettings/predeclaration`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: organisationPredeclarationSettings,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Predeclaration in a printable format
     * @param guid the identifier of the pre-declaration
     * @param template the template to use for printing
     * @param predeclaration the pre-declaration content
     * @param localeCode the locale code for the labels in the printout, if supported (defaults to British English)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public printEpd(guid: string, template: PrintableDocumentTemplate, predeclaration: Predeclaration, localeCode?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/pdf',}): Observable<Blob>;
    public printEpd(guid: string, template: PrintableDocumentTemplate, predeclaration: Predeclaration, localeCode?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/pdf',}): Observable<HttpResponse<Blob>>;
    public printEpd(guid: string, template: PrintableDocumentTemplate, predeclaration: Predeclaration, localeCode?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/pdf',}): Observable<HttpEvent<Blob>>;
    public printEpd(guid: string, template: PrintableDocumentTemplate, predeclaration: Predeclaration, localeCode?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/pdf',}): Observable<any> {
        if (guid === null || guid === undefined) {
            throw new Error('Required parameter guid was null or undefined when calling printEpd.');
        }
        if (template === null || template === undefined) {
            throw new Error('Required parameter template was null or undefined when calling printEpd.');
        }
        if (predeclaration === null || predeclaration === undefined) {
            throw new Error('Required parameter predeclaration was null or undefined when calling printEpd.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (template !== undefined && template !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>template, 'template');
        }
        if (localeCode !== undefined && localeCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>localeCode, 'localeCode');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (read-epd) required
        localVarCredential = this.configuration.lookupCredential('read-epd');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/pdf'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/epds/${this.configuration.encodeParam({name: "guid", value: guid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/print.pdf`;
        return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: predeclaration,
                params: localVarQueryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Print the EPD pass for all operations
     * @param guid the identifier of the pre-declaration
     * @param noReplacedBy whether to load the message exchanges pointed by \&quot;replacedBy\&quot; property
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public printEpdPass(guid: string, noReplacedBy?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/pdf',}): Observable<Blob>;
    public printEpdPass(guid: string, noReplacedBy?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/pdf',}): Observable<HttpResponse<Blob>>;
    public printEpdPass(guid: string, noReplacedBy?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/pdf',}): Observable<HttpEvent<Blob>>;
    public printEpdPass(guid: string, noReplacedBy?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/pdf',}): Observable<any> {
        if (guid === null || guid === undefined) {
            throw new Error('Required parameter guid was null or undefined when calling printEpdPass.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (noReplacedBy !== undefined && noReplacedBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>noReplacedBy, 'noReplacedBy');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (read-epd) required
        localVarCredential = this.configuration.lookupCredential('read-epd');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/pdf'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let localVarPath = `/epds/${this.configuration.encodeParam({name: "guid", value: guid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/transport/operations/pass.pdf`;
        return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Print the EPD pass for the given transport operation and message exchange
     * @param guid the identifier of the pre-declaration
     * @param opseq the transport operation sequence in the pre-declaration
     * @param mxId the identifier of the message exchange
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public printEpdTOMxPass(guid: string, opseq: number, mxId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/pdf',}): Observable<Blob>;
    public printEpdTOMxPass(guid: string, opseq: number, mxId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/pdf',}): Observable<HttpResponse<Blob>>;
    public printEpdTOMxPass(guid: string, opseq: number, mxId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/pdf',}): Observable<HttpEvent<Blob>>;
    public printEpdTOMxPass(guid: string, opseq: number, mxId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/pdf',}): Observable<any> {
        if (guid === null || guid === undefined) {
            throw new Error('Required parameter guid was null or undefined when calling printEpdTOMxPass.');
        }
        if (opseq === null || opseq === undefined) {
            throw new Error('Required parameter opseq was null or undefined when calling printEpdTOMxPass.');
        }
        if (mxId === null || mxId === undefined) {
            throw new Error('Required parameter mxId was null or undefined when calling printEpdTOMxPass.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (read-epd) required
        localVarCredential = this.configuration.lookupCredential('read-epd');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/pdf'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let localVarPath = `/epds/${this.configuration.encodeParam({name: "guid", value: guid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/transport/operations/${this.configuration.encodeParam({name: "opseq", value: opseq, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/message-exchanges/${this.configuration.encodeParam({name: "mxId", value: mxId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/pass.pdf`;
        return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Print the EPD pass for the given transport operation
     * @param guid the identifier of the pre-declaration
     * @param opseq the transport operation sequence in the pre-declaration
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public printEpdTOPass(guid: string, opseq: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/pdf',}): Observable<Blob>;
    public printEpdTOPass(guid: string, opseq: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/pdf',}): Observable<HttpResponse<Blob>>;
    public printEpdTOPass(guid: string, opseq: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/pdf',}): Observable<HttpEvent<Blob>>;
    public printEpdTOPass(guid: string, opseq: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/pdf',}): Observable<any> {
        if (guid === null || guid === undefined) {
            throw new Error('Required parameter guid was null or undefined when calling printEpdTOPass.');
        }
        if (opseq === null || opseq === undefined) {
            throw new Error('Required parameter opseq was null or undefined when calling printEpdTOPass.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (read-epd) required
        localVarCredential = this.configuration.lookupCredential('read-epd');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/pdf'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let localVarPath = `/epds/${this.configuration.encodeParam({name: "guid", value: guid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/transport/operations/${this.configuration.encodeParam({name: "opseq", value: opseq, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/pass.pdf`;
        return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Predeclaration in a printable format
     * @param guid the identifier of the pre-declaration
     * @param template the template to use for printing
     * @param localeCode the locale code for the labels in the printout, if supported (defaults to British English)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public printExistingEpd(guid: string, template: PrintableDocumentTemplate, localeCode?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/pdf',}): Observable<Blob>;
    public printExistingEpd(guid: string, template: PrintableDocumentTemplate, localeCode?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/pdf',}): Observable<HttpResponse<Blob>>;
    public printExistingEpd(guid: string, template: PrintableDocumentTemplate, localeCode?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/pdf',}): Observable<HttpEvent<Blob>>;
    public printExistingEpd(guid: string, template: PrintableDocumentTemplate, localeCode?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/pdf',}): Observable<any> {
        if (guid === null || guid === undefined) {
            throw new Error('Required parameter guid was null or undefined when calling printExistingEpd.');
        }
        if (template === null || template === undefined) {
            throw new Error('Required parameter template was null or undefined when calling printExistingEpd.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (template !== undefined && template !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>template, 'template');
        }
        if (localeCode !== undefined && localeCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>localeCode, 'localeCode');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (read-epd) required
        localVarCredential = this.configuration.lookupCredential('read-epd');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/pdf'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let localVarPath = `/epds/${this.configuration.encodeParam({name: "guid", value: guid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/print.pdf`;
        return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the content of a attachment
     * @param guid the identifier of the pre-declaration
     * @param uniqueConsignmentReference the unique consignment reference
     * @param attachmentId the attachment identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readConsignmentAttachmentContent(guid: string, uniqueConsignmentReference: string, attachmentId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream',}): Observable<Blob>;
    public readConsignmentAttachmentContent(guid: string, uniqueConsignmentReference: string, attachmentId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream',}): Observable<HttpResponse<Blob>>;
    public readConsignmentAttachmentContent(guid: string, uniqueConsignmentReference: string, attachmentId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream',}): Observable<HttpEvent<Blob>>;
    public readConsignmentAttachmentContent(guid: string, uniqueConsignmentReference: string, attachmentId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/octet-stream',}): Observable<any> {
        if (guid === null || guid === undefined) {
            throw new Error('Required parameter guid was null or undefined when calling readConsignmentAttachmentContent.');
        }
        if (uniqueConsignmentReference === null || uniqueConsignmentReference === undefined) {
            throw new Error('Required parameter uniqueConsignmentReference was null or undefined when calling readConsignmentAttachmentContent.');
        }
        if (attachmentId === null || attachmentId === undefined) {
            throw new Error('Required parameter attachmentId was null or undefined when calling readConsignmentAttachmentContent.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (read-epd) required
        localVarCredential = this.configuration.lookupCredential('read-epd');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/octet-stream'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let localVarPath = `/epds/${this.configuration.encodeParam({name: "guid", value: guid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/consignments/${this.configuration.encodeParam({name: "uniqueConsignmentReference", value: uniqueConsignmentReference, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/attachments/${this.configuration.encodeParam({name: "attachmentId", value: attachmentId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/content`;
        return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the reference of a attachment
     * @param guid the identifier of the pre-declaration
     * @param uniqueConsignmentReference the unique consignment reference
     * @param attachmentId the attachment identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readConsignmentAttachmentReference(guid: string, uniqueConsignmentReference: string, attachmentId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<AttachmentReference>;
    public readConsignmentAttachmentReference(guid: string, uniqueConsignmentReference: string, attachmentId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<AttachmentReference>>;
    public readConsignmentAttachmentReference(guid: string, uniqueConsignmentReference: string, attachmentId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<AttachmentReference>>;
    public readConsignmentAttachmentReference(guid: string, uniqueConsignmentReference: string, attachmentId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (guid === null || guid === undefined) {
            throw new Error('Required parameter guid was null or undefined when calling readConsignmentAttachmentReference.');
        }
        if (uniqueConsignmentReference === null || uniqueConsignmentReference === undefined) {
            throw new Error('Required parameter uniqueConsignmentReference was null or undefined when calling readConsignmentAttachmentReference.');
        }
        if (attachmentId === null || attachmentId === undefined) {
            throw new Error('Required parameter attachmentId was null or undefined when calling readConsignmentAttachmentReference.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (read-epd) required
        localVarCredential = this.configuration.lookupCredential('read-epd');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/epds/${this.configuration.encodeParam({name: "guid", value: guid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/consignments/${this.configuration.encodeParam({name: "uniqueConsignmentReference", value: uniqueConsignmentReference, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/attachments/${this.configuration.encodeParam({name: "attachmentId", value: attachmentId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<AttachmentReference>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the content of a attachment
     * @param guid the identifier of the pre-declaration
     * @param attachmentId the attachment identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readPredeclarationAttachmentContent(guid: string, attachmentId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream',}): Observable<Blob>;
    public readPredeclarationAttachmentContent(guid: string, attachmentId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream',}): Observable<HttpResponse<Blob>>;
    public readPredeclarationAttachmentContent(guid: string, attachmentId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream',}): Observable<HttpEvent<Blob>>;
    public readPredeclarationAttachmentContent(guid: string, attachmentId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/octet-stream',}): Observable<any> {
        if (guid === null || guid === undefined) {
            throw new Error('Required parameter guid was null or undefined when calling readPredeclarationAttachmentContent.');
        }
        if (attachmentId === null || attachmentId === undefined) {
            throw new Error('Required parameter attachmentId was null or undefined when calling readPredeclarationAttachmentContent.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (read-epd) required
        localVarCredential = this.configuration.lookupCredential('read-epd');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/octet-stream'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let localVarPath = `/epds/${this.configuration.encodeParam({name: "guid", value: guid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/attachments/${this.configuration.encodeParam({name: "attachmentId", value: attachmentId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/content`;
        return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the content of a attachment
     * @param guid the identifier of the pre-declaration
     * @param attachmentId the attachment identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readPredeclarationAttachmentReference(guid: string, attachmentId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<AttachmentReference>;
    public readPredeclarationAttachmentReference(guid: string, attachmentId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<AttachmentReference>>;
    public readPredeclarationAttachmentReference(guid: string, attachmentId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<AttachmentReference>>;
    public readPredeclarationAttachmentReference(guid: string, attachmentId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (guid === null || guid === undefined) {
            throw new Error('Required parameter guid was null or undefined when calling readPredeclarationAttachmentReference.');
        }
        if (attachmentId === null || attachmentId === undefined) {
            throw new Error('Required parameter attachmentId was null or undefined when calling readPredeclarationAttachmentReference.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (read-epd) required
        localVarCredential = this.configuration.lookupCredential('read-epd');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/epds/${this.configuration.encodeParam({name: "guid", value: guid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/attachments/${this.configuration.encodeParam({name: "attachmentId", value: attachmentId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<AttachmentReference>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List the uploaded attachments related to a consignment
     * @param guid the identifier of the pre-declaration
     * @param uniqueConsignmentReference the unique consignment reference
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchConsignmentAttachments(guid: string, uniqueConsignmentReference: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<Array<AttachmentReference>>;
    public searchConsignmentAttachments(guid: string, uniqueConsignmentReference: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<Array<AttachmentReference>>>;
    public searchConsignmentAttachments(guid: string, uniqueConsignmentReference: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<Array<AttachmentReference>>>;
    public searchConsignmentAttachments(guid: string, uniqueConsignmentReference: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (guid === null || guid === undefined) {
            throw new Error('Required parameter guid was null or undefined when calling searchConsignmentAttachments.');
        }
        if (uniqueConsignmentReference === null || uniqueConsignmentReference === undefined) {
            throw new Error('Required parameter uniqueConsignmentReference was null or undefined when calling searchConsignmentAttachments.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (read-epd) required
        localVarCredential = this.configuration.lookupCredential('read-epd');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/epds/${this.configuration.encodeParam({name: "guid", value: guid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/consignments/${this.configuration.encodeParam({name: "uniqueConsignmentReference", value: uniqueConsignmentReference, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/attachments`;
        return this.httpClient.request<Array<AttachmentReference>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search for EPDs
     * @param ds the datasource to query
     * @param holderId the id of the holder of the guarantee
     * @param guaranteeNumber the guarantee number entered in the predeclaration
     * @param id the predeclaration id, complete or end (cf support pin)
     * @param description the description as found in the metadata (not exact search)
     * @param lrn the lrn as created by messaging application
     * @param mrn the mrn as allocated by customs authority
     * @param submissionCountryCode a country, encoded as ISO3, where the predeclaration is sent to
     * @param from the parameter is mandatory if the no restrictive search term is set and it cannot go before 1 month in the past (unless to is specified too)
     * @param to the period between from and to cannot exceed 1 month (epd.search.max.period&#x3D;P1M)
     * @param appStatus the parameter can be used if \&#39;ds\&#39; is not \&#39;app\&#39;
     * @param msgStatus the parameter can be used if \&#39;ds\&#39; is not \&#39;msg\&#39;
     * @param allowedAction the message\&#39;s allowed action
     * @param guaranteeFormat 
     * @param sort the element on which to sort ; defaults to metadataDeclarationDate
     * @param sortOrder the direction on which to sort ; defaults to desc
     * @param pageAfterSortId the id (of element used to sort) just before the requested page of result
     * @param pageBeforeSortId the id (of element used to sort) just after the requested page of result
     * @param pageSize 
     * @param part data part to be returned by the search method ; if null, returns the whole SearchedPredeclaration.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchEpds(ds: 'app' | 'msg', holderId?: string, guaranteeNumber?: string, id?: string, description?: string, lrn?: string, mrn?: string, submissionCountryCode?: string, from?: string, to?: string, appStatus?: string, msgStatus?: PredeclarationSentOverallStatus, allowedAction?: TransportOperationMessageExchangeActionType, guaranteeFormat?: TransitGuaranteeFormat, sort?: PredeclarationSort, sortOrder?: SortOrder, pageAfterSortId?: string, pageBeforeSortId?: string, pageSize?: number, part?: 'id', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<SearchedPredeclarations>;
    public searchEpds(ds: 'app' | 'msg', holderId?: string, guaranteeNumber?: string, id?: string, description?: string, lrn?: string, mrn?: string, submissionCountryCode?: string, from?: string, to?: string, appStatus?: string, msgStatus?: PredeclarationSentOverallStatus, allowedAction?: TransportOperationMessageExchangeActionType, guaranteeFormat?: TransitGuaranteeFormat, sort?: PredeclarationSort, sortOrder?: SortOrder, pageAfterSortId?: string, pageBeforeSortId?: string, pageSize?: number, part?: 'id', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<SearchedPredeclarations>>;
    public searchEpds(ds: 'app' | 'msg', holderId?: string, guaranteeNumber?: string, id?: string, description?: string, lrn?: string, mrn?: string, submissionCountryCode?: string, from?: string, to?: string, appStatus?: string, msgStatus?: PredeclarationSentOverallStatus, allowedAction?: TransportOperationMessageExchangeActionType, guaranteeFormat?: TransitGuaranteeFormat, sort?: PredeclarationSort, sortOrder?: SortOrder, pageAfterSortId?: string, pageBeforeSortId?: string, pageSize?: number, part?: 'id', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<SearchedPredeclarations>>;
    public searchEpds(ds: 'app' | 'msg', holderId?: string, guaranteeNumber?: string, id?: string, description?: string, lrn?: string, mrn?: string, submissionCountryCode?: string, from?: string, to?: string, appStatus?: string, msgStatus?: PredeclarationSentOverallStatus, allowedAction?: TransportOperationMessageExchangeActionType, guaranteeFormat?: TransitGuaranteeFormat, sort?: PredeclarationSort, sortOrder?: SortOrder, pageAfterSortId?: string, pageBeforeSortId?: string, pageSize?: number, part?: 'id', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (ds === null || ds === undefined) {
            throw new Error('Required parameter ds was null or undefined when calling searchEpds.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (ds !== undefined && ds !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>ds, 'ds');
        }
        if (holderId !== undefined && holderId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>holderId, 'holderId');
        }
        if (guaranteeNumber !== undefined && guaranteeNumber !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>guaranteeNumber, 'guaranteeNumber');
        }
        if (id !== undefined && id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>id, 'id');
        }
        if (description !== undefined && description !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>description, 'description');
        }
        if (lrn !== undefined && lrn !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lrn, 'lrn');
        }
        if (mrn !== undefined && mrn !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>mrn, 'mrn');
        }
        if (submissionCountryCode !== undefined && submissionCountryCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>submissionCountryCode, 'submissionCountryCode');
        }
        if (from !== undefined && from !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>from, 'from');
        }
        if (to !== undefined && to !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>to, 'to');
        }
        if (appStatus !== undefined && appStatus !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>appStatus, 'appStatus');
        }
        if (msgStatus !== undefined && msgStatus !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>msgStatus, 'msgStatus');
        }
        if (allowedAction !== undefined && allowedAction !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>allowedAction, 'allowedAction');
        }
        if (guaranteeFormat !== undefined && guaranteeFormat !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>guaranteeFormat, 'guaranteeFormat');
        }
        if (sort !== undefined && sort !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sort, 'sort');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }
        if (pageAfterSortId !== undefined && pageAfterSortId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageAfterSortId, 'pageAfterSortId');
        }
        if (pageBeforeSortId !== undefined && pageBeforeSortId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageBeforeSortId, 'pageBeforeSortId');
        }
        if (pageSize !== undefined && pageSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageSize, 'pageSize');
        }
        if (part !== undefined && part !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>part, 'part');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (read-epd) required
        localVarCredential = this.configuration.lookupCredential('read-epd');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/epds`;
        return this.httpClient.request<SearchedPredeclarations>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search transit guarantees according to criteria (without token consumption details)
     * @param legalFramework the legal framework
     * @param format the format
     * @param holderId the id of the holder of the guarantee ; if none, the organisation of the logged-in user
     * @param minTransitOperationCount the minimal count of transit operations to support
     * @param transitCountryCode the codes of the countries where the transit guarantee will be used
     * @param forEpdIndicator if the transit guarantee is to be used in a predeclaration
     * @param inEpdIndicator if the transit guarantee is already used in a predeclaration
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchGuarantees(legalFramework?: TransitGuaranteeLegalFramework, format?: TransitGuaranteeFormat, holderId?: string, minTransitOperationCount?: number, transitCountryCode?: Array<string>, forEpdIndicator?: boolean, inEpdIndicator?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<Array<TransitGuarantee>>;
    public searchGuarantees(legalFramework?: TransitGuaranteeLegalFramework, format?: TransitGuaranteeFormat, holderId?: string, minTransitOperationCount?: number, transitCountryCode?: Array<string>, forEpdIndicator?: boolean, inEpdIndicator?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<Array<TransitGuarantee>>>;
    public searchGuarantees(legalFramework?: TransitGuaranteeLegalFramework, format?: TransitGuaranteeFormat, holderId?: string, minTransitOperationCount?: number, transitCountryCode?: Array<string>, forEpdIndicator?: boolean, inEpdIndicator?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<Array<TransitGuarantee>>>;
    public searchGuarantees(legalFramework?: TransitGuaranteeLegalFramework, format?: TransitGuaranteeFormat, holderId?: string, minTransitOperationCount?: number, transitCountryCode?: Array<string>, forEpdIndicator?: boolean, inEpdIndicator?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (legalFramework !== undefined && legalFramework !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>legalFramework, 'legalFramework');
        }
        if (format !== undefined && format !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>format, 'format');
        }
        if (holderId !== undefined && holderId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>holderId, 'holderId');
        }
        if (minTransitOperationCount !== undefined && minTransitOperationCount !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>minTransitOperationCount, 'minTransitOperationCount');
        }
        if (transitCountryCode) {
            transitCountryCode.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'transitCountryCode');
            })
        }
        if (forEpdIndicator !== undefined && forEpdIndicator !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>forEpdIndicator, 'forEpdIndicator');
        }
        if (inEpdIndicator !== undefined && inEpdIndicator !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>inEpdIndicator, 'inEpdIndicator');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (read-epd) required
        localVarCredential = this.configuration.lookupCredential('read-epd');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/guarantees`;
        return this.httpClient.request<Array<TransitGuarantee>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List the uploaded attachments related to a pre-declaration
     * @param guid the identifier of the pre-declaration
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchPredeclarationAttachments(guid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<Array<AttachmentReference>>;
    public searchPredeclarationAttachments(guid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<Array<AttachmentReference>>>;
    public searchPredeclarationAttachments(guid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<Array<AttachmentReference>>>;
    public searchPredeclarationAttachments(guid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (guid === null || guid === undefined) {
            throw new Error('Required parameter guid was null or undefined when calling searchPredeclarationAttachments.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (read-epd) required
        localVarCredential = this.configuration.lookupCredential('read-epd');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/epds/${this.configuration.encodeParam({name: "guid", value: guid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/attachments`;
        return this.httpClient.request<Array<AttachmentReference>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a new message to the message exchange (usually a response message to a business message)
     * @param guid the identifier of the pre-declaration
     * @param opseq the transport operation sequence in the pre-declaration
     * @param mxId the identifier of the message exchange
     * @param predeclarationTransportOperationMessageSubmission the pre-declaration content
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public submitTOMxMessage(guid: string, opseq: number, mxId: string, predeclarationTransportOperationMessageSubmission: PredeclarationTransportOperationMessageSubmission, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<any>;
    public submitTOMxMessage(guid: string, opseq: number, mxId: string, predeclarationTransportOperationMessageSubmission: PredeclarationTransportOperationMessageSubmission, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<HttpResponse<any>>;
    public submitTOMxMessage(guid: string, opseq: number, mxId: string, predeclarationTransportOperationMessageSubmission: PredeclarationTransportOperationMessageSubmission, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<HttpEvent<any>>;
    public submitTOMxMessage(guid: string, opseq: number, mxId: string, predeclarationTransportOperationMessageSubmission: PredeclarationTransportOperationMessageSubmission, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined,}): Observable<any> {
        if (guid === null || guid === undefined) {
            throw new Error('Required parameter guid was null or undefined when calling submitTOMxMessage.');
        }
        if (opseq === null || opseq === undefined) {
            throw new Error('Required parameter opseq was null or undefined when calling submitTOMxMessage.');
        }
        if (mxId === null || mxId === undefined) {
            throw new Error('Required parameter mxId was null or undefined when calling submitTOMxMessage.');
        }
        if (predeclarationTransportOperationMessageSubmission === null || predeclarationTransportOperationMessageSubmission === undefined) {
            throw new Error('Required parameter predeclarationTransportOperationMessageSubmission was null or undefined when calling submitTOMxMessage.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (submit-epd) required
        localVarCredential = this.configuration.lookupCredential('submit-epd');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/epds/${this.configuration.encodeParam({name: "guid", value: guid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/transport/operations/${this.configuration.encodeParam({name: "opseq", value: opseq, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/message-exchanges/${this.configuration.encodeParam({name: "mxId", value: mxId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/messages`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: predeclarationTransportOperationMessageSubmission,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Save the pre-declaration
     * @param guid the identifier of the pre-declaration
     * @param step the step in which the pre-declaration is updated ; for last step, submit-epd is required too (unless bypassSubmit is true)
     * @param predeclaration the pre-declaration content
     * @param newPartsOnly validate and add only new (those without technical id) parts (consignment or consolidatedShipment)
     * @param consignmentId the identifier of the consignment (in the designated pre-declaration) to update
     * @param consolidatedShipmentId the identifier of the consolidated shipment (in the designated pre-declaration and consignment) to update
     * @param bypassValidation indicates that no validation should be performed
     * @param bypassSave indicates that no save should be performed.
     * @param bypassSubmit indicates that no submit of EPD should be performed
     * @param resend the list of messages to resend (encoded as type+.+operation reference; for instance epd.1, gda.2, etc.)
     * @param cancel the list of messages to cancel (encoded as type+.+operation reference; for instance epd.1, gda.2, etc.)
     * @param mxId The particular message exchanges to operate on ; all if not set
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateEpd(guid: string, step: number, predeclaration: Predeclaration, newPartsOnly?: boolean, consignmentId?: number, consolidatedShipmentId?: number, bypassValidation?: boolean, bypassSave?: boolean, bypassSubmit?: boolean, resend?: Array<string>, cancel?: Array<string>, mxId?: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<Array<PredeclarationNewPart>>;
    public updateEpd(guid: string, step: number, predeclaration: Predeclaration, newPartsOnly?: boolean, consignmentId?: number, consolidatedShipmentId?: number, bypassValidation?: boolean, bypassSave?: boolean, bypassSubmit?: boolean, resend?: Array<string>, cancel?: Array<string>, mxId?: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<Array<PredeclarationNewPart>>>;
    public updateEpd(guid: string, step: number, predeclaration: Predeclaration, newPartsOnly?: boolean, consignmentId?: number, consolidatedShipmentId?: number, bypassValidation?: boolean, bypassSave?: boolean, bypassSubmit?: boolean, resend?: Array<string>, cancel?: Array<string>, mxId?: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<Array<PredeclarationNewPart>>>;
    public updateEpd(guid: string, step: number, predeclaration: Predeclaration, newPartsOnly?: boolean, consignmentId?: number, consolidatedShipmentId?: number, bypassValidation?: boolean, bypassSave?: boolean, bypassSubmit?: boolean, resend?: Array<string>, cancel?: Array<string>, mxId?: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (guid === null || guid === undefined) {
            throw new Error('Required parameter guid was null or undefined when calling updateEpd.');
        }
        if (step === null || step === undefined) {
            throw new Error('Required parameter step was null or undefined when calling updateEpd.');
        }
        if (predeclaration === null || predeclaration === undefined) {
            throw new Error('Required parameter predeclaration was null or undefined when calling updateEpd.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (step !== undefined && step !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>step, 'step');
        }
        if (newPartsOnly !== undefined && newPartsOnly !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>newPartsOnly, 'new-parts-only');
        }
        if (consignmentId !== undefined && consignmentId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>consignmentId, 'consignmentId');
        }
        if (consolidatedShipmentId !== undefined && consolidatedShipmentId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>consolidatedShipmentId, 'consolidatedShipmentId');
        }
        if (bypassValidation !== undefined && bypassValidation !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>bypassValidation, 'bypassValidation');
        }
        if (bypassSave !== undefined && bypassSave !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>bypassSave, 'bypassSave');
        }
        if (bypassSubmit !== undefined && bypassSubmit !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>bypassSubmit, 'bypassSubmit');
        }
        if (resend) {
            resend.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'resend');
            })
        }
        if (cancel) {
            cancel.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'cancel');
            })
        }
        if (mxId) {
            mxId.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'mxId');
            })
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (update-epd) required
        localVarCredential = this.configuration.lookupCredential('update-epd');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/epds/${this.configuration.encodeParam({name: "guid", value: guid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<Array<PredeclarationNewPart>>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: predeclaration,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Upload a new attachment for this consignment
     * @param guid the identifier of the pre-declaration
     * @param uniqueConsignmentReference the unique consignment reference
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadConsignmentAttachment(guid: string, uniqueConsignmentReference: string, file?: Blob, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<any>;
    public uploadConsignmentAttachment(guid: string, uniqueConsignmentReference: string, file?: Blob, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<HttpResponse<any>>;
    public uploadConsignmentAttachment(guid: string, uniqueConsignmentReference: string, file?: Blob, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<HttpEvent<any>>;
    public uploadConsignmentAttachment(guid: string, uniqueConsignmentReference: string, file?: Blob, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined,}): Observable<any> {
        if (guid === null || guid === undefined) {
            throw new Error('Required parameter guid was null or undefined when calling uploadConsignmentAttachment.');
        }
        if (uniqueConsignmentReference === null || uniqueConsignmentReference === undefined) {
            throw new Error('Required parameter uniqueConsignmentReference was null or undefined when calling uploadConsignmentAttachment.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (update-epd) required
        localVarCredential = this.configuration.lookupCredential('update-epd');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any, filename?: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (file !== undefined) {
            localVarFormParams = localVarFormParams.append('file', <any>file, 'utf-8\'\'' + encodeURIComponent((<File>file).name)) as any || localVarFormParams;
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/epds/${this.configuration.encodeParam({name: "guid", value: guid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/consignments/${this.configuration.encodeParam({name: "uniqueConsignmentReference", value: uniqueConsignmentReference, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/attachments`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Upload a new attachment for this pre-declaration
     * @param guid the identifier of the pre-declaration
     * @param file 
     * @param shared 
     * @param documentType 
     * @param description 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadPredeclarationAttachment(guid: string, file?: Blob, shared?: boolean, documentType?: AttachmentDocumentType, description?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<any>;
    public uploadPredeclarationAttachment(guid: string, file?: Blob, shared?: boolean, documentType?: AttachmentDocumentType, description?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<HttpResponse<any>>;
    public uploadPredeclarationAttachment(guid: string, file?: Blob, shared?: boolean, documentType?: AttachmentDocumentType, description?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<HttpEvent<any>>;
    public uploadPredeclarationAttachment(guid: string, file?: Blob, shared?: boolean, documentType?: AttachmentDocumentType, description?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined,}): Observable<any> {
        if (guid === null || guid === undefined) {
            throw new Error('Required parameter guid was null or undefined when calling uploadPredeclarationAttachment.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (update-epd) required
        localVarCredential = this.configuration.lookupCredential('update-epd');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any, filename?: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (file !== undefined) {
            localVarFormParams = localVarFormParams.append('file', <any>file, 'utf-8\'\'' + encodeURIComponent((<File>file).name)) as any || localVarFormParams;
        }
        if (shared !== undefined) {
            localVarFormParams = localVarFormParams.append('shared', <any>shared) as any || localVarFormParams;
        }
        if (documentType !== undefined) {
            localVarFormParams = localVarFormParams.append('documentType', <any>documentType) as any || localVarFormParams;
        }
        if (description !== undefined) {
            localVarFormParams = localVarFormParams.append('description', <any>description) as any || localVarFormParams;
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/epds/${this.configuration.encodeParam({name: "guid", value: guid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/attachments`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
