/**
 * TIR-EPD REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tirepd@iru.org
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The national leg of a journey. Start to border, border to border, border to end, etc.
 */
export interface ItineraryStep { 
    reference: number;
    countryCode: string;
    /**
     * Indicates whether goods will be loaded during this step.
     */
    loadIndicator: boolean;
    /**
     * Indicates whether goods will be unloaded during this step.
     */
    unloadIndicator: boolean;
    /**
     * The transition of state of the TIR Transport during the itinerary step.
     */
    withTIRTransport?: ItineraryStep.WithTIRTransportEnum;
    /**
     * The transport mode
     */
    transportMode?: ItineraryStep.TransportModeEnum;
}
export namespace ItineraryStep {
    export type WithTIRTransportEnum = 'start' | 'suspend' | 'resume' | 'resume-end' | 'end';
    export const WithTIRTransportEnum = {
        Start: 'start' as WithTIRTransportEnum,
        Suspend: 'suspend' as WithTIRTransportEnum,
        Resume: 'resume' as WithTIRTransportEnum,
        ResumeEnd: 'resume-end' as WithTIRTransportEnum,
        End: 'end' as WithTIRTransportEnum
    };
    export type TransportModeEnum = 'road' | 'rail' | 'sea' | 'air';
    export const TransportModeEnum = {
        Road: 'road' as TransportModeEnum,
        Rail: 'rail' as TransportModeEnum,
        Sea: 'sea' as TransportModeEnum,
        Air: 'air' as TransportModeEnum
    };
}


