import { Injectable } from "@angular/core";
import { TokenExchangeResponse } from "@api/sts-application/index";
import { environment } from "@env/environment";
import { ReplaySubject } from "rxjs";
@Injectable({
    providedIn: 'root'
})
export class StsApplicationTokenCacheService {


    private _tokenCache: Map<string, ReplaySubject<TokenExchangeResponse>> = new Map();

    constructor() {


    }

    public clearTokenCache(): void {
        if (environment.debug) {
            console.log(`clear STS Application API token cache.`);
        }
        this._tokenCache.clear();
    }

    public get tokenCache(): Map<string, ReplaySubject<TokenExchangeResponse>> {
        return this._tokenCache;
    }

}