import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class URIService {
    constructor() { }

    public getAnchorValue(value: string, prefix: string): string {
        return this.substring(value, prefix, '#');
    }

    public queryParameters(value: string, prefix: string): Map<string, string> {
        const paramsString: string = this.substring(value, prefix, '?');
        const params: string[] = paramsString.split('&');
        const result: Map<string, string> = new Map<string, string>();
        params.forEach(param => {
            const eqIndex: number = param.indexOf('=');
            if (eqIndex > -1) {
                const k: string = param.substring(0, eqIndex);
                const v: string = param.substring(eqIndex + 1);
                result.set(k, v);
            } else {
                result.set(param, null);
            }
        });

        return result;
    }

    public substring(value: string, prefix: string, separator: string): string {
        if (value.startsWith(`${prefix}${separator}`)) {
            return value.substring(prefix.length + 1);
        }
        return null;
    }
}
