
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApplicationMarkdownExtension, ApplicationPopup, OrganisationService as ApiOrganisationService, UserApplicationSettings } from '@api/index';
import { UserContextService } from '@app/core';
import { MarkdownWebApplicationConfigurationService } from '@app/core/services/markdown-web-application-configuration.service';
import { MarkdownServiceProperties } from '@app/shared/models/markdown-service-properties';
import { MarkdownService } from '@app/shared/services/markdown.service';
import { OrganisationService } from '@app/shared/services/organisation.service';
import { TranslateService } from '@app/shared/services/translate.service';
import { forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';
import { DialogData, MarkdownDialogComponent, SaveStoreEvent } from './markdown-dialog.component';

@Component({
    selector: 'app-markdown',
    templateUrl: 'markdown.component.html'
})
export class MarkdownComponent implements OnInit {

    @Input()
    public markdownService: MarkdownService;

    @Input()
    public markdownServiceProperties: MarkdownServiceProperties;

    public localeCode: string;

    public banners: ApplicationMarkdownExtension[] = [];

    public linkedPopups: Map<string, ApplicationMarkdownExtension> = new Map();

    public popups: ApplicationMarkdownExtension[] = [];

    /*
    * Contains mandatory popups id and their status
    * false: not displayed yet
    * true: displayed
    */
    public mandatoryPopupsStatus: Map<string, boolean>;

    constructor(public dialog: MatDialog,
        private markdownWebApplicationConfigurationService: MarkdownWebApplicationConfigurationService,
        private translateService: TranslateService,
        private userContext: UserContextService,
        private organisationService: OrganisationService,
        private apiOrganisationService: ApiOrganisationService) {

    }

    ngOnInit() {
        this.localeCode = this.translateService.currentLang;

        forkJoin([
            this.markdownService.getMarkdowns(this.markdownServiceProperties, this.localeCode),
            this.markdownWebApplicationConfigurationService.linkTargetSelfUrlpattern.pipe(take(1))
        ])
            .subscribe(([res]) => {
                if (res != null) {
                    this.processMarkdowns(res);
                    if (this.markdownServiceProperties.automaticallyNotLinkedOpenPopup && this.popups.length > 0) {
                        this.openNotLinkedPopup();
                    }
                }
            },
                err => {
                    console.error(err);
                });
    }


    public refresh(newMarkdownServiceProperties: MarkdownServiceProperties): void {
        this.markdownServiceProperties = newMarkdownServiceProperties;
        this.ngOnInit();
    }

    openNotLinkedPopup(): Promise<void> {
        const promise: Promise<void> = new Promise<void>(resolve => {

            const dialogData: DialogData = {
                markdowns: this.popups,
                localeCode: this.localeCode
            };

            this.openPopupDialog(dialogData, resolve);
        });
        return promise;
    }

    openLinkedPopup(popupId: string): Promise<void> {
        const promise: Promise<void> = new Promise<void>(resolve => {

            const popupMarkdown = this.linkedPopups.get(popupId);
            if (popupMarkdown == null) {
                console.error(`Popup markdown ${popupId} does not exist.`);
                return;
            }

            if (this.mandatoryPopupsStatus && this.mandatoryPopupsStatus.get(popupId) != null) {
                this.mandatoryPopupsStatus.set(popupId, true);
            }

            const popupMarkdowns: ApplicationMarkdownExtension[] = [popupMarkdown];

            const dialogData: DialogData = {
                markdowns: popupMarkdowns,
                localeCode: this.localeCode
            };
            this.openPopupDialog(dialogData, resolve);
        });
        return promise;
    }

    private openPopupDialog(dialogData: DialogData, resolve: (value: PromiseLike<void>) => void): void {
        const dialogRef = this.dialog.open(MarkdownDialogComponent, {
            width: '600px',
            closeOnNavigation: false,
            disableClose: true,
            data: dialogData
        });
        dialogRef.afterClosed().subscribe(() => {
            resolve(null);
        });
        const sub = dialogRef.componentInstance.saveStoreEventEmitter.subscribe((event: SaveStoreEvent) => {
            this.processSaveStoreEvent(event)
        });
    }

    private processSaveStoreEvent(event: SaveStoreEvent) {
        switch (event.store) {
            case 'organisation':
                const popups: { [key: string]: ApplicationPopup } = {};
                popups[event.markdownId] = { version: event.value };

                this.organisationService.savePopups(popups);
                break;
            case 'user':
                const userApplicationSettings: UserApplicationSettings = {
                    popups: {}
                };
                userApplicationSettings.popups[event.markdownId] = { version: event.value };
                this.userContext.patchAppSettings(userApplicationSettings);
                break;
        }
    }

    processMarkdowns(markdowns: ApplicationMarkdownExtension[]): void {
        this.banners = markdowns.filter(markdown => markdown.type === 'banner');

        markdowns.filter(markdown => markdown.type === 'popup')
            .forEach(markdown => {
                if (this.banners.filter(banner => banner.popup === markdown.id).length > 0) {
                    this.linkedPopups.set(markdown.id, markdown);
                } else {
                    this.popups.push(markdown);
                }

                if (markdown.mandatoryPopupIndicator) {
                    if (this.mandatoryPopupsStatus == null) {
                        this.mandatoryPopupsStatus = new Map();
                    }
                    this.mandatoryPopupsStatus.set(markdown.id, false);
                }
            });
    }

    forceDisplayRemainingMandatoryPopups(): Promise<void> {
        const promise: Promise<void> = new Promise<void>(async resolve => {
            if (this.mandatoryPopupsStatus == null) {
                resolve(null);
            } else {
                for (const [key, value] of this.mandatoryPopupsStatus) {
                    if (!value) {
                        await this.openLinkedPopup(key);
                    }
                }

                resolve(null);
            }
        });
        return promise;
    }
}
