<mat-toolbar class="gradient" *ngIf="features != null">
  <div *ngIf="isMenuButtonDisplayed('home')" class="container" routerLinkActive="active-link">
    <button type="button" [routerLink]="['/home']"
      mat-button><span>{{ 'menu.home' | trackAndTranslate }}</span></button>
  </div>


  <div *ngIf="isMenuButtonDisplayed('order')" class="container" routerLinkActive="active-link">
    <button type="button" mat-button [matMenuTriggerFor]="menuOrder"><span>{{ 'menu.order' | trackAndTranslate
        }}</span></button>
    <mat-menu #menuOrder="matMenu">
      <button type="button" [routerLink]="['/order/orders/new']" mat-menu-item>{{ 'menu.order.new' | trackAndTranslate
        }}</button>
      <button type="button" [routerLink]="['/order/orders']" mat-menu-item>{{ 'menu.order.list.orders' | trackAndTranslate
        }}</button>
    </mat-menu>
  </div>

  <div *ngIf="isMenuButtonDisplayed('epd')" class="container" routerLinkActive="active-link">
    <span [routerLink]="['/epd']"></span> <!-- for parent route for routerLinkActive -->
    <button type="button" mat-button
      [matMenuTriggerFor]="menuEpd"><span>{{ 'menu.epds' | trackAndTranslate }}</span></button>
    <mat-menu #menuEpd="matMenu">
      <button type="button" [routerLink]="['/epd/new']" *ngIf="isMenuItemDisplayed('epd.create')" mat-menu-item>{{
        'menu.new.epd' | trackAndTranslate }}</button>
      <button type="button" [routerLink]="['/epd/list/draft']" *ngIf="isMenuItemDisplayed('epd.list')" mat-menu-item>{{
        'menu.epds.search-draft' | trackAndTranslate }}</button>
      <button type="button" [routerLink]="['/epd/list/submitted']" *ngIf="isMenuItemDisplayed('epd.list')"
        mat-menu-item>{{ 'menu.epds.search-submitted' | trackAndTranslate }}</button>
      <button type="button" [routerLink]="['/epd/preferences']" *ngIf="isMenuItemDisplayed('epd.preference')"
        mat-menu-item>{{ 'menu.epds.preferences' | trackAndTranslate }}</button>
      <button type="button" *ngIf="isMenuItemDisplayed('epd.broker.support')" [routerLink]="['/epd/support']"
        mat-menu-item>{{ 'menu.epds.epd.support' | trackAndTranslate }}</button>
    </mat-menu>
  </div>


  <div *ngIf="isMenuButtonDisplayed('companyinfo')" class="container" routerLinkActive="active-link">
    <button type="button" mat-button [matMenuTriggerFor]="menuCompanyInfo"><span>{{ 'menu.companyinfo' | trackAndTranslate
        }}</span></button>
    <mat-menu #menuCompanyInfo="matMenu">
      <button type="button"
        [routerLink]="['/company/organisations/details/' + userContextService.user.tenant.split('/').join('-')]"
        *ngIf="isMenuItemDisplayed('companyinfo.generalinfo')" mat-menu-item>{{ 'menu.companyinfo.general' | trackAndTranslate
        }}</button>
      <button type="button" *ngIf="isMenuItemDisplayed('companyinfo.search.business-profile')"
        [routerLink]="['/company/profiles']"
        mat-menu-item>{{ 'menu.companyinfo.search-profiles' | trackAndTranslate }}</button>
      <button type="button" [routerLink]="['/company/trade-partners']"
        *ngIf="isMenuItemDisplayed('companyinfo.consignor-consignee')" mat-menu-item>{{ 'menu.companyinfo.traders' |
        trackAndTranslate }}</button>
      <button type="button" [routerLink]="['/company/vehicles']" *ngIf="isMenuItemDisplayed('companyinfo.vehicle')"
        mat-menu-item>{{ 'menu.companyinfo.vehicles' | trackAndTranslate }}</button>
      <button type="button" [routerLink]="['/company/drivers']" *ngIf="isMenuItemDisplayed('companyinfo.driver')"
        mat-menu-item>{{ 'menu.companyinfo.drivers' | trackAndTranslate }}</button>
    </mat-menu>
  </div>


  <div class="container" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: true }"
    *ngIf="isMenuButtonDisplayed('manage.organisations') && orgTypes && orgTypes.length > 0">
    <button type="button" mat-button [matMenuTriggerFor]="orgTypes && orgTypes.length > 1 ? menuOrganisation : undefined"
      *ngIf="isMenuItemDisplayed('manage.organisations')"
      [routerLink]="orgTypes && orgTypes.length === 1 ? ['/company/organisations/' +  orgTypes[0] ] : undefined"><span>{{ menuOrganisationLabel }}</span></button>
    <mat-menu #menuOrganisation="matMenu">
      <button *ngFor="let orgType of orgTypes" type="button" [routerLink]="['/company/organisations/' +  orgType ]"
        mat-menu-item>{{ 'organisation.business-organisation-type.' + orgType | trackAndTranslate }}</button>
    </mat-menu>
  </div>


  <div *ngIf="isMenuButtonDisplayed('t')" class="container" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: false }">
    <button type="button" mat-button [matMenuTriggerFor]="menuOrganisation"><span>{{ 'menu.t' | trackAndTranslate}}</span></button>
    <mat-menu #menuOrganisation="matMenu">
      <button type="button" [routerLink]="['/t/movements/details']" *ngIf="isMenuItemDisplayed('t.movement.declare')"
        mat-menu-item>{{ 'menu.t.movement.declare' | trackAndTranslate }}</button>
      <button type="button" [routerLink]="['/t/movements']" *ngIf="isMenuItemDisplayed('t.movement.list')"
        mat-menu-item>{{ 'menu.t.movement.list' | trackAndTranslate }}</button>
      <button type="button" style="display: none;" [routerLink]="['/t/transport/operator']" mat-menu-item>{{ 'menu.t.transport.operator' | trackAndTranslate }}</button>
    </mat-menu>
  </div>

  <span class="spacer"></span>

  <button mat-icon-button color="primary" [matMenuTriggerFor]="menu">
    <mat-icon>help_outline</mat-icon>
  </button>

  <mat-menu #menu="matMenu">
    <a href="{{ userGuideUrl }}" target="_blank" *ngIf="userGuideUrl">
      <button mat-menu-item>
        {{'menu.help.userguide' | trackAndTranslate}}
        <mat-icon style="transform: scale(0.65);">open_in_new</mat-icon>
      </button>
    </a>

    <a href="{{ howToFillEpdUrl }}" target="_blank" *ngIf="howToFillEpdUrl">
      <button mat-menu-item>
        {{'menu.help.how.to.fill.epd' | trackAndTranslate}}
        <mat-icon style="transform: scale(0.65);">open_in_new</mat-icon>
      </button>
    </a>

    <a href="{{ faqUrl }}" target="_blank" *ngIf="faqUrl">
      <button mat-menu-item>
        {{'menu.help.faq' | trackAndTranslate}}
        <mat-icon style="transform: scale(0.65);">open_in_new</mat-icon>
      </button>
    </a>

    <a href="{{ contactUsUrl }}" target="_blank" rel="noopener" *ngIf="contactUsUrl">
      <button mat-menu-item>
        {{'menu.help.contactus' | trackAndTranslate}}
      </button>

    </a>
  </mat-menu>

</mat-toolbar>

<div>

</div>