import { Component, OnInit } from '@angular/core';
import { ApplicationFeatures, BusinessProfileType } from '@api/index';
import { UserContextService } from '@app/core/services/user-context.service';
import { BusinessOrganisationType } from '@app/modules/company/models/business-organisation-type-enum';
import { Feature } from '@app/shared/services/feature.service';
import { OrganisationService as OrganisationSharedService } from "@app/shared/services/organisation.service";
import { TranslateService } from '@app/shared/services/translate.service';
import { WebApplicationConfigurationService } from '@app/shared/services/web-application-configuration.service';
import { LangChangeEvent } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { first } from "rxjs/operators";
import { NavigationService } from '../../shared/services/navigation.service';

@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

    public features: ApplicationFeatures;

    public userGuideUrl: string;
    public howToFillEpdUrl: string;
    public faqUrl: string;
    public contactUsUrl: string;

    public menuOrganisationLabel: string;
    public broker: boolean;

    public orgTypes: BusinessOrganisationType.TypeEnum[] = [];

    constructor(
        private navigationService: NavigationService,
        public userContextService: UserContextService,
        private translateService: TranslateService,
        private webApplicationConfigurationService: WebApplicationConfigurationService,
        private organisationSharedService: OrganisationSharedService) {

    }

    ngOnInit() {
        this.features = this.navigationService.getApplicationFeaturesList;
        this.menuOrganisationLabel = "";

        forkJoin([
            this.organisationSharedService.getCurrentUserOrganisation().pipe(first()),
            this.webApplicationConfigurationService.getPropertiesValueByPrefix('user-guide.url'),
            this.webApplicationConfigurationService.getPropertiesValueByPrefix('how-to-fill-epd.url'),
            this.webApplicationConfigurationService.getPropertiesValueByPrefix('faq.url'),
            this.webApplicationConfigurationService.getPropertiesValueByPrefix('contact-us.url')
        ])
            .subscribe(([organisation, userGuideUrls, howToFillEpdUrls, faqUrls, contactUsUrls]) => {
                this.menuOrganisationLabel = this.translateService.instant('menu.organisations.default');
                this.setLinks(userGuideUrls, howToFillEpdUrls, faqUrls, contactUsUrls, this.translateService.currentLang);
                const businessProfileType = this.organisationSharedService.getBusinessProfileType(organisation);
                this.broker = businessProfileType === BusinessProfileType.Broker;
                const key = `menu.organisations.${businessProfileType}`;
                const specificMenuForCurrentOrganisation = this.translateService.instant(key);
                if (specificMenuForCurrentOrganisation !== key) {
                    this.menuOrganisationLabel = specificMenuForCurrentOrganisation;
                }

                this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
                    this.setLinks(userGuideUrls, howToFillEpdUrls, faqUrls, contactUsUrls, event.lang);
                });

                if (this.features != null && true === this.features[Feature.FeatureEnum.BusinessOrganisationTypesShowAll]) {
                    this.orgTypes = Object.values(BusinessOrganisationType.TypeEnum);
                } else {
                    const accessActionTypeMap = this.organisationSharedService.getAccessActionMapForOrganisation(organisation);
                    if (accessActionTypeMap) {
                        this.orgTypes.push(...accessActionTypeMap.keys());
                    }
                }
            });

    }

    private setLinks(userGuideUrls: Map<string, string>, howToFillEpdUrls: Map<string, string>, faqUrls: Map<string, string>, contactUsUrls: Map<string, string>, lang: string): void {
        if (userGuideUrls.has(`user-guide.url.${lang}`)) {
            this.userGuideUrl = userGuideUrls.get(`user-guide.url.${lang}`);
        } else {
            this.userGuideUrl = userGuideUrls.get('user-guide.url');
        }

        if (howToFillEpdUrls.has(`how-to-fill-epd.url.${lang}`)) {
            this.howToFillEpdUrl = howToFillEpdUrls.get(`how-to-fill-epd.url.${lang}`);
        } else {
            this.howToFillEpdUrl = howToFillEpdUrls.get('how-to-fill-epd.url');
        }

        if (faqUrls.has(`faq.url.${lang}`)) {
            this.faqUrl = faqUrls.get(`faq.url.${lang}`);
        } else {
            this.faqUrl = faqUrls.get('faq.url');
        }

        if (contactUsUrls.has(`contact-us.url.${lang}`)) {
            this.contactUsUrl = contactUsUrls.get(`contact-us.url.${lang}`);
        } else {
            this.contactUsUrl = contactUsUrls.get('contact-us.url');
        }
    }

    isMenuItemDisplayed(itemName: string): boolean {
        return this.navigationService.isMenuItemDisplayed(itemName);
    }

    isMenuButtonDisplayed(itemName: string): boolean {
        return this.navigationService.isMenuButtonDisplayed(itemName);
    }

    onMenuItemClick(featureName: string, ...args: string[]) {
        console.error('unexpected call to onMenuItemClick ' + featureName);
    }
}
