import { Injectable } from '@angular/core';
import { WebApplicationConfigurationService } from '@app/shared/services/web-application-configuration.service';
import { ReplaySubject } from 'rxjs';

/*
* This service is design to synchronize the MarkdownRenderer with the async call to get getPropertyValue()
* PLEASE STICK TO THIS PURPOSE
*/
@Injectable({ providedIn: 'root' })
export class MarkdownWebApplicationConfigurationService {

    private _linkTargetSelfUrlpattern: ReplaySubject<string> = new ReplaySubject<string>(1);

    constructor(private webApplicationConfigurationService: WebApplicationConfigurationService) {
        this.webApplicationConfigurationService.getPropertyValue('ui.markdown.link.target.self.urlpattern')
            .then(value => {
                if (value) {
                    this._linkTargetSelfUrlpattern.next(value);
                }
            });
    }

    public get linkTargetSelfUrlpattern(): ReplaySubject<string> {
        return this._linkTargetSelfUrlpattern;
    }

}
