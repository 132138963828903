import { Direction } from '@angular/cdk/bidi';
import { Component } from '@angular/core';
import { AuthAuthService } from '@app/core/services/auth-auth.service';
import { TranslateService } from '@app/shared/services/translate.service';
import { LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-public-layout',
  templateUrl: './public-layout.component.html',
  styleUrls: ['./public-layout.component.scss']
})
export class PublicLayoutComponent {

  public rtlLanguages: string[] = ['ar', 'fa', 'ur', 'he', 'iw', 'yi', 'dv'];

  public textDir: Direction;

  constructor(
    private translateService: TranslateService,
    private authService: AuthAuthService) {

    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      if (this.rtlLanguages.includes(event.lang)) {
        this.textDir = 'rtl' as Direction;
      } else {
        this.textDir = 'ltr' as Direction;
      }
    });
  }

}
