import { Direction } from '@angular/cdk/bidi';
import { OverlayRef } from '@angular/cdk/overlay';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BusinessProfileType, Organisation } from '@api/index';
import { LoginMarkdownServiceProperties } from '@app/core/models/login-markdown-service-properties';
import { LoginMarkdownService } from '@app/core/services/login-markdown.service';
import { UserContextService } from '@app/core/services/user-context.service';
import { Feature, FeatureService } from '@app/shared/services/feature.service';
import { LoadingOverlayService } from '@app/shared/services/loading-overlay.service';
import { OrganisationService as CachedOrganisationService } from '@app/shared/services/organisation.service';
import { TranslateService } from '@app/shared/services/translate.service';
import { LangChangeEvent } from '@ngx-translate/core';
import * as rename from '../../../../target/tir-app-design-rename.json';

@Component({
  selector: 'app-content-layout',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.scss']
})
export class ContentLayoutComponent implements OnInit, OnDestroy {

  public rtlLanguages: string[] = ['ar', 'fa', 'ur', 'he', 'iw', 'yi', 'dv'];

  public textDir: Direction;

  public displayProfileFillingTip = false;

  public initialized = false;

  private loader: OverlayRef;

  public loginMarkdownServiceProperties: LoginMarkdownServiceProperties;

  constructor(public dialog: MatDialog,
    public userContextService: UserContextService,
    private translateService: TranslateService,
    private featureService: FeatureService,
    private cachedOrganisationService: CachedOrganisationService,
    private loadingOverlayService: LoadingOverlayService,
    public loginMarkdownService: LoginMarkdownService) {
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      if (this.rtlLanguages.includes(event.lang)) {
        this.textDir = 'rtl' as Direction;
      } else {
        this.textDir = 'ltr' as Direction;
      }
    });
  }
  ngOnDestroy(): void {
    if (this.loader) {
      this.loader.detach();
    }
  }

  ngOnInit() {
    const loader = this.loadingOverlayService.open();
    this.userContextService.organisation
      .subscribe(organisation => {
        if (organisation) {
          this.featureService.isFeatureEnabled(Feature.FeatureEnum.BusinessProfileEnabled)
            .then(enabled => {
              const businessProfileEnabled = enabled &&
                (Organisation.TypeEnum.Haulier === this.userContextService.tenant.type
                  || Organisation.TypeEnum.ThirdParty === this.userContextService.tenant.type);
              if (businessProfileEnabled && this.userContextService.hasPermission('manage-company-detail') &&
                this.cachedOrganisationService.getBusinessProfileType(organisation) != null &&
                (organisation.businessProfile == null
                  || !organisation.businessProfile.publicationConsentIndicator)
              ) {
                this.displayProfileFillingTip = true;
              } else {
                this.displayProfileFillingTip = false;
              }

              this.initialized = true;
              loader.dispose();
            });
        } else {
          this.initialized = true;
          loader.dispose();
        }

      });

    this.translateService.onLangChange.subscribe((params: LangChangeEvent) => {
      this.loginMarkdownServiceProperties = new LoginMarkdownServiceProperties();
    });



  }

  get associationLogoPath(): string {
    let logo = '';
    if (Organisation.TypeEnum.Haulier === this.userContextService.tenant.type
      || Organisation.TypeEnum.Association === this.userContextService.tenant.type) {
      logo = rename['default'][`images/logos/associations/medium/${this.userContextService.tenant.associationId}.png`];
    } else if (Organisation.TypeEnum.Iru === this.userContextService.tenant.type) {
      logo = rename['default'][`images/logos/associations/medium/1.png`];
    } else if (Organisation.TypeEnum.ThirdParty === this.userContextService.tenant.type && this.userContextService.businessProfileType === BusinessProfileType.TransportOperator) {
      logo = `images/transportOperator.png`;
    } else if (Organisation.TypeEnum.ThirdParty === this.userContextService.tenant.type) {
      logo = `images/third-party.png`;
    }
    return logo;
  }

}
