import { Injectable } from "@angular/core";
import { ApplicationGroupService, ApplicationService, AuthorizedOperationService, UsernameGroupService, UsernameService } from "@api/sts-application/index";
import { ScopeService } from "@api/sts/index";
import { WebApplicationConfigurationService } from "@app/shared/services/web-application-configuration.service";
import { forkJoin, ReplaySubject } from "rxjs";
import { take } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class ExternalApiModuleWrapper {

    public isStsInitialized: ReplaySubject<boolean> = new ReplaySubject();
    public isStsApplicationInitialized: ReplaySubject<boolean> = new ReplaySubject();

    constructor(private webApplicationConfigurationService: WebApplicationConfigurationService,
        private usernameService: UsernameService,
        private applicationService: ApplicationService,
        private usernameGroupService: UsernameGroupService,
        private applicationGroupService: ApplicationGroupService,
        private authorizedOperationService: AuthorizedOperationService,
        private scopeService: ScopeService) {

        forkJoin([this.webApplicationConfigurationService.getPublicPropertyValue('public.authorization-config.baseUrl', null)])
            .subscribe(([stsBaseUrl]) => {
                this.scopeService.configuration.basePath = stsBaseUrl;

                this.isStsInitialized.next(true);
            });
    }

    public waitStsInitialized(): Promise<void> {
        return new Promise<void>(resolve => {
            this.isStsInitialized.pipe(take(1)).subscribe(val => resolve(null));
        });
    }

    public waitStsApplicationInitialized(): Promise<void> {
        return new Promise<void>(resolve => {
            this.isStsApplicationInitialized.pipe(take(1)).subscribe(val => resolve(null));

            this.webApplicationConfigurationService.getPublicPropertyValue('public.sts-application.authorization-config.baseUrl', null)
                .then(baseUrl => {
                    this.usernameService.configuration.basePath = baseUrl;
                    this.usernameGroupService.configuration.basePath = baseUrl;
                    this.applicationGroupService.configuration.basePath = baseUrl;
                    this.authorizedOperationService.configuration.basePath = baseUrl;
                    this.isStsApplicationInitialized.next(true);
                });
        });
    }

    public stsScopeService(): ScopeService {
        return this.scopeService;
    }


    public stsApplicationUsernameService(): UsernameService {
        return this.usernameService;
    }


    public stsApplicationApplicationService(): ApplicationService {
        return this.applicationService;
    }


    public stsApplicationUsernameGroupService(): UsernameGroupService {
        return this.usernameGroupService;
    }


    public stsApplicationApplicationGroupService(): ApplicationGroupService {
        return this.applicationGroupService;
    }

    public stsApplicationAuthorizedOperationService(): AuthorizedOperationService {
        return this.authorizedOperationService;
    }



}