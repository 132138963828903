import { OverlayRef } from '@angular/cdk/overlay';
import { Component, EventEmitter, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApplicationMarkdownExtension } from '@api/index';
import { AuthAuthService } from '@app/core/services/auth-auth.service';
import { LoadingOverlayService } from '@app/shared/services/loading-overlay.service';

export interface DialogData {
    markdowns: ApplicationMarkdownExtension[];
    localeCode: string;
}

export interface SaveStoreEvent {
    markdownId: string,
    store: 'user' | 'organisation',
    value: string
}

@Component({
    selector: 'app-markdown-dialog',
    templateUrl: 'markdown-dialog.component.html',
})
export class MarkdownDialogComponent implements OnDestroy {

    public displayMarkdownIndex = 0;

    private loader: OverlayRef;

    public saveStoreEventEmitter: EventEmitter<SaveStoreEvent> = new EventEmitter();

    constructor(
        private authService: AuthAuthService,
        private loadingOverlayService: LoadingOverlayService,
        public dialogRef: MatDialogRef<MarkdownDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

    ngOnDestroy(): void {
        if (this.loader) {
            this.loader.detach();
        }
    }

    onClose(): void {
        const markdown = this.data.markdowns[this.displayMarkdownIndex];

        if (markdown.store && markdown.values.close) {
            this.saveStoreEventEmitter.emit({ markdownId: markdown.id, store: markdown.store, value: markdown.values.close });
        }


        if (this.displayMarkdownIndex === this.data.markdowns.length - 1) {
            this.dialogRef.close();
        } else {
            this.displayMarkdownIndex++;
        }

    }

    onLogout(): void {
        this.loader = this.loadingOverlayService.open();
        this.authService.logout();
    }

}
