import { Injectable } from '@angular/core';
import { WebApplicationConfiguration, WebApplicationConfigurationExternalURL } from '@api/index';
import { ReplaySubject } from 'rxjs';
import { DataReferenceService } from './data-reference.service';
import { TranslateService } from './translate.service';


export enum Source {
    PUBLIC = 'public',
    ALL = 'all'
}

@Injectable({ providedIn: 'root' })
export class WebApplicationConfigurationService {
    constructor(
        private dataReferenceService: DataReferenceService,
        private translateService: TranslateService
    ) { }



    public generateURL(key: string, parameters: Map<String, String>): Promise<string> {
        const promise = new Promise<string>(resolve => {
            this.dataReferenceService.getWebApplicationConfiguration().subscribe(configuration => {
                if (configuration != null && configuration.externalUrls != null
                    && configuration.externalUrls[key] != null) {


                    if (parameters == null) {
                        parameters = new Map();
                    }
                    parameters.set('languageCode', this.translateService.currentLang.substr(0, 2));

                    const webApplicationConfigurationExternalURL: WebApplicationConfigurationExternalURL
                        = configuration.externalUrls[key];


                    const separator = webApplicationConfigurationExternalURL.mandatoryQueryParameterIndicator ?
                        '&' : '?';

                    const queryParams: string[] = [];
                    if (webApplicationConfigurationExternalURL.optionalParameters != null) {
                        for (const parameterName in webApplicationConfigurationExternalURL.optionalParameters) {
                            if (webApplicationConfigurationExternalURL.optionalParameters.hasOwnProperty(parameterName)) {
                                const parameterValueTemplate = webApplicationConfigurationExternalURL.optionalParameters[parameterName];
                                const parameterValue = this.replace(parameterValueTemplate, parameters,
                                    webApplicationConfigurationExternalURL.supportedLanguageCodes);
                                if (parameterValue) {
                                    queryParams.push(`${parameterName}=${parameterValue}`);
                                }
                            }
                        }
                    }

                    resolve(`${webApplicationConfigurationExternalURL.template}${separator}${queryParams.join('&')}`);
                }
                resolve(null);
            });
        });

        return promise;
    }

    public getPropertyValue(propertyName: string, propertyNotFoundValue?: string): Promise<string> {
        return this.getPropertyValueBySource(Source.ALL, propertyName, propertyNotFoundValue);
    }


    public getPropertiesValue(...propertyNames: string[]): Promise<Map<string, string>> {
        return this.getPropertiesValueBySource(Source.ALL, propertyNames);
    }

    public getPropertiesValueByPrefix(propertyNamePrefix: string): Promise<Map<string, string>> {
        return this.getPropertiesValueByPrefixBySource(Source.ALL, propertyNamePrefix);
    }


    public getPublicPropertyValue(propertyName: string, propertyNotFoundValue?: string): Promise<string> {
        return this.getPropertyValueBySource(Source.PUBLIC, propertyName, propertyNotFoundValue);
    }


    public getPublicPropertiesValue(...propertyNames: string[]): Promise<Map<string, string>> {
        return this.getPropertiesValueBySource(Source.PUBLIC, propertyNames);
    }

    public getPublicPropertiesValueByPrefix(propertyNamePrefix: string): Promise<Map<string, string>> {
        return this.getPropertiesValueByPrefixBySource(Source.PUBLIC, propertyNamePrefix);
    }

    private getReplaySubject(source: Source) {
        let replaySubject: ReplaySubject<WebApplicationConfiguration>;
        switch (source) {
            case Source.ALL:
                replaySubject = this.dataReferenceService.getWebApplicationConfiguration();
                break;
            case Source.PUBLIC:
                replaySubject = this.dataReferenceService.getWebApplicationPublicConfiguration();
                break;
        }
        return replaySubject
    }

    private getPropertyValueBySource(source: Source, propertyName: string, propertyNotFoundValue?: string): Promise<string> {
        const promise = new Promise<string>(resolve => {

            this.getReplaySubject(source).subscribe(configuration => {
                if (configuration != null && configuration.additionalItems[propertyName]) {
                    resolve(configuration.additionalItems[propertyName]);
                } else if (propertyNotFoundValue) {
                    resolve(propertyNotFoundValue);
                } else {
                    resolve(null);
                }
            });
        });
        return promise;
    }



    private getPropertiesValueBySource(source: Source, propertyNames: string[]): Promise<Map<string, string>> {
        const publicProperyMap: Map<string, string> = new Map<string, string>();
        const promise = new Promise<Map<string, string>>(resolve => {
            this.getReplaySubject(source).subscribe(configuration => {
                for (const property in configuration.additionalItems) {
                    if (propertyNames.includes(property)) {
                        publicProperyMap.set(property, configuration.additionalItems[property]);
                    };
                }
                if (publicProperyMap.size > 0) {
                    resolve(publicProperyMap);
                } else {
                    resolve(null);
                }
            });
        });
        return promise;
    }

    private getPropertiesValueByPrefixBySource(source: Source, propertyNamePrefix: string): Promise<Map<string, string>> {
        const publicProperyMap: Map<string, string> = new Map<string, string>();
        const promise = new Promise<Map<string, string>>(resolve => {
            this.getReplaySubject(source).subscribe(configuration => {
                if (configuration == null) {
                    resolve(null);
                } else {
                    for (const property in configuration.additionalItems) {
                        if (property.startsWith(propertyNamePrefix)) {
                            publicProperyMap.set(property, configuration.additionalItems[property]);
                        };
                    }
                    if (publicProperyMap.size > 0) {
                        resolve(publicProperyMap);
                    } else {
                        resolve(null);
                    }
                }
            });
        });
        return promise;
    }

    private replace(template: string, parameters: Map<String, String>, supportedLanguages: string[]): string {
        parameters.forEach((value: string, key: string) => {

            if ('languageCode' === key) {
                if (supportedLanguages != null && !supportedLanguages.includes(value)) {
                    value = 'en';
                }
            }

            template = template.replace(`{${key}}`, value);
        });

        return template;
    }
}
