import { MarkdownServiceProperties } from '@app/shared/models/markdown-service-properties';

export class LoginMarkdownServiceProperties extends MarkdownServiceProperties {

    constructor() {
        super();
        this.automaticallyNotLinkedOpenPopup = true;
    }

}
