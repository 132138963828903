<footer fxLayout="row" fxLayoutAlign=" center">
  <div fxFlex="20">
      <span>&copy; 2012-{{currentYear}} IRU</span>
  </div>
  <div fxFlex="20">
    <a href="{{termsAndConditionsUrl}}" target="_blank">
      <span>{{ 'legal.notice' | trackAndTranslate }}</span>
    </a>
  </div>
  <div fxFlex="50" (click)="logVersion()">
    {{ 'application.title' | trackAndTranslate }} v{{version}} {{buildDate}}
  </div>
  <div fxFlex="10">
    <a href="http://www.iru.org/" target="_blank">
      <img class="IRULogo" alt="" src="assets/images/img_transparent.png" />
    </a>
  </div>
</footer>
