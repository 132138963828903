/**
 * TIR-EPD REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tirepd@iru.org
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Type of road transport vehicle.
 */
export type VehicleType = 'truck' | 'autobus' | 'semitrailer' | 'trailer' | 'minibus';

export const VehicleType = {
    Truck: 'truck' as VehicleType,
    Autobus: 'autobus' as VehicleType,
    Semitrailer: 'semitrailer' as VehicleType,
    Trailer: 'trailer' as VehicleType,
    Minibus: 'minibus' as VehicleType
};

