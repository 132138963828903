/**
 * TIR-EPD REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tirepd@iru.org
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type VehicleSort = 'registrationNumber' | 'chassisNumber' | 'type' | 'make' | 'model';

export const VehicleSort = {
    RegistrationNumber: 'registrationNumber' as VehicleSort,
    ChassisNumber: 'chassisNumber' as VehicleSort,
    Type: 'type' as VehicleSort,
    Make: 'make' as VehicleSort,
    Model: 'model' as VehicleSort
};

