import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AttachedDocumentManagementComponent } from '@app/modules/epd/shared/components/attached-document/attached-document-management.component';
import { AttachedDocumentVehicleManagementComponent } from '@app/modules/epd/shared/components/attached-document/attached-document-vehicle-management.component';
import { AttachedDocumentComponent } from '@app/modules/epd/shared/components/attached-document/attached-document.component';
import { RSSAlertsComponent } from '@app/shared/components/rss-alerts/rss-alerts.component';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { ArchwizardModule } from 'angular-archwizard';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgxFilesizeModule } from 'ngx-filesize';
import { MarkdownModule } from 'ngx-markdown';
import { BusinessProfileComponent as ViewBusinessProfileComponent } from './components/business-profile/business-profile.component';
import { ConfirmationDialogComponent } from './components/dialogs/confirmation/confirmation-dialog.component';
import { ErrorDialogComponent } from './components/dialogs/error/error-dialog.component';
import { EpdCardComponent } from './components/epd-card/epd-card.component';
import { EpdMultiselectComponent } from './components/epd-multiselect/epd-multiselect.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { LoadingOverlayComponent } from './components/loading-overlay.component';
import { MarkdownDialogComponent } from './components/markdown/markdown-dialog.component';
import { MarkdownComponent } from './components/markdown/markdown.component';
import { MaxRangeDirective } from './directives/max-range.directive';
import { MaterialModule } from './material.module';
import { DataTranslationKeyPipe } from './pipes/data-translation-key';
import { EllipsisPipe } from './pipes/ellipsis.pipe';
import { LocalizedDatePipe } from './pipes/localized-date.pipe';
import { LocalizedDatetimePipe } from './pipes/localized-datetime.pipe';
import { TrackAndTranslatePipe } from './pipes/track-and-translate';


@NgModule({
    declarations: [LoadingOverlayComponent,
        EpdCardComponent,
        EpdMultiselectComponent,
        ConfirmationDialogComponent,
        ErrorDialogComponent,
        FileUploadComponent,
        MarkdownComponent,
        MarkdownDialogComponent,
        LocalizedDatePipe,
        LocalizedDatetimePipe,
        EllipsisPipe,
        ViewBusinessProfileComponent,
        RSSAlertsComponent,
        MaxRangeDirective,
        DataTranslationKeyPipe,
        TrackAndTranslatePipe,
        AttachedDocumentVehicleManagementComponent,
        AttachedDocumentComponent
    ],
    providers: [
        CurrencyPipe,
        TranslatePipe
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        ArchwizardModule,
        TranslateModule,
        FlexLayoutModule,
        NgxFilesizeModule,
        NgxFileDropModule,
        MarkdownModule.forChild(),
        RouterModule
    ],
    exports: [
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        FlexLayoutModule,
        LoadingOverlayComponent,
        EpdCardComponent,
        EpdMultiselectComponent,
        ConfirmationDialogComponent,
        ErrorDialogComponent,
        FileUploadComponent,
        MarkdownComponent,
        ArchwizardModule,
        LocalizedDatePipe,
        LocalizedDatetimePipe,
        EllipsisPipe,
        NgxFilesizeModule,
        NgxFileDropModule,
        ViewBusinessProfileComponent,
        RSSAlertsComponent,
        MaxRangeDirective,
        DataTranslationKeyPipe,
        TrackAndTranslatePipe,
        AttachedDocumentVehicleManagementComponent,
        AttachedDocumentComponent

    ]
})
export class SharedModule { }
