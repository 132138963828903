import { Injectable } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { LoadingOverlayComponent } from '../components/loading-overlay.component';

@Injectable({
    providedIn: 'root',
})
export class LoadingOverlayService {

    constructor(
        private overlay: Overlay) { }

    open(): OverlayRef  {

        // Returns an OverlayRef which is a PortalHost
        const overlayRef = this.createOverlay();

        // Create ComponentPortal that can be attached to a PortalHost
        const filePreviewPortal = new ComponentPortal(LoadingOverlayComponent);

        // Attach ComponentPortal to PortalHost
        overlayRef.attach(filePreviewPortal);

        return overlayRef;
    }

    private createOverlay() {
        const overlayConfig = this.getOverlayConfig();
        return this.overlay.create(overlayConfig);
    }


    private getOverlayConfig(): OverlayConfig {
        const positionStrategy = this.overlay.position()
            .global()
            .centerHorizontally()
            .centerVertically();

        const overlayConfig = new OverlayConfig({
            hasBackdrop: true,
            scrollStrategy: this.overlay.scrollStrategies.block(),
            positionStrategy
        });

        return overlayConfig;
    }
}
