import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class RedirectAfterLoginService {


    constructor() { }

    public set routerUrl(routerUrl: string) {
        localStorage.setItem('redirect_routerUrl', routerUrl);
    }

    public get routerUrl(): string {
        const routerUrl = this.readRouterUrl();
        localStorage.removeItem('redirect_routerUrl');
        return routerUrl;
    }

    public readRouterUrl(): string {
        const routerUrl = localStorage.getItem('redirect_routerUrl');
        return (routerUrl == null || routerUrl.startsWith('/home')) ? null : routerUrl;
    }
}