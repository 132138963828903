<mat-card class="rss-card" style="height: max-content">
  <mat-card-header>
    <mat-card-title>
      <div fxLayout="row" fxLayoutAlign="start center">
        <div>
          {{ this.rss?.title }}
        </div>
        <div>
          <a mat-icon-button color="primary" href="{{this.rss?.link}}" target="_blank" fxLayoutAlign="end none">
            <mat-icon>rss_feed</mat-icon>
          </a>
        </div>
      </div>
    </mat-card-title>

  </mat-card-header>
  <mat-card-content class="rss-card-content" *ngIf="rss?.items">
    <div fxLayout="column">

      <div fxLayout="row" *ngFor="let item of rss?.items">
        <div fxFlex="10" fxLayoutAlign="start">
          <span>{{ item.published | localizedDate }}</span>
        </div>
        <div fxFlex="80" fxLayoutAlign="start">
          <span [innerHtml]="item.title"></span>
        </div>
        <div fxFlex="10" fxLayoutAlign="center">
          <a mat-button color="primary" href="{{item.link}}" target="_blank">{{'rss.read' | trackAndTranslate}}</a>
        </div>
      </div>

    </div>

  </mat-card-content>
</mat-card>
