/**
 * TIR-EPD REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tirepd@iru.org
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Party } from './party';
import { HaulierTIRAccess } from './haulierTIRAccess';
import { BusinessProfile } from './businessProfile';
import { OrganisationApplicationSettings } from './organisationApplicationSettings';


export interface Organisation { 
    type: Organisation.TypeEnum;
    party: Party;
    appSettings?: OrganisationApplicationSettings;
    haulierTIRAccess?: HaulierTIRAccess;
    businessProfile?: BusinessProfile;
    lastModificationDate: string;
}
export namespace Organisation {
    export type TypeEnum = 'iru' | 'association' | 'haulier' | 'third-party' | 'other';
    export const TypeEnum = {
        Iru: 'iru' as TypeEnum,
        Association: 'association' as TypeEnum,
        Haulier: 'haulier' as TypeEnum,
        ThirdParty: 'third-party' as TypeEnum,
        Other: 'other' as TypeEnum
    };
}


