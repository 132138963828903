import { Injectable } from '@angular/core';
import { ApplicationMarkdownExtension, SettingsService } from '@api/index';
import { MarkdownService } from '@app/shared/services/markdown.service';
import { Observable } from 'rxjs';
import { LoginMarkdownServiceProperties } from '../models/login-markdown-service-properties';

@Injectable({
    providedIn: 'root'
})
export class LoginMarkdownService implements MarkdownService {

    constructor(
        private settingsService: SettingsService) {

    }

    public getMarkdowns(properties: LoginMarkdownServiceProperties, localeCode: string):
        Observable<ApplicationMarkdownExtension[]> {

        return this.settingsService.getUserLoginPopups(localeCode);
    }
}
