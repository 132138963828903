/**
 * STS Management API for Applications
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: Matthieu.Verbert@iru.org
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { ApplicationGroupTranslatedLabel } from '../model/applicationGroupTranslatedLabel';
// @ts-ignore
import { ApplicationGroupTranslatedLabels } from '../model/applicationGroupTranslatedLabels';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class ApplicationGroupTranslatedLabelService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Create a new application group translated label
     * @param applicationGroupId the identifier of the application group
     * @param applicationGroupTranslatedLabel the application group translated label content
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createApplicationGroupTranslatedLabel(applicationGroupId: number, applicationGroupTranslatedLabel: ApplicationGroupTranslatedLabel, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<any>;
    public createApplicationGroupTranslatedLabel(applicationGroupId: number, applicationGroupTranslatedLabel: ApplicationGroupTranslatedLabel, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<any>>;
    public createApplicationGroupTranslatedLabel(applicationGroupId: number, applicationGroupTranslatedLabel: ApplicationGroupTranslatedLabel, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<any>>;
    public createApplicationGroupTranslatedLabel(applicationGroupId: number, applicationGroupTranslatedLabel: ApplicationGroupTranslatedLabel, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (applicationGroupId === null || applicationGroupId === undefined) {
            throw new Error('Required parameter applicationGroupId was null or undefined when calling createApplicationGroupTranslatedLabel.');
        }
        if (applicationGroupTranslatedLabel === null || applicationGroupTranslatedLabel === undefined) {
            throw new Error('Required parameter applicationGroupTranslatedLabel was null or undefined when calling createApplicationGroupTranslatedLabel.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (stsapp_token_write) required
        localVarCredential = this.configuration.lookupCredential('stsapp_token_write');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/application-groups/${this.configuration.encodeParam({name: "applicationGroupId", value: applicationGroupId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/translated-labels`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: applicationGroupTranslatedLabel,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete the application group translated label
     * @param applicationGroupId the identifier of the application group
     * @param id the technical identifier of the application group translated label
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteApplicationGroupTranslatedLabel(applicationGroupId: number, id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<any>;
    public deleteApplicationGroupTranslatedLabel(applicationGroupId: number, id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<HttpResponse<any>>;
    public deleteApplicationGroupTranslatedLabel(applicationGroupId: number, id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<HttpEvent<any>>;
    public deleteApplicationGroupTranslatedLabel(applicationGroupId: number, id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined,}): Observable<any> {
        if (applicationGroupId === null || applicationGroupId === undefined) {
            throw new Error('Required parameter applicationGroupId was null or undefined when calling deleteApplicationGroupTranslatedLabel.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteApplicationGroupTranslatedLabel.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (stsapp_token_write) required
        localVarCredential = this.configuration.lookupCredential('stsapp_token_write');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/application-groups/${this.configuration.encodeParam({name: "applicationGroupId", value: applicationGroupId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/translated-labels/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find one application group translated label
     * @param applicationGroupId the identifier of the application group
     * @param id the technical identifier of the application group translated label
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findApplicationGroupTranslatedLabel(applicationGroupId: number, id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<ApplicationGroupTranslatedLabel>;
    public findApplicationGroupTranslatedLabel(applicationGroupId: number, id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<ApplicationGroupTranslatedLabel>>;
    public findApplicationGroupTranslatedLabel(applicationGroupId: number, id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<ApplicationGroupTranslatedLabel>>;
    public findApplicationGroupTranslatedLabel(applicationGroupId: number, id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (applicationGroupId === null || applicationGroupId === undefined) {
            throw new Error('Required parameter applicationGroupId was null or undefined when calling findApplicationGroupTranslatedLabel.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling findApplicationGroupTranslatedLabel.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (stsapp_token_read) required
        localVarCredential = this.configuration.lookupCredential('stsapp_token_read');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/application-groups/${this.configuration.encodeParam({name: "applicationGroupId", value: applicationGroupId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/translated-labels/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<ApplicationGroupTranslatedLabel>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search for application group translated labels
     * @param applicationGroupId the identifier of the application group
     * @param searchTerm a search term for the application group translated label
     * @param sort the primary element on which to sort ; the application group translated label id is always added as secondary sort
     * @param descSortOrder 
     * @param pageSize the number of requested results
     * @param pageAfterSortId the non unique value (of element used to sort) just before the requested page of result
     * @param pageAfterSortApplicationGroupTranslatedLabelId the application group translated label id (of element used to sort) just before the requested page of result
     * @param pageBeforeSortId the non unique value (of element used to sort) just after the requested page of result
     * @param pageBeforeSortApplicationGroupTranslatedLabelId the application group translated label id (of element used to sort) just after the requested page of result
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchApplicationGroupTranslatedLabels(applicationGroupId: number, searchTerm?: string, sort?: string, descSortOrder?: boolean, pageSize?: number, pageAfterSortId?: string, pageAfterSortApplicationGroupTranslatedLabelId?: number, pageBeforeSortId?: string, pageBeforeSortApplicationGroupTranslatedLabelId?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<ApplicationGroupTranslatedLabels>;
    public searchApplicationGroupTranslatedLabels(applicationGroupId: number, searchTerm?: string, sort?: string, descSortOrder?: boolean, pageSize?: number, pageAfterSortId?: string, pageAfterSortApplicationGroupTranslatedLabelId?: number, pageBeforeSortId?: string, pageBeforeSortApplicationGroupTranslatedLabelId?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<ApplicationGroupTranslatedLabels>>;
    public searchApplicationGroupTranslatedLabels(applicationGroupId: number, searchTerm?: string, sort?: string, descSortOrder?: boolean, pageSize?: number, pageAfterSortId?: string, pageAfterSortApplicationGroupTranslatedLabelId?: number, pageBeforeSortId?: string, pageBeforeSortApplicationGroupTranslatedLabelId?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<ApplicationGroupTranslatedLabels>>;
    public searchApplicationGroupTranslatedLabels(applicationGroupId: number, searchTerm?: string, sort?: string, descSortOrder?: boolean, pageSize?: number, pageAfterSortId?: string, pageAfterSortApplicationGroupTranslatedLabelId?: number, pageBeforeSortId?: string, pageBeforeSortApplicationGroupTranslatedLabelId?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (applicationGroupId === null || applicationGroupId === undefined) {
            throw new Error('Required parameter applicationGroupId was null or undefined when calling searchApplicationGroupTranslatedLabels.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (searchTerm !== undefined && searchTerm !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>searchTerm, 'searchTerm');
        }
        if (sort !== undefined && sort !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sort, 'sort');
        }
        if (descSortOrder !== undefined && descSortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>descSortOrder, 'descSortOrder');
        }
        if (pageSize !== undefined && pageSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageSize, 'pageSize');
        }
        if (pageAfterSortId !== undefined && pageAfterSortId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageAfterSortId, 'pageAfterSortId');
        }
        if (pageAfterSortApplicationGroupTranslatedLabelId !== undefined && pageAfterSortApplicationGroupTranslatedLabelId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageAfterSortApplicationGroupTranslatedLabelId, 'pageAfterSortApplicationGroupTranslatedLabelId');
        }
        if (pageBeforeSortId !== undefined && pageBeforeSortId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageBeforeSortId, 'pageBeforeSortId');
        }
        if (pageBeforeSortApplicationGroupTranslatedLabelId !== undefined && pageBeforeSortApplicationGroupTranslatedLabelId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageBeforeSortApplicationGroupTranslatedLabelId, 'pageBeforeSortApplicationGroupTranslatedLabelId');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (stsapp_token_read) required
        localVarCredential = this.configuration.lookupCredential('stsapp_token_read');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/application-groups/${this.configuration.encodeParam({name: "applicationGroupId", value: applicationGroupId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/translated-labels`;
        return this.httpClient.request<ApplicationGroupTranslatedLabels>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update the application group translated label
     * @param applicationGroupId the identifier of the application group
     * @param id the technical identifier of the application group translated label
     * @param applicationGroupTranslatedLabel the application group translated label content
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateApplicationGroupTranslatedLabel(applicationGroupId: number, id: number, applicationGroupTranslatedLabel: ApplicationGroupTranslatedLabel, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<any>;
    public updateApplicationGroupTranslatedLabel(applicationGroupId: number, id: number, applicationGroupTranslatedLabel: ApplicationGroupTranslatedLabel, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<any>>;
    public updateApplicationGroupTranslatedLabel(applicationGroupId: number, id: number, applicationGroupTranslatedLabel: ApplicationGroupTranslatedLabel, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<any>>;
    public updateApplicationGroupTranslatedLabel(applicationGroupId: number, id: number, applicationGroupTranslatedLabel: ApplicationGroupTranslatedLabel, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (applicationGroupId === null || applicationGroupId === undefined) {
            throw new Error('Required parameter applicationGroupId was null or undefined when calling updateApplicationGroupTranslatedLabel.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateApplicationGroupTranslatedLabel.');
        }
        if (applicationGroupTranslatedLabel === null || applicationGroupTranslatedLabel === undefined) {
            throw new Error('Required parameter applicationGroupTranslatedLabel was null or undefined when calling updateApplicationGroupTranslatedLabel.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (stsapp_token_write) required
        localVarCredential = this.configuration.lookupCredential('stsapp_token_write');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/application-groups/${this.configuration.encodeParam({name: "applicationGroupId", value: applicationGroupId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/translated-labels/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                body: applicationGroupTranslatedLabel,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
