import { NgModule } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { RouteGuard } from './core/guards/route.guard';
import { ContentLayoutComponent } from './layouts/content-layout/content-layout.component';
import { PublicLayoutComponent } from './layouts/public-layout/public-layout.component';
import { PosthogService } from "@app/core/services/posthog.service";


const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'public',
    component: PublicLayoutComponent,
    loadChildren: () => import('./modules/public/public.module').then(m => m.PublicModule)
  },
  {
    path: 'new-user',
    component: ContentLayoutComponent,
    loadChildren: () => import('./modules/new-user/new-user.module').then(m => m.NewUserModule)
  },
  {
    path: '',
    component: ContentLayoutComponent,
    canActivate: [RouteGuard],
    children: [{
      path: 'home',
      loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule)
    },
    {
      path: 'order',
      loadChildren: () => import('./modules/order/order.module').then(m => m.OrderModule)
    },
    {
      path: 'epd',
      loadChildren: () => import('./modules/epd/epd.module').then(m => m.EpdModule)
    },
    {
      path: 'company',
      loadChildren: () => import('./modules/company/company.module').then(m => m.CompanyModule)
    },
    {
      path: 't',
      loadChildren: () => import('./modules/t/t.module').then(m => m.TModule)
    }]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(private router: Router,
              private postHogService: PosthogService) {
    this.postHogService.capture(router);
  }
}
