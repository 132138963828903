import { Injectable, NgZone } from "@angular/core";
import { WebApplicationConfigurationService } from "@app/shared/services/web-application-configuration.service";
import { forkJoin } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MatomoService {

  private _initialized: boolean = false;

  constructor(
    private webApplicationConfigurationService: WebApplicationConfigurationService,
    private zone: NgZone) {
  }

  public init(): void {
    // initialise Matomo Analytics with environment configuration
    forkJoin([
      this.webApplicationConfigurationService.getPublicPropertyValue('public.analytics.matomo.base.url'),
      this.webApplicationConfigurationService.getPublicPropertyValue('public.analytics.matomo.site.id'),
      this.webApplicationConfigurationService.getPublicPropertyValue('public.analytics.matomo.container.scriptName')
    ])
      .subscribe(async ([analyticsMatomoBaseUrl, analyticsMatomoSiteId, analyticsMatomoContainerPath]) => {
        if (analyticsMatomoBaseUrl && analyticsMatomoSiteId) {
          this.zone.runOutsideAngular(() => {
            var _paq = window['_paq'] = window['_paq'] || [];
            /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
            _paq.push(['trackPageView']);
            _paq.push(['enableLinkTracking']);
            (function() {
              var u=analyticsMatomoBaseUrl.replace(/\/$|$/, '/');
              _paq.push(['setTrackerUrl', u+'matomo.php']);
              _paq.push(['setSiteId', analyticsMatomoSiteId]);
              var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
              g.type='text/javascript'; g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
            })();

            if (analyticsMatomoContainerPath) {
              var u=analyticsMatomoBaseUrl.replace(/\/$|$/, '/')+'js/';
              var _mtm = window['_mtm'] = window['_mtm'] || [];
              _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
              var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
              g.type='text/javascript'; g.async=true; g.src=u+analyticsMatomoContainerPath; s.parentNode.insertBefore(g,s);
            }

            this._initialized = true;
          });
        }
      });
  }

  public identify(username: string, tenant: string, bctIndicator: boolean): void {
    if (this._initialized) {
      this.zone.runOutsideAngular(() => {
        var _paq = window['_paq'] = window['_paq'] || [];
        // User has just logged in, call 'setUserId'
        _paq.push(['setUserId', username]);
        _paq.push(['setCustomDimension', 1, username]);
        _paq.push(['setCustomDimension', 2, tenant]);
        _paq.push(['setCustomDimension', 3, bctIndicator]);
        _paq.push(['trackPageView']);
        _paq.push(['enableLinkTracking']);
      });
    }
  }

  public reset(): void {
    if (this._initialized) {
      this.zone.runOutsideAngular(() => {
        var _paq = window['_paq'] = window['_paq'] || [];
        // User has just logged out, we reset the User ID
        _paq.push(['resetUserId']);
        _paq.push(['deleteCustomDimension', 1]);
        _paq.push(['deleteCustomDimension', 2]);
        // we also force a new visit to be created for the pageviews after logout
        _paq.push(['appendToTrackingUrl', 'new_visit=1']);
        _paq.push(['trackPageView']);
        // we finally make sure to not again create a new visit afterwards (important for Single Page Applications)
        _paq.push(['appendToTrackingUrl', '']);
      });
    }
  }
}
