/**
 * TIR-EPD REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tirepd@iru.org
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { TransitGuarantee } from '../model/transitGuarantee';
// @ts-ignore
import { TransitGuaranteeFormat } from '../model/transitGuaranteeFormat';
// @ts-ignore
import { TransitGuaranteeLegalFramework } from '../model/transitGuaranteeLegalFramework';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class GuaranteeService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Load a guarantee
     * @param guaranteeNumber the guarantee number
     * @param holderId the id of the holder of the guarantee ; if none, the organisation of the logged-in user
     * @param transitCountryCode the codes of the countries where the transit guarantee will be used ; if not set, then tokenConsumption is loaded for all possible countries 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public loadGuarantee(guaranteeNumber: string, holderId?: string, transitCountryCode?: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<TransitGuarantee>;
    public loadGuarantee(guaranteeNumber: string, holderId?: string, transitCountryCode?: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<TransitGuarantee>>;
    public loadGuarantee(guaranteeNumber: string, holderId?: string, transitCountryCode?: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<TransitGuarantee>>;
    public loadGuarantee(guaranteeNumber: string, holderId?: string, transitCountryCode?: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (guaranteeNumber === null || guaranteeNumber === undefined) {
            throw new Error('Required parameter guaranteeNumber was null or undefined when calling loadGuarantee.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (holderId !== undefined && holderId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>holderId, 'holderId');
        }
        if (transitCountryCode) {
            transitCountryCode.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'transitCountryCode');
            })
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (read-epd) required
        localVarCredential = this.configuration.lookupCredential('read-epd');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/guarantees/${this.configuration.encodeParam({name: "guaranteeNumber", value: guaranteeNumber, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<TransitGuarantee>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search transit guarantees according to criteria (without token consumption details)
     * @param legalFramework the legal framework
     * @param format the format
     * @param holderId the id of the holder of the guarantee ; if none, the organisation of the logged-in user
     * @param minTransitOperationCount the minimal count of transit operations to support
     * @param transitCountryCode the codes of the countries where the transit guarantee will be used
     * @param forEpdIndicator if the transit guarantee is to be used in a predeclaration
     * @param inEpdIndicator if the transit guarantee is already used in a predeclaration
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchGuarantees(legalFramework?: TransitGuaranteeLegalFramework, format?: TransitGuaranteeFormat, holderId?: string, minTransitOperationCount?: number, transitCountryCode?: Array<string>, forEpdIndicator?: boolean, inEpdIndicator?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<Array<TransitGuarantee>>;
    public searchGuarantees(legalFramework?: TransitGuaranteeLegalFramework, format?: TransitGuaranteeFormat, holderId?: string, minTransitOperationCount?: number, transitCountryCode?: Array<string>, forEpdIndicator?: boolean, inEpdIndicator?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<Array<TransitGuarantee>>>;
    public searchGuarantees(legalFramework?: TransitGuaranteeLegalFramework, format?: TransitGuaranteeFormat, holderId?: string, minTransitOperationCount?: number, transitCountryCode?: Array<string>, forEpdIndicator?: boolean, inEpdIndicator?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<Array<TransitGuarantee>>>;
    public searchGuarantees(legalFramework?: TransitGuaranteeLegalFramework, format?: TransitGuaranteeFormat, holderId?: string, minTransitOperationCount?: number, transitCountryCode?: Array<string>, forEpdIndicator?: boolean, inEpdIndicator?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (legalFramework !== undefined && legalFramework !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>legalFramework, 'legalFramework');
        }
        if (format !== undefined && format !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>format, 'format');
        }
        if (holderId !== undefined && holderId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>holderId, 'holderId');
        }
        if (minTransitOperationCount !== undefined && minTransitOperationCount !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>minTransitOperationCount, 'minTransitOperationCount');
        }
        if (transitCountryCode) {
            transitCountryCode.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'transitCountryCode');
            })
        }
        if (forEpdIndicator !== undefined && forEpdIndicator !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>forEpdIndicator, 'forEpdIndicator');
        }
        if (inEpdIndicator !== undefined && inEpdIndicator !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>inEpdIndicator, 'inEpdIndicator');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (read-epd) required
        localVarCredential = this.configuration.lookupCredential('read-epd');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/guarantees`;
        return this.httpClient.request<Array<TransitGuarantee>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
