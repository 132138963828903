import { Component } from '@angular/core';


@Component({
  selector: 'app-disabled-user',
  templateUrl: './disabled-user.component.html',
  styleUrls: ['./disabled-user.component.scss']
})
export class DisabledUserComponent {

  error: string;

  constructor() {

  }

}
