import { Injectable } from '@angular/core';
import { AuthAuthService } from './auth-auth.service';



export enum Action {
  MANAGE_VEHICLE = 'manage-vehicle',
  MANAGE_DRIVER = 'manage-driver',
  MANAGE_TRADER = 'manage-trader',
  MANAGE_GUARANTEE_ORDER = 'manage-guarantee-order',
  READ_EPD = 'read-epd',
  UPDATE_EPD = 'update-epd',
  CREATE_EPD = 'create-epd',
  SUBMIT_EPD = 'submit-epd',
  SEARCH_BUSINESS_PROFILE = 'search-business-profile',
  MANAGE_USER = 'manage-user',
  MANAGE_COMPANY_DETAIL = 'manage-company-detail'
}

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  public constructor(private authAuthService: AuthAuthService) {
  }

  public canManageVehicles(): boolean {
    return this.canAccess(Action.MANAGE_VEHICLE);
  }

  public canManageDrivers(): boolean {
    return this.canAccess(Action.MANAGE_DRIVER);
  }

  public canManageTraders(): boolean {
    return this.canAccess(Action.MANAGE_TRADER);
  }

  public canManageGuaranteeOrder(): boolean {
    return this.canAccess(Action.MANAGE_GUARANTEE_ORDER);
  }

  public canReadEpds(): boolean {
    return this.canAccess(Action.READ_EPD);
  }

  public canUpdateEpds(): boolean {
    return this.canAccess(Action.UPDATE_EPD);
  }

  public canCreateEpd(): boolean {
    return this.canAccess(Action.CREATE_EPD);
  }

  public canSubmitEpds(): boolean {
    return this.canAccess(Action.SUBMIT_EPD);
  }

  public canSearchBusinessProfiles(): boolean {
    return this.canAccess(Action.SEARCH_BUSINESS_PROFILE);
  }

  public canManageUsers(): boolean {
    return this.canAccess(Action.MANAGE_USER);
  }

  public canManageCompanyDetail(): boolean {
    return this.canAccess(Action.MANAGE_COMPANY_DETAIL);
  }


  public canAccess(action: string): boolean {
    return this.authAuthService && !!this.authAuthService.profile && this.authAuthService.profile.includes(action);
  }
}
