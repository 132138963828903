import { Injectable } from '@angular/core';
import { ApplicationPopup, BusinessProfileType, FieldEntity, LocationPartyProperties, Organisation, OrganisationService as apiOrganisationService, Rule } from '@api/index';
import { UserProfile } from '@app/core/services/auth-auth.service';
import { BusinessOrganisationType } from "@app/modules/company/models/business-organisation-type-enum";
import { ReplaySubject } from 'rxjs';



export type Visibility = 'view' | 'edit';

@Injectable({
    providedIn: 'root',
})
export class OrganisationService {

    private _organisation: ReplaySubject<Organisation>;
    private _organisations: [string, ReplaySubject<Organisation>][] = [];
    private _entityRules: [FieldEntity, ReplaySubject<Rule[]>][] = [];
    private _tradePartyRecommendedProperties: ReplaySubject<LocationPartyProperties[]>;
    private _haulierRecommendedProperties: ReplaySubject<LocationPartyProperties[]>;

    private static THIRD_PARTY_BUSINESS_PROFILE_TYPE_PARTY_PROPERTY_NAME = 'https://tirepd.iru.org/organisation/business-profile/type';

    constructor(
        private organisationService: apiOrganisationService) {

    }

    patchOrganisation(organisation: Organisation): void {
        this.organisationService.patchOrganisation(organisation).subscribe(() => {
            this.organisationService.getOrganisation().subscribe(organisation => this._organisation.next(organisation));
        });
    }

    savePopups(requestBody: { [key: string]: ApplicationPopup; }): void {
        this.organisationService.savePopups(requestBody).subscribe(() => {
            this.organisationService.getOrganisation().subscribe(organisation => this._organisation.next(organisation));
        });
    }

    getCurrentUserOrganisation(reloadCache: boolean = false): ReplaySubject<Organisation> {
        if (this._organisation == null || reloadCache) {
            if (this._organisation == null) {
                this._organisation = new ReplaySubject<Organisation>(1);
            }

            this.organisationService.getOrganisation().subscribe(organisation => this._organisation.next(organisation));
        }
        return this._organisation;
    }

    getUserOrganisation(user: UserProfile, reloadCache: boolean): ReplaySubject<Organisation> {
        return this.doGetOrganisation(null, user.tenant, reloadCache);
    }

    getOrganisation(id: string, reloadCache: boolean): ReplaySubject<Organisation> {
        return this.doGetOrganisation(id, id, reloadCache);
    }

    private doGetOrganisation(id: string, tenant: string, reloadCache: boolean): ReplaySubject<Organisation> {
        if (this._organisations[tenant] == null || reloadCache) {
            if (this._organisations[tenant] == null) {
                this._organisations[tenant] = new ReplaySubject<Organisation>(1);
            }


            this.organisationService.getOrganisation(id)
                .toPromise()
                .then(
                    organisation => {
                        this._organisations[organisation.party.id].next(organisation);
                    },
                    error => {
                        this._organisations[tenant].error(error);
                    })
                .catch(err => {
                    this._organisations[tenant] = null;
                });

        }
        return this._organisations[tenant];
    }

    getEntityRules(fieldEntity: FieldEntity): ReplaySubject<Rule[]> {

        if (this._entityRules[fieldEntity] == null) {
            this._entityRules[fieldEntity] = new ReplaySubject<Rule[]>(1);

            this.organisationService.getEntityRules(fieldEntity).subscribe(rules => this._entityRules[fieldEntity].next(rules));
        }
        return this._entityRules[fieldEntity];
    }


    getTradePartyRecommendedProperties(): ReplaySubject<LocationPartyProperties[]> {

        if (this._tradePartyRecommendedProperties == null) {
            this._tradePartyRecommendedProperties = new ReplaySubject<LocationPartyProperties[]>(1);
            this.organisationService.getPartyRecommendedProperties('trade-party')
                .subscribe(locationPartyProperties => this._tradePartyRecommendedProperties.next(locationPartyProperties));
        }
        return this._tradePartyRecommendedProperties;

    }


    getHaulierRecommendedProperties(): ReplaySubject<LocationPartyProperties[]> {

        if (this._haulierRecommendedProperties == null) {
            this._haulierRecommendedProperties = new ReplaySubject<LocationPartyProperties[]>(1);
            this.organisationService.getPartyRecommendedProperties('haulier')
                .subscribe(locationPartyProperties => this._haulierRecommendedProperties.next(locationPartyProperties),
                    error => this._haulierRecommendedProperties.error(error));
        }
        return this._haulierRecommendedProperties;

    }

    getBusinessProfileType(organisation: Organisation): BusinessProfileType {
        let result: BusinessProfileType = null;

        switch (organisation.type) {
            case Organisation.TypeEnum.Association:
                result = BusinessProfileType.Association;
                break;
            case Organisation.TypeEnum.Haulier:
                result = BusinessProfileType.Holder;
                break;
            case Organisation.TypeEnum.ThirdParty:
                if (organisation.party != null
                    && organisation.party.properties != null
                    && organisation.party.properties.length > 0) {
                    const filteredProperties = organisation.party.properties
                        .filter(property => property.name === OrganisationService.THIRD_PARTY_BUSINESS_PROFILE_TYPE_PARTY_PROPERTY_NAME);

                    if (filteredProperties.length > 0) {
                        const filteredEnumValues = Object.values(BusinessProfileType)
                            .filter(businessProfileTypeValue => businessProfileTypeValue.valueOf() === filteredProperties[0].value);
                        if (filteredEnumValues.length > 0) {
                            result = filteredEnumValues[0];
                        }
                    }
                }
                break;
            default:
                result = null;
        }

        return result;
    }

    getAccessActionMapForOrganisation(organisation: Organisation): Map<BusinessOrganisationType.TypeEnum, Visibility> {
        const accessActionTypeMap: Map<BusinessOrganisationType.TypeEnum, Visibility> = new Map();
        const usersBusinessOrganisationType: BusinessOrganisationType.TypeEnum = BusinessOrganisationType.getBusinessOrganisationTypeByOrganisation(organisation);

        switch (usersBusinessOrganisationType) {
            case BusinessOrganisationType.TypeEnum.FreightForwarder:
                accessActionTypeMap.set(BusinessOrganisationType.TypeEnum.TirHaulier, 'view');
                break;
            case BusinessOrganisationType.TypeEnum.Warehouse:
                accessActionTypeMap.set(BusinessOrganisationType.TypeEnum.TirHaulier, 'view');
                break;
            case BusinessOrganisationType.TypeEnum.Broker:
                accessActionTypeMap.set(BusinessOrganisationType.TypeEnum.TirHaulier, 'view');
                accessActionTypeMap.set(BusinessOrganisationType.TypeEnum.TransportOperator, 'view');
                break;
        }

        this.getManagableBusinessOrganisationType(organisation)
            .forEach(type => accessActionTypeMap.set(type, 'edit'));

        return accessActionTypeMap;
    }

    getManagableBusinessOrganisationType(organisation: Organisation): BusinessOrganisationType.TypeEnum[] {
        const managableBusinessOrganisationTypes: Array<BusinessOrganisationType.TypeEnum> = [];
        if (organisation) {
            const usersBusinessOrganisationType: BusinessOrganisationType.TypeEnum = BusinessOrganisationType.getBusinessOrganisationTypeByOrganisation(organisation);
            switch (usersBusinessOrganisationType) {
                case BusinessOrganisationType.TypeEnum.Iru:
                    managableBusinessOrganisationTypes.push(BusinessOrganisationType.TypeEnum.TirAssociation);
                    managableBusinessOrganisationTypes.push(BusinessOrganisationType.TypeEnum.TirHaulier);
                    managableBusinessOrganisationTypes.push(BusinessOrganisationType.TypeEnum.FreightForwarder);
                    managableBusinessOrganisationTypes.push(BusinessOrganisationType.TypeEnum.Warehouse);
                    managableBusinessOrganisationTypes.push(BusinessOrganisationType.TypeEnum.Broker);
                    managableBusinessOrganisationTypes.push(BusinessOrganisationType.TypeEnum.TransportOperator);
                    break;
                case BusinessOrganisationType.TypeEnum.TirAssociation:
                    managableBusinessOrganisationTypes.push(BusinessOrganisationType.TypeEnum.TirHaulier);
                    managableBusinessOrganisationTypes.push(BusinessOrganisationType.TypeEnum.FreightForwarder);
                    managableBusinessOrganisationTypes.push(BusinessOrganisationType.TypeEnum.Warehouse);
                    managableBusinessOrganisationTypes.push(BusinessOrganisationType.TypeEnum.Broker);
                    managableBusinessOrganisationTypes.push(BusinessOrganisationType.TypeEnum.TransportOperator);
                    break;
                case BusinessOrganisationType.TypeEnum.TirHaulier:
                    break;
                case BusinessOrganisationType.TypeEnum.Holder:
                    break;
                case BusinessOrganisationType.TypeEnum.FreightForwarder:
                    break;
                case BusinessOrganisationType.TypeEnum.Warehouse:
                    break;
                case BusinessOrganisationType.TypeEnum.Broker:
                    break;
                case BusinessOrganisationType.TypeEnum.Association:
                    break;
            }
        }
        return managableBusinessOrganisationTypes;
    }
}
