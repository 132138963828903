export * from './authentication.service';
import { AuthenticationService } from './authentication.service';
export * from './country.service';
import { CountryService } from './country.service';
export * from './currency.service';
import { CurrencyService } from './currency.service';
export * from './customsOffice.service';
import { CustomsOfficeService } from './customsOffice.service';
export * from './epd.service';
import { EpdService } from './epd.service';
export * from './feature.service';
import { FeatureService } from './feature.service';
export * from './guarantee.service';
import { GuaranteeService } from './guarantee.service';
export * from './locale.service';
import { LocaleService } from './locale.service';
export * from './order.service';
import { OrderService } from './order.service';
export * from './organisation.service';
import { OrganisationService } from './organisation.service';
export * from './settings.service';
import { SettingsService } from './settings.service';
export const APIS = [AuthenticationService, CountryService, CurrencyService, CustomsOfficeService, EpdService, FeatureService, GuaranteeService, LocaleService, OrderService, OrganisationService, SettingsService];
