import { BusinessProfileType, Organisation } from "@api/index";



export namespace BusinessOrganisationType {
    export type TypeEnum = 'iru' | 'tir-association' | 'tir-haulier' | 'holder' | 'freightForwarder' | 'warehouse' | 'broker' | 'association' | 'transportOperator';
    export const TypeEnum = {
        Iru: 'iru' as TypeEnum,
        TirAssociation: 'tir-association' as TypeEnum,
        TirHaulier: 'tir-haulier' as TypeEnum,
        Holder: 'holder' as TypeEnum,
        FreightForwarder: 'freightForwarder' as TypeEnum,
        Warehouse: 'warehouse' as TypeEnum,
        Broker: 'broker' as TypeEnum,
        Association: 'association' as TypeEnum,
        TransportOperator: 'transportOperator' as TypeEnum
    };


    export type BusinessOrganisationTypeDefinition = { type: Organisation.TypeEnum, businessProfileType: BusinessProfileType };


    export const BusinessOrganisationTypeAssociation = new Map<BusinessOrganisationType.TypeEnum, BusinessOrganisationTypeDefinition>([
        [BusinessOrganisationType.TypeEnum.Iru, { type: Organisation.TypeEnum.Iru, businessProfileType: null }],
        [BusinessOrganisationType.TypeEnum.TirAssociation, { type: Organisation.TypeEnum.Association, businessProfileType: undefined }],
        [BusinessOrganisationType.TypeEnum.TirHaulier, { type: Organisation.TypeEnum.Haulier, businessProfileType: undefined }],
        [BusinessOrganisationType.TypeEnum.Holder, { type: Organisation.TypeEnum.ThirdParty, businessProfileType: BusinessProfileType.Holder }],
        [BusinessOrganisationType.TypeEnum.FreightForwarder, { type: Organisation.TypeEnum.ThirdParty, businessProfileType: BusinessProfileType.FreightForwarder }],
        [BusinessOrganisationType.TypeEnum.Warehouse, { type: Organisation.TypeEnum.ThirdParty, businessProfileType: BusinessProfileType.Warehouse }],
        [BusinessOrganisationType.TypeEnum.Broker, { type: Organisation.TypeEnum.ThirdParty, businessProfileType: BusinessProfileType.Broker }],
        [BusinessOrganisationType.TypeEnum.Association, { type: Organisation.TypeEnum.ThirdParty, businessProfileType: BusinessProfileType.Association }],
        [BusinessOrganisationType.TypeEnum.TransportOperator, { type: Organisation.TypeEnum.ThirdParty, businessProfileType: BusinessProfileType.TransportOperator }]
    ]);


    export function getBusinessOrganisationTypeByOrganisation(organisation: Organisation): BusinessOrganisationType.TypeEnum {
        const businessProfileTypeString = organisation.party.properties?.find(property => property.name === 'https://tirepd.iru.org/organisation/business-profile/type')?.value;
        const businessProfileType = Object.values(BusinessProfileType).find(enumValue => enumValue === businessProfileTypeString);
        return getBusinessOrganisationType(organisation.type, businessProfileType);
    }

    export function getBusinessOrganisationType(type: Organisation.TypeEnum, businessProfileType: BusinessProfileType): BusinessOrganisationType.TypeEnum {
        let result: BusinessOrganisationType.TypeEnum = null;
        BusinessOrganisationTypeAssociation.forEach((value, key) => {

            if (value.type == type && (value.businessProfileType == undefined || value.businessProfileType == businessProfileType)) {
                if (result != null) {
                    console.error('Multiple BusinessOrganisationType found with parameters : ', type, businessProfileType, BusinessOrganisationTypeAssociation);
                    return null;
                } else {
                    result = key;
                }
            }
        });

        return result;
    }

    export function getBusinessOrganisationTypeDefinition(businessOrganisationType: BusinessOrganisationType.TypeEnum): BusinessOrganisationTypeDefinition {
        return BusinessOrganisationTypeAssociation.get(businessOrganisationType);
    }
}
