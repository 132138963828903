<div class="grid-container">
  <mat-grid-list cols="2" rows="9" rowHeight="40px">
    <mat-grid-tile [colspan]="1" [rowspan]="5">
      <app-login-authentication></app-login-authentication>
    </mat-grid-tile>

    <mat-grid-tile [colspan]="1" [rowspan]="5">
      <app-login-new-user></app-login-new-user>
    </mat-grid-tile>

    <mat-grid-tile [colspan]="2" [rowspan]="7">
      <app-login-countries></app-login-countries>
    </mat-grid-tile>

    <mat-grid-tile [colspan]="2" [rowspan]="9">
      <app-shared-rss-alerts></app-shared-rss-alerts>
    </mat-grid-tile>
  </mat-grid-list>
</div>