import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateParser } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { TranslateService } from '@app/shared/services/translate.service';

@Injectable()
export class CustomPaginator extends MatPaginatorIntl {

    private rangeLabelIntl: string;

    private noResultRangeLabelIntl: string;

    constructor(private translateService: TranslateService, private translateParser: TranslateParser) {
        super();

        this.translateService.onLangChange.subscribe((e: Event) => {
            this.getTranslations();
          });

          this.getTranslations();

    }

    getTranslations() {
        this.translateService.get([
            'search.paginator.itemsPerPageLabel',
            'search.paginator.nextPageLabel',
            'search.paginator.previousPageLabel',
            'search.paginator.rangeLabel',
            'search.paginator.noResultRangeLabel'
        ])
        .subscribe(translation => {
            this.itemsPerPageLabel = translation['search.paginator.itemsPerPageLabel'];
            this.nextPageLabel = translation['search.paginator.nextPageLabel'];
            this.previousPageLabel = translation['search.paginator.previousPageLabel'];
            this.rangeLabelIntl = translation['search.paginator.rangeLabel'];
            this.noResultRangeLabelIntl = translation['search.paginator.noResultRangeLabel'];
            this.changes.next();
        });
    }

    getRangeLabel = (page: number, pageSize: number, length: number) => {
        if (length === 0 || pageSize === 0) {
            return this.noResultRangeLabelIntl;
        }
        length = Math.max(length, 0);
        let startIndex = page * pageSize;
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        startIndex++;

        return this.translateParser.interpolate(this.rangeLabelIntl, { startIndex, endIndex, length });
    }
}
