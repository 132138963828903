<ng-container *ngFor="let banner of banners">
    <mat-card>
        <mat-card-content>
            <markdown ngPreserveWhitespaces>
                {{ banner.markdowns[this.localeCode].text }}
            </markdown>
        </mat-card-content>
        <mat-card-actions *ngIf="!markdownServiceProperties.hideActions && banner.actions">
            <button *ngIf="banner.actions.routerLink" mat-stroked-button color="primary" type="button"
                [routerLink]="banner.routerLink.path" [queryParams]="banner.routerLink.queryParams">
                {{ banner.actions.routerLink }}
            </button>
            <button *ngIf="banner.actions.popup" mat-stroked-button color="primary" type="button"
                (click)="openLinkedPopup(banner.popup)">
                {{ banner.actions.popup }}
            </button>
        </mat-card-actions>
    </mat-card>
    <br />
</ng-container>