/**
 * TIR-EPD REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tirepd@iru.org
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PredeclarationSentOverallStatus = 'unsent' | 'sent' | 'acknowledged' | 'confirmed' | 'rejected';

export const PredeclarationSentOverallStatus = {
    Unsent: 'unsent' as PredeclarationSentOverallStatus,
    Sent: 'sent' as PredeclarationSentOverallStatus,
    Acknowledged: 'acknowledged' as PredeclarationSentOverallStatus,
    Confirmed: 'confirmed' as PredeclarationSentOverallStatus,
    Rejected: 'rejected' as PredeclarationSentOverallStatus
};

