/**
 * TIR-EPD REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tirepd@iru.org
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { ApplicationMarkdownExtension } from '../model/applicationMarkdownExtension';
// @ts-ignore
import { ItemCategory } from '../model/itemCategory';
// @ts-ignore
import { OrderCreation } from '../model/orderCreation';
// @ts-ignore
import { OrderCreationResponse } from '../model/orderCreationResponse';
// @ts-ignore
import { OrderDocument } from '../model/orderDocument';
// @ts-ignore
import { OrderPayment } from '../model/orderPayment';
// @ts-ignore
import { OrderRefundDetails } from '../model/orderRefundDetails';
// @ts-ignore
import { OrderResponse } from '../model/orderResponse';
// @ts-ignore
import { OrderResponseSort } from '../model/orderResponseSort';
// @ts-ignore
import { OrderResponseStatus } from '../model/orderResponseStatus';
// @ts-ignore
import { OrderResponses } from '../model/orderResponses';
// @ts-ignore
import { OrderableItem } from '../model/orderableItem';
// @ts-ignore
import { PaymentMeansReference } from '../model/paymentMeansReference';
// @ts-ignore
import { SellerType } from '../model/sellerType';
// @ts-ignore
import { SortOrder } from '../model/sortOrder';
// @ts-ignore
import { ValidationIssueMessage } from '../model/validationIssueMessage';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class OrderService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Cancel the identified order
     * @param orderId the order id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cancelOrder(orderId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<OrderRefundDetails>;
    public cancelOrder(orderId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<OrderRefundDetails>>;
    public cancelOrder(orderId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<OrderRefundDetails>>;
    public cancelOrder(orderId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling cancelOrder.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (default) required
        localVarCredential = this.configuration.lookupCredential('default');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/orders/${this.configuration.encodeParam({name: "orderId", value: orderId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<OrderRefundDetails>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new Order
     * @param orderCreation the order content; taken from payment-schemas except that orderId is not mandatory 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createOrder(orderCreation: OrderCreation, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<OrderCreationResponse>;
    public createOrder(orderCreation: OrderCreation, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<OrderCreationResponse>>;
    public createOrder(orderCreation: OrderCreation, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<OrderCreationResponse>>;
    public createOrder(orderCreation: OrderCreation, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (orderCreation === null || orderCreation === undefined) {
            throw new Error('Required parameter orderCreation was null or undefined when calling createOrder.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (default) required
        localVarCredential = this.configuration.lookupCredential('default');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/orders`;
        return this.httpClient.request<OrderCreationResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: orderCreation,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the catalogue (items) for a specific category or type of seller
     * @param itemCategory the category
     * @param sellerType the type of seller (IRU or association)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCatalogue(itemCategory?: ItemCategory, sellerType?: SellerType, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<Array<OrderableItem>>;
    public getCatalogue(itemCategory?: ItemCategory, sellerType?: SellerType, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<Array<OrderableItem>>>;
    public getCatalogue(itemCategory?: ItemCategory, sellerType?: SellerType, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<Array<OrderableItem>>>;
    public getCatalogue(itemCategory?: ItemCategory, sellerType?: SellerType, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (itemCategory !== undefined && itemCategory !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>itemCategory, 'itemCategory');
        }
        if (sellerType !== undefined && sellerType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sellerType, 'sellerType');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (default) required
        localVarCredential = this.configuration.lookupCredential('default');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/catalogues`;
        return this.httpClient.request<Array<OrderableItem>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the list of markdowns to show on products screen
     * @param itemCategory the category
     * @param localeCode the user locale
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCatalogueMarkdowns(itemCategory: ItemCategory, localeCode: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<Array<ApplicationMarkdownExtension>>;
    public getCatalogueMarkdowns(itemCategory: ItemCategory, localeCode: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<Array<ApplicationMarkdownExtension>>>;
    public getCatalogueMarkdowns(itemCategory: ItemCategory, localeCode: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<Array<ApplicationMarkdownExtension>>>;
    public getCatalogueMarkdowns(itemCategory: ItemCategory, localeCode: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (itemCategory === null || itemCategory === undefined) {
            throw new Error('Required parameter itemCategory was null or undefined when calling getCatalogueMarkdowns.');
        }
        if (localeCode === null || localeCode === undefined) {
            throw new Error('Required parameter localeCode was null or undefined when calling getCatalogueMarkdowns.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (itemCategory !== undefined && itemCategory !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>itemCategory, 'itemCategory');
        }
        if (localeCode !== undefined && localeCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>localeCode, 'localeCode');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (default) required
        localVarCredential = this.configuration.lookupCredential('default');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/catalogues/markdowns`;
        return this.httpClient.request<Array<ApplicationMarkdownExtension>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the document (proforma or invoice) for an order
     * @param orderId the order id
     * @param document the type of document
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDocument(orderId: string, document: OrderDocument, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/pdf',}): Observable<Blob>;
    public getDocument(orderId: string, document: OrderDocument, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/pdf',}): Observable<HttpResponse<Blob>>;
    public getDocument(orderId: string, document: OrderDocument, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/pdf',}): Observable<HttpEvent<Blob>>;
    public getDocument(orderId: string, document: OrderDocument, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/pdf',}): Observable<any> {
        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling getDocument.');
        }
        if (document === null || document === undefined) {
            throw new Error('Required parameter document was null or undefined when calling getDocument.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (default) required
        localVarCredential = this.configuration.lookupCredential('default');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/pdf'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let localVarPath = `/orders/${this.configuration.encodeParam({name: "orderId", value: orderId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/${this.configuration.encodeParam({name: "document", value: document, in: "path", style: "simple", explode: false, dataType: "OrderDocument", dataFormat: undefined})}.pdf`;
        return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the order details
     * @param orderId the order id
     * @param verifyPayment if we should verify the payment information before returning the data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrder(orderId: string, verifyPayment?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<OrderResponse>;
    public getOrder(orderId: string, verifyPayment?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<OrderResponse>>;
    public getOrder(orderId: string, verifyPayment?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<OrderResponse>>;
    public getOrder(orderId: string, verifyPayment?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling getOrder.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (verifyPayment !== undefined && verifyPayment !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>verifyPayment, 'verify-payment');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (default) required
        localVarCredential = this.configuration.lookupCredential('default');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/orders/${this.configuration.encodeParam({name: "orderId", value: orderId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<OrderResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param sellerType the type of seller (IRU or association)
     * @param associationId association id in the case of an association seller
     * @param officeCode association office code in the case of an association seller
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPaymentMeansReferences(sellerType?: SellerType, associationId?: number, officeCode?: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<Array<PaymentMeansReference>>;
    public getPaymentMeansReferences(sellerType?: SellerType, associationId?: number, officeCode?: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<Array<PaymentMeansReference>>>;
    public getPaymentMeansReferences(sellerType?: SellerType, associationId?: number, officeCode?: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<Array<PaymentMeansReference>>>;
    public getPaymentMeansReferences(sellerType?: SellerType, associationId?: number, officeCode?: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (sellerType !== undefined && sellerType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sellerType, 'sellerType');
        }
        if (associationId !== undefined && associationId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>associationId, 'associationId');
        }
        if (officeCode) {
            officeCode.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'officeCode');
            })
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (default) required
        localVarCredential = this.configuration.lookupCredential('default');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/global/order-payment-means`;
        return this.httpClient.request<Array<PaymentMeansReference>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search for orders
     * @param itemCategory the category
     * @param orderStatus the current status of the orders
     * @param orderFrom the first moment of order
     * @param orderTo the last moment of order
     * @param paymentMeansCodeListURI the listURI of the payment means code
     * @param paymentMeansCodeValue the value of the payment means code
     * @param sort the element on which to sort
     * @param sortOrder the direction on which to sort
     * @param pageSize the number of requested results
     * @param pageAfterSortId the id (of element used to sort) just before the requested page of result
     * @param pageBeforeSortId the id (of element used to sort) just after the requested page of result
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchOrders(itemCategory: Array<ItemCategory>, orderStatus?: OrderResponseStatus, orderFrom?: string, orderTo?: string, paymentMeansCodeListURI?: string, paymentMeansCodeValue?: string, sort?: OrderResponseSort, sortOrder?: SortOrder, pageSize?: number, pageAfterSortId?: string, pageBeforeSortId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<OrderResponses>;
    public searchOrders(itemCategory: Array<ItemCategory>, orderStatus?: OrderResponseStatus, orderFrom?: string, orderTo?: string, paymentMeansCodeListURI?: string, paymentMeansCodeValue?: string, sort?: OrderResponseSort, sortOrder?: SortOrder, pageSize?: number, pageAfterSortId?: string, pageBeforeSortId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<OrderResponses>>;
    public searchOrders(itemCategory: Array<ItemCategory>, orderStatus?: OrderResponseStatus, orderFrom?: string, orderTo?: string, paymentMeansCodeListURI?: string, paymentMeansCodeValue?: string, sort?: OrderResponseSort, sortOrder?: SortOrder, pageSize?: number, pageAfterSortId?: string, pageBeforeSortId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<OrderResponses>>;
    public searchOrders(itemCategory: Array<ItemCategory>, orderStatus?: OrderResponseStatus, orderFrom?: string, orderTo?: string, paymentMeansCodeListURI?: string, paymentMeansCodeValue?: string, sort?: OrderResponseSort, sortOrder?: SortOrder, pageSize?: number, pageAfterSortId?: string, pageBeforeSortId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (itemCategory === null || itemCategory === undefined) {
            throw new Error('Required parameter itemCategory was null or undefined when calling searchOrders.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (orderStatus !== undefined && orderStatus !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>orderStatus, 'orderStatus');
        }
        if (itemCategory) {
            itemCategory.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'itemCategory');
            })
        }
        if (orderFrom !== undefined && orderFrom !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>orderFrom, 'orderFrom');
        }
        if (orderTo !== undefined && orderTo !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>orderTo, 'orderTo');
        }
        if (paymentMeansCodeListURI !== undefined && paymentMeansCodeListURI !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paymentMeansCodeListURI, 'paymentMeansCodeListURI');
        }
        if (paymentMeansCodeValue !== undefined && paymentMeansCodeValue !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paymentMeansCodeValue, 'paymentMeansCodeValue');
        }
        if (sort !== undefined && sort !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sort, 'sort');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }
        if (pageSize !== undefined && pageSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageSize, 'pageSize');
        }
        if (pageAfterSortId !== undefined && pageAfterSortId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageAfterSortId, 'pageAfterSortId');
        }
        if (pageBeforeSortId !== undefined && pageBeforeSortId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageBeforeSortId, 'pageBeforeSortId');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (default) required
        localVarCredential = this.configuration.lookupCredential('default');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/orders`;
        return this.httpClient.request<OrderResponses>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update the payment information
     * @param orderPayment the order payment content; taken from payment-schemas 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAssociationPayment(orderPayment: OrderPayment, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<object>;
    public updateAssociationPayment(orderPayment: OrderPayment, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<object>>;
    public updateAssociationPayment(orderPayment: OrderPayment, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<object>>;
    public updateAssociationPayment(orderPayment: OrderPayment, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (orderPayment === null || orderPayment === undefined) {
            throw new Error('Required parameter orderPayment was null or undefined when calling updateAssociationPayment.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (none) required
        localVarCredential = this.configuration.lookupCredential('none');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/orders/association/payment`;
        return this.httpClient.request<object>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: orderPayment,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Verify the payment status
     * @param orderId the order id
     * @param verificationToken a verifiable token to prevent attacks on this URL
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public verifyPayment(orderId: string, verificationToken: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<any>;
    public verifyPayment(orderId: string, verificationToken: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<HttpResponse<any>>;
    public verifyPayment(orderId: string, verificationToken: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<HttpEvent<any>>;
    public verifyPayment(orderId: string, verificationToken: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined,}): Observable<any> {
        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling verifyPayment.');
        }
        if (verificationToken === null || verificationToken === undefined) {
            throw new Error('Required parameter verificationToken was null or undefined when calling verifyPayment.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (verificationToken !== undefined && verificationToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>verificationToken, 'verification-token');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (none) required
        localVarCredential = this.configuration.lookupCredential('none');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/orders/${this.configuration.encodeParam({name: "orderId", value: orderId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/payment-verification`;
        return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
