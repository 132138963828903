import { NgxMatDateAdapter, NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { NgxMatMomentAdapter, NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, NGX_MAT_MOMENT_FORMATS } from '@angular-material-components/moment-adapter';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule, ErrorHandler } from '@angular/core';
import { DefaultValueAccessor } from '@angular/forms';
import {
  MatMomentDateModule,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter
} from '@angular/material-moment-adapter';
import { MAT_CARD_CONFIG } from '@angular/material/card';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { TranslateLoader, TranslateModule, TranslateParser } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';
import {
  ApiModule as StsApiModule, Configuration as StsConfiguration
} from '../../target/openapi-sts-api-typescript';
import {
  ApiModule as StsApplicationApiModule, Configuration as StsApplicationConfiguration
} from '../../target/openapi-sts-application-api-typescript';

import {
  ApiModule as TApiModule, BASE_PATH as T_BASE_PATH, Configuration as TConfiguration
} from '../../target/openapi-t-typescript';
import { ApiModule, BASE_PATH, Configuration } from '../../target/openapi-typescript';
// import {ApiModule as StsApplicationApiModule, Configuration as StsApplicationConfiguration} from '../../target/openapi-sts-application-api-typescript';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { OAuthModule, OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpErrorInterceptor } from './core/interceptors/http-error.interceptor';
import { HttpUnauthorizedInterceptor } from './core/interceptors/http-unauthorized.interceptor';
import { MarkdownRenderer } from './core/markdown/markdown-renderer';
import { AuthServiceProvider } from './core/providers/auth.provider';
import { MarkdownWebApplicationConfigurationService } from './core/services/markdown-web-application-configuration.service';
import { CustomPaginator } from './core/translate/custom-paginator';
import { MultipleFileTranslateHttpLoader } from './core/translate/MultipleFileTranslateHttpLoader';
import { ContentLayoutComponent } from './layouts/content-layout/content-layout.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { NavComponent } from './layouts/nav/nav.component';
import { PublicLayoutComponent } from './layouts/public-layout/public-layout.component';
import { UserProfileComponent } from './layouts/user-profile/user-profile.component';
import { PublicModule } from './modules/public/public.module';
import { TranslateService } from './shared/services/translate.service';
import { GlobalErrorHandler } from "@app/core/providers/error.handler";

/*
* Trim every string entered into angular form controls
*/
const original = DefaultValueAccessor.prototype.registerOnChange;

DefaultValueAccessor.prototype.registerOnChange = function (fn) {

  return original.call(this, value => {
    const trimmed = (typeof value === 'string') ? value.trim() : value;
    return fn(trimmed);
  })
}



export function markedOptionsFactory(markdownWebApplicationConfigurationService: MarkdownWebApplicationConfigurationService):
  MarkedOptions {
  const renderer = new MarkdownRenderer(markdownWebApplicationConfigurationService);

  return {
    renderer: renderer,
    gfm: true,
    breaks: false,
    pedantic: false,
    smartLists: true,
    smartypants: false,

  };
}

export function authProviderFactory(provider: AuthServiceProvider) {
  return () => provider.load();
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new MultipleFileTranslateHttpLoader(http, [
    { prefix: './assets/i18n/', suffix: '.json', renameIndicator: true },
    { prefix: `${environment.API_BASE_PATH}/global/i18n/`, suffix: '.json', renameIndicator: false }
  ]);
}


@NgModule({
  declarations: [
    AppComponent,
    ContentLayoutComponent,
    UserProfileComponent,
    PublicLayoutComponent,
    NavComponent,
    FooterComponent
  ],
  imports: [
    // angular
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,

    // 3rd party
    PublicModule,
    OAuthModule.forRoot(),
    HighlightModule,

    // Internationalization
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),


    // API client
    ApiModule,
    StsApiModule,
    StsApplicationApiModule,
    TApiModule,



    // core & shared
    CoreModule,
    SharedModule,

    // app
    AppRoutingModule,

    // moment
    MatMomentDateModule,

    // ngx-markdown
    MarkdownModule.forRoot({
      loader: HttpClient,
      markedOptions: {
        provide: MarkedOptions,
        useFactory: markedOptionsFactory,
        deps: [MarkdownWebApplicationConfigurationService]
      }
    })
  ],
  providers: [
    {provide: ErrorHandler, useClass: GlobalErrorHandler},
    {
      provide: MAT_SELECT_CONFIG, useValue: {
        typeaheadDebounceInterval: 750
      }
    },
    {
      provide: MAT_CARD_CONFIG, useValue: { appearance: 'raised' }
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' }
    },

    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: false } },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },


    { provide: NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: false } },
    { provide: NGX_MAT_DATE_FORMATS, useValue: NGX_MAT_MOMENT_FORMATS },
    { provide: NgxMatDateAdapter, useClass: NgxMatMomentAdapter, deps: [MAT_DATE_LOCALE, NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS] },

    { provide: MatPaginatorIntl, useClass: CustomPaginator, deps: [TranslateService, TranslateParser] },

    { provide: BASE_PATH, useValue: environment.API_BASE_PATH },
    { provide: T_BASE_PATH, useValue: environment.T_API_BASE_PATH },



    {
      provide: StsConfiguration,
      useFactory: (oauthService: OAuthService) => new StsConfiguration(
        {
          accessToken: oauthService.getIdToken.bind(oauthService)
        }
      ),
      deps: [OAuthService],
      multi: false
    },

    {
      provide: StsApplicationConfiguration,
      useFactory: (oauthService: OAuthService) => new StsApplicationConfiguration(
        {

        }
      ),
      deps: [OAuthService],
      multi: false
    },

    {
      provide: Configuration,
      useFactory: (authStorage: OAuthStorage) => new Configuration(
        {
          accessToken: () => {
            return authStorage.getItem('api_access_token') ?
              authStorage.getItem('api_access_token') as string : '';
          }
        }
      ),
      deps: [OAuthStorage],
      multi: false
    },

    {
      provide: TConfiguration,
      useFactory: (authStorage: OAuthStorage) => new TConfiguration(
        {
          accessToken: () => {
            return authStorage.getItem('api_access_token') ?
              authStorage.getItem('api_access_token') as string : '';
          }
        }
      ),
      deps: [OAuthStorage],
      multi: false
    },

    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        lineNumbersLoader: () => import('highlightjs-line-numbers.js'), // Optional, only if you want the line numbers
        languages: {
          xml: () => import('highlight.js/lib/languages/xml')
        }
      }
    },

    AuthServiceProvider,
    {
      provide: APP_INITIALIZER, useFactory: authProviderFactory, deps: [AuthServiceProvider], multi: true
    },
    { provide: OAuthStorage, useFactory: () => sessionStorage },
    { provide: HTTP_INTERCEPTORS, useClass: HttpUnauthorizedInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }
