/**
 * TIR-EPD REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tirepd@iru.org
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type TransportOperationMessageExchangeActionType = 'amend' | 'resend' | 'cancel' | 'nonArrivedMovementResponse';

export const TransportOperationMessageExchangeActionType = {
    Amend: 'amend' as TransportOperationMessageExchangeActionType,
    Resend: 'resend' as TransportOperationMessageExchangeActionType,
    Cancel: 'cancel' as TransportOperationMessageExchangeActionType,
    NonArrivedMovementResponse: 'nonArrivedMovementResponse' as TransportOperationMessageExchangeActionType
};

