import { ElementRef, Injectable, Pipe, PipeTransform } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { DataTranslationKeyPipe } from './data-translation-key';

@Pipe({
  name: 'trackAndTranslate',
  pure: false
})
@Injectable({
  providedIn: 'root'
})
export class TrackAndTranslatePipe
  extends DataTranslationKeyPipe
  implements PipeTransform {

    constructor(private _element: ElementRef, private translate: TranslatePipe) {
      super(_element);
    }

    transform(query: string, ...args: any[]): any {
      super.transform(query);

      return this.translate.transform(query, ...args);
    }
}
