import { Component } from '@angular/core';
import { AuthAuthService } from '@app/core/services/auth-auth.service';


@Component({
  selector: 'app-login-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss']
})
export class AuthenticationComponent {

  public loading = false;

  constructor(private authAuthService: AuthAuthService) {
  }

  login() {
    this.loading = true;
    this.authAuthService.login();
  }

}
