/**
 * TIR-EPD REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tirepd@iru.org
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TransportOperationStartCustomsOfficeSimplifiedProcedure } from './transportOperationStartCustomsOfficeSimplifiedProcedure';
import { CustomsOffice } from './customsOffice';


export interface TransportOperationStartCustomsOffice { 
    customsOffice?: CustomsOffice;
    type?: TransportOperationStartCustomsOffice.TypeEnum;
    stepReference?: number;
    simplifiedProcedure?: TransportOperationStartCustomsOfficeSimplifiedProcedure;
    expectedArrivalDate?: string;
}
export namespace TransportOperationStartCustomsOffice {
    export type TypeEnum = 'departure' | 'entry' | 'destination__after_partial_unload';
    export const TypeEnum = {
        Departure: 'departure' as TypeEnum,
        Entry: 'entry' as TypeEnum,
        DestinationAfterPartialUnload: 'destination__after_partial_unload' as TypeEnum
    };
}


