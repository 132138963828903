import { Component, OnInit } from '@angular/core';
import { AuthAuthService } from '@app/core/services/auth-auth.service';
import { UserContextService } from '@app/core/services/user-context.service';
import { WebApplicationConfigurationService } from '@app/shared/services/web-application-configuration.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  public canChangePassword = false;

  constructor(
    private authService: AuthAuthService,
    public userContextService: UserContextService,
    private webApplicationConfigurationService: WebApplicationConfigurationService) {
  }

  ngOnInit() {
    this.webApplicationConfigurationService.getPublicPropertyValue('public.authentication-config.changePasswordUrl')
      .then(value => {
        if (value) {
          this.canChangePassword = value.startsWith(`${this.authService.accessToken.idToken.iss}/`);
        }
      });
  }

  onLogout() {
    this.authService.logout();
  }

  onChangePassword() {
    this.authService.navigateToChangePasswordPage();
  }

}
