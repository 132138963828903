/**
 * TIR-EPD REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tirepd@iru.org
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type FieldEntity = 'party' | 'vehicle' | 'driver';

export const FieldEntity = {
    Party: 'party' as FieldEntity,
    Vehicle: 'vehicle' as FieldEntity,
    Driver: 'driver' as FieldEntity
};

