<mat-card class="countries-card">
  <mat-card-header fxLayout="row">
    <mat-card-title>
      {{'tir-epd.countries' | trackAndTranslate}}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="countries-card-content">
    <div fxLayout="row" fxLayoutAlign="space-around start">
      <div class="border-left" *ngFor="let columnNumber of [].constructor(totalColumns), let columnNumberIndex = index">
        <div fxLayout="column" *ngFor="let i of [].constructor(defaultRowNumber), let rowIndex = index">
          <span
            *ngIf="countries[columnNumberIndex * defaultRowNumber + rowIndex]; else countriesNotExist">&nbsp;&nbsp;{{'country.'
            + countries[columnNumberIndex * defaultRowNumber + rowIndex] | trackAndTranslate}}
          </span>
          <ng-template #countriesNotExist><span>&nbsp;</span></ng-template>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
