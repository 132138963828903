<div class="layout-container" [dir]="textDir">
  <div class="logo" [routerLink]="['/public/login']"></div>

  <div class="top-bar gradient">
    <div fxFlex fxLayoutAlign="start" style="width: 180px;">
    </div>



    <div fxFlex fxLayoutAlign="end" style="margin-top: 6px;">


      <app-internationalization [publicMode]="true"></app-internationalization>

      <span class="display-name">&nbsp;</span>

    </div>
  </div>


  <div class="banner" style="padding: 30px">

    <div fxFlex fxLayoutAlign="space-between center">
      <div class="welcome">{{ 'welcome' | trackAndTranslate }}</div>
      <div>
        &nbsp;
      </div>
    </div>

  </div>

  <app-error></app-error>

  <router-outlet></router-outlet>
</div>

<app-footer></app-footer>
