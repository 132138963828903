import { Injectable } from '@angular/core';
import {
    BusinessProfileType,
    Code, ContainerSizesTypes, CountryService,
    CurrencyService, CustomsOffice, CustomsUnion,
    EpdService,
    Language,
    LocaleService, MovementCodeApplicability,
    PackagingType, SettingsService, VehicleRegistrationNumberValueCountryPrefixes,
    VehicleTypeAdditionalPropertyValues,
    WebApplicationConfiguration
} from '@api/index';
import { ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { SortService } from './sort.service';
import { URIService } from './uri.service';

/**
 * Cached access to the data that will not change during the user session
 */
@Injectable({
    providedIn: 'root',
})
export class DataReferenceService {

    private static BUSINESS_PROFILE_TYPE_URI_PREFIX = 'http://www.tirepd.org/model/list/business-profile-service-type';

    private _customsOffices: ReplaySubject<CustomsOffice[]>;
    private _countries: ReplaySubject<string[]>;
    private _extendedCountryGroups: ReplaySubject<Map<string, string[]>>;
    private _epdCountriesSendByDefault: ReplaySubject<string[]>;
    private _epdCountries: ReplaySubject<string[]>;
    private _loginEpdCountries: ReplaySubject<string[]>;
    private _customsUnions: ReplaySubject<CustomsUnion[]> = null;
    private _movementCodePerCountry: [string, ReplaySubject<string[]>][] = [];
    private _makes: ReplaySubject<string[]>;
    private _vehicleSubTypes: ReplaySubject<VehicleTypeAdditionalPropertyValues[]>;
    private _vehicleNumberOfAxles: ReplaySubject<VehicleTypeAdditionalPropertyValues[]>;
    private _languages: ReplaySubject<Language[]>;
    private _scriptsPerLanguage: ReplaySubject<Map<string, string[]>>;
    private _defaultScriptPerLanguage: ReplaySubject<Map<string, string>>;
    private _registrationNumberPrefixes: ReplaySubject<VehicleRegistrationNumberValueCountryPrefixes[]>;
    private _containerSizesTypes: ReplaySubject<ContainerSizesTypes>;
    private _webApplicationConfigurations: ReplaySubject<WebApplicationConfiguration>;
    private _publicWebApplicationConfigurations: ReplaySubject<WebApplicationConfiguration>;
    private _currencies: ReplaySubject<string[]>;
    private _masurementUnitCodes: ReplaySubject<Code[]>;
    private _packagingTypes: ReplaySubject<PackagingType[]>;
    private _businessProfileTransportMeanTypeCodes: ReplaySubject<Code[]>;
    private _businessProfileServiceTypeCodesPerBusinessProfileType: ReplaySubject<Map<BusinessProfileType, Code[]>>;
    private _businessProfileServiceTypeCodes: ReplaySubject<Code[]>;

    constructor(private countryService: CountryService,
        private epdService: EpdService,
        private settingService: SettingsService,
        private currencyService: CurrencyService,
        private sortService: SortService,
        private localeService: LocaleService,
        private uriService: URIService) {

    }

    getCustomsOffices(): ReplaySubject<CustomsOffice[]> {
        if (this._customsOffices == null) {
            this._customsOffices = new ReplaySubject<CustomsOffice[]>(1);
            this.epdService.getCustomsOffices().subscribe(customsOffices => this._customsOffices.next(customsOffices),
              e => this._customsOffices.error(e), () => this._customsOffices.complete());
        }
        return this._customsOffices;
    }

    getCountries(): ReplaySubject<string[]> {
        if (this._countries == null) {
            this._countries = new ReplaySubject<string[]>(1);
            this.countryService.getCountries().subscribe(countries => this._countries.next(countries));
        }
        return this._countries;
    }

    getEpdCountriesForLogin(): ReplaySubject<string[]> {
        if (this._loginEpdCountries == null) {
            this._loginEpdCountries = new ReplaySubject<string[]>(1);
            this.countryService.getCountries(true).subscribe(epdCountries => this._loginEpdCountries.next(epdCountries));
        }
        return this._loginEpdCountries;
    }

    getExtendedCountryGroups(): ReplaySubject<Map<string, string[]>> {
        if (this._extendedCountryGroups == null) {
            this._extendedCountryGroups = new ReplaySubject<Map<string, string[]>>(1);
            this.countryService.getExtendedCountryGroups()
                .pipe(
                    map(result => {
                        const resultMap: Map<string, string[]> = new Map();
                        for (const key in result) {
                            if (result.hasOwnProperty(key)) {
                                resultMap.set(key, result[key]);
                            }
                        }
                        return resultMap;
                    })
                )
                .subscribe(extendedCountryGroups => this._extendedCountryGroups.next(extendedCountryGroups));
        }
        return this._extendedCountryGroups;
    }


    getEpdCountriesSendByDefault(): ReplaySubject<string[]> {

        if (this._epdCountriesSendByDefault == null) {
            this._epdCountriesSendByDefault = new ReplaySubject<string[]>(1);
            this.countryService.getCountriesInEPD(true).subscribe(countries => this._epdCountriesSendByDefault.next(countries));
        }
        return this._epdCountriesSendByDefault;

    }

    getEpdCountries(): ReplaySubject<string[]> {


        if (this._epdCountries == null) {
            this._epdCountries = new ReplaySubject<string[]>(1);
            this.countryService.getCountriesInEPD().subscribe(countries => this._epdCountries.next(countries));
        }
        return this._epdCountries;

    }


    getCustomsUnions(): ReplaySubject<CustomsUnion[]> {

        if (this._customsUnions == null) {
            this._customsUnions = new ReplaySubject<CustomsUnion[]>(1);
            this.countryService.getCustomsUnions().subscribe(customsUnions => this._customsUnions.next(customsUnions));
        }
        return this._customsUnions;
    }

    getMovementCodesPerCountry(countryCode: string): ReplaySubject<string[]> {

        if (this._movementCodePerCountry[countryCode] == null) {
            this._movementCodePerCountry[countryCode] = new ReplaySubject<string[]>(1);

            this.epdService.getMovementCodes(MovementCodeApplicability.Operation, countryCode)
                .subscribe(movementCodes => this._movementCodePerCountry[countryCode].next(movementCodes));
        }
        return this._movementCodePerCountry[countryCode];
    }


    getVehicleMakes(): ReplaySubject<string[]> {

        if (this._makes == null) {
            this._makes = new ReplaySubject<string[]>(1);
            this.epdService.getVehicleMakes().subscribe(makes => this._makes.next(makes));
        }
        return this._makes;
    }


    getVehicleSubTypes(): ReplaySubject<VehicleTypeAdditionalPropertyValues[]> {

        if (this._vehicleSubTypes == null) {
            this._vehicleSubTypes = new ReplaySubject<VehicleTypeAdditionalPropertyValues[]>(1);
            this.epdService.getVehicleProperties('SubType')
                .subscribe(vehicleTypeAdditionalPropertyValues => this._vehicleSubTypes.next(vehicleTypeAdditionalPropertyValues));
        }
        return this._vehicleSubTypes;
    }


    getVehicleNumberOfAxles(): ReplaySubject<VehicleTypeAdditionalPropertyValues[]> {

        if (this._vehicleNumberOfAxles == null) {
            this._vehicleNumberOfAxles = new ReplaySubject<VehicleTypeAdditionalPropertyValues[]>(1);
            this.epdService.getVehicleProperties('NumberOfAxles')
                .subscribe(vehicleTypeAdditionalPropertyValues => this._vehicleNumberOfAxles.next(vehicleTypeAdditionalPropertyValues));
        }
        return this._vehicleNumberOfAxles;
    }


    getLanguages(): ReplaySubject<Language[]> {

        if (this._languages == null) {
            this._languages = new ReplaySubject<Language[]>(1);
            this.localeService.getLanguages().subscribe(languages => this._languages.next(languages), (err) => this._languages.error(err));
        }
        return this._languages;
    }


    getScriptsPerLanguage(): ReplaySubject<Map<string, string[]>> {
        if (this._scriptsPerLanguage == null) {
            this._scriptsPerLanguage = new ReplaySubject<Map<string, string[]>>(1);
            this.getLanguages().subscribe(languages => {
                const scriptsPerLanguage: Map<string, string[]> = new Map();
                languages.forEach(language => {
                    scriptsPerLanguage.set(language.code, []);

                    if (language.defaultScript != null) {
                        scriptsPerLanguage.get(language.code).push(language.defaultScript);
                    }

                    if (language.otherScripts != null) {
                        language.otherScripts.forEach(otherScript => {
                            if (!scriptsPerLanguage.get(language.code).includes(otherScript)) {
                                scriptsPerLanguage.get(language.code).push(otherScript);
                            }
                        });
                    }
                    this._scriptsPerLanguage.next(scriptsPerLanguage);
                });

            });
        }
        return this._scriptsPerLanguage;
    }


    getDefaultScriptPerLanguage(): ReplaySubject<Map<string, string>> {

        if (this._defaultScriptPerLanguage == null) {
            this._defaultScriptPerLanguage = new ReplaySubject<Map<string, string>>(1);
            this.getLanguages().subscribe(languages => {
                const defaultScriptPerLanguage: Map<string, string> = new Map();
                languages.forEach(language => {
                    if (language.defaultScript != null) {
                        defaultScriptPerLanguage.set(language.code, language.defaultScript);
                    }
                });
                this._defaultScriptPerLanguage.next(defaultScriptPerLanguage);
            });
        }
        return this._defaultScriptPerLanguage;
    }


    getRegistrationNumberPrefixes(): ReplaySubject<VehicleRegistrationNumberValueCountryPrefixes[]> {

        if (this._registrationNumberPrefixes == null) {
            this._registrationNumberPrefixes = new ReplaySubject<VehicleRegistrationNumberValueCountryPrefixes[]>(1);
            this.epdService.getRegistrationNumberPrefixes()
                .subscribe(vehicleRegistrationNumberValueCountryPrefixes =>
                    this._registrationNumberPrefixes.next(vehicleRegistrationNumberValueCountryPrefixes));
        }
        return this._registrationNumberPrefixes;
    }

    getContainerSizesTypes(): ReplaySubject<ContainerSizesTypes> {

        if (this._containerSizesTypes == null) {
            this._containerSizesTypes = new ReplaySubject<ContainerSizesTypes>(1);
            this.epdService.getContainerSizesTypes().subscribe(containerSizesTypes => this._containerSizesTypes.next(containerSizesTypes));
        }
        return this._containerSizesTypes;

    }

    getWebApplicationConfiguration(): ReplaySubject<WebApplicationConfiguration> {
        if (this._webApplicationConfigurations == null) {
            this._webApplicationConfigurations = new ReplaySubject<WebApplicationConfiguration>(1);
            this.settingService.getWebApplicationConfiguration().subscribe(webApplicationConfigurations =>
                this._webApplicationConfigurations.next(webApplicationConfigurations));
        }
        return this._webApplicationConfigurations;
    }

    getWebApplicationPublicConfiguration(): ReplaySubject<WebApplicationConfiguration> {
        if (this._publicWebApplicationConfigurations == null) {
            this._publicWebApplicationConfigurations = new ReplaySubject<WebApplicationConfiguration>(1);
            this.settingService.getWebApplicationPublicConfiguration()
                .pipe(map(stringMap => {
                    const webApplicationConfigurations: WebApplicationConfiguration = {
                        additionalItems: stringMap
                    };
                    return webApplicationConfigurations;
                }))
                .subscribe(webApplicationConfigurations =>
                    this._publicWebApplicationConfigurations.next(webApplicationConfigurations),
                    err => {
                        console.error('Unable to fetch webApplicationConfigurations');
                        this._publicWebApplicationConfigurations.next(null);
                    });
        }
        return this._publicWebApplicationConfigurations;
    }



    getCurrencies(): ReplaySubject<string[]> {
        if (this._currencies == null) {
            this._currencies = new ReplaySubject<string[]>(1);
            this.currencyService.getCurrencies().subscribe(currencies => {
                this._currencies.next(this.sortService.sortCurrenciesAlphabetically(currencies));
            });
        }
        return this._currencies;
    }

    getMeasurementUnitCodes(): ReplaySubject<Code[]> {
        if (this._masurementUnitCodes == null) {
            this._masurementUnitCodes = new ReplaySubject<Code[]>(1);
            this.epdService.getMeasurementUnitCodes().subscribe(unitCodes => {
                this._masurementUnitCodes.next(unitCodes);
            });
        }
        return this._masurementUnitCodes;
    }


    getPackagingTypes(): ReplaySubject<PackagingType[]> {

        if (this._packagingTypes == null) {
            this._packagingTypes = new ReplaySubject<PackagingType[]>(1);
            this.epdService.getTransportHandlingUnitPackagingTypes().subscribe(packagingTypes => this._packagingTypes.next(packagingTypes));
        }
        return this._packagingTypes;
    }

    getBusinessProfileTransportMeanTypeCodes(): ReplaySubject<Code[]> {
        if (this._businessProfileTransportMeanTypeCodes == null) {
            this._businessProfileTransportMeanTypeCodes = new ReplaySubject<Code[]>(1);
            this.settingService.getBusinessProfileTransportMeanTypeCodes().subscribe(businessProfileTransportMeanTypeCodes =>
                this._businessProfileTransportMeanTypeCodes.next(businessProfileTransportMeanTypeCodes));
        }
        return this._businessProfileTransportMeanTypeCodes;
    }

    getBusinessProfileServiceTypeCodesPerBusinessProfileType(): ReplaySubject<Map<BusinessProfileType, Code[]>> {
        if (this._businessProfileServiceTypeCodesPerBusinessProfileType == null) {
            this._businessProfileServiceTypeCodesPerBusinessProfileType = new ReplaySubject<Map<BusinessProfileType, Code[]>>(1);

            this.settingService.getBusinessProfileServiceTypeCodes().subscribe(businessProfileServiceTypeCodes => {
                const codesMap = new Map<BusinessProfileType, Code[]>();
                businessProfileServiceTypeCodes.forEach(businessProfileServiceTypeCode => {

                    const queryParameters: Map<string, string> = this.uriService.queryParameters(businessProfileServiceTypeCode.listURI,
                        DataReferenceService.BUSINESS_PROFILE_TYPE_URI_PREFIX);

                    queryParameters.forEach((value: string, key: BusinessProfileType) => {
                        if (Object.values(BusinessProfileType)
                            .filter(businessProfileTypeValue => businessProfileTypeValue.valueOf() === key).length > 0) {

                            if (codesMap.get(key) == null) {
                                codesMap.set(key, [businessProfileServiceTypeCode]);
                            } else {
                                codesMap.get(key).push(businessProfileServiceTypeCode);
                            }
                        }
                    });


                });

                this._businessProfileServiceTypeCodesPerBusinessProfileType.next(codesMap);

            });
        }
        return this._businessProfileServiceTypeCodesPerBusinessProfileType;
    }

    getBusinessProfileServiceTypeCodes(): ReplaySubject<Code[]> {
        if (this._businessProfileServiceTypeCodes == null) {
            this._businessProfileServiceTypeCodes = new ReplaySubject<Code[]>(1);
            this.settingService.getBusinessProfileServiceTypeCodes().subscribe(businessProfileServiceTypeCodes =>
                this._businessProfileServiceTypeCodes.next(businessProfileServiceTypeCodes));
        }
        return this._businessProfileServiceTypeCodes;
    }

}
