import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { SharedModule } from '@app/shared';
import { ErrorComponent } from './components/error/error.component';
import { InternationalizationComponent } from './components/internationalization/internationalization.component';
import { SelectScopeDialogComponent } from './components/select-scope/select-scope-dialog.component';
import { throwIfAlreadyLoaded } from './guards/module-import.guard';
import { NewUserRouteGuard } from './guards/new-user-route.guard';
import { PendingChangesGuard } from './guards/pending-changes.guard';
import { RouteGuard } from './guards/route.guard';
import { UserContextService } from './services/user-context.service';




@NgModule({
  declarations: [InternationalizationComponent, ErrorComponent, SelectScopeDialogComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  providers: [
    RouteGuard,
    NewUserRouteGuard,
    PendingChangesGuard,
    UserContextService
  ],
  exports: [InternationalizationComponent, ErrorComponent]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
