/**
 * TIR-EPD REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tirepd@iru.org
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type OrderResponseStatus = 'pending' | 'paid' | 'cancelled' | 'approved' | 'rejected' | 'sentForRefund' | 'refunded';

export const OrderResponseStatus = {
    Pending: 'pending' as OrderResponseStatus,
    Paid: 'paid' as OrderResponseStatus,
    Cancelled: 'cancelled' as OrderResponseStatus,
    Approved: 'approved' as OrderResponseStatus,
    Rejected: 'rejected' as OrderResponseStatus,
    SentForRefund: 'sentForRefund' as OrderResponseStatus,
    Refunded: 'refunded' as OrderResponseStatus
};

