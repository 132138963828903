import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared';
import { DisabledUserComponent } from './disabled-user/disabled-user.component';
import { EmailValidationComponent } from './email-validation/email-validation.component';
import { AuthenticationComponent } from './login/authentication/authentication.component';
import { CountriesComponent } from './login/countries/countries.component';
import { LoginComponent } from './login/login.component';
import { NewUserComponent } from './login/new-user/new-user.component';
import { PublicRoutingModule } from './public.routing';


@NgModule({
  declarations: [
    LoginComponent,
    EmailValidationComponent,
    AuthenticationComponent,
    NewUserComponent,
    CountriesComponent,
    DisabledUserComponent
  ],
  imports: [
    CommonModule,
    PublicRoutingModule,
    SharedModule
  ]
})
export class PublicModule { }
