import { Organisation } from '@api/index';



export class UserTenant {
    type: Organisation.TypeEnum;
    countryCode: string;
    associationId: number;
    tenant: string;
    checkDigit: string;
    tenantId: string;
}
