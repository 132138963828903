/**
 * TIR-EPD REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tirepd@iru.org
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ItemCategory = 'tobbundttoken' | 'epdtoken' | 'gdatoken' | 'guarantee' | 'despatchService' | 'stampduty' | 'other';

export const ItemCategory = {
    Tobbundttoken: 'tobbundttoken' as ItemCategory,
    Epdtoken: 'epdtoken' as ItemCategory,
    Gdatoken: 'gdatoken' as ItemCategory,
    Guarantee: 'guarantee' as ItemCategory,
    DespatchService: 'despatchService' as ItemCategory,
    Stampduty: 'stampduty' as ItemCategory,
    Other: 'other' as ItemCategory
};

