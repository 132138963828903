import { Injectable } from '@angular/core';
import { BusinessProfileType, Organisation, SettingsService, UserApplicationSettings, UserProfile as ApplicationUserProfile } from '@api/index';
import { UserTenant } from '@app/shared/models/userTenant';
import { OrganisationService as CachedOrganisationService, OrganisationService } from '@app/shared/services/organisation.service';
import { ReplaySubject } from 'rxjs';
import { UserProfile } from './auth-auth.service';


@Injectable({
  providedIn: 'root'
})
export class UserContextService {

  private _organisation = new ReplaySubject<Organisation>(1);
  private _userProfile: ReplaySubject<ApplicationUserProfile> = null;

  private _user: UserProfile;
  private _tenant: UserTenant;
  private _businessProfileType: BusinessProfileType;

  constructor(private organisationService: OrganisationService,
    public cachedOrganisationService: CachedOrganisationService,
    private settingService: SettingsService) {
    this._tenant = new UserTenant();
  }

  get user(): UserProfile {
    return this._user;
  }

  setDisabledUser(user: UserProfile): Promise<void> {
    return new Promise(resolve => {
      this._user = user;
      this._organisation.next(null);
      resolve(null);
    });
  }

  setUser(user: UserProfile): Promise<void> {
    return new Promise((resolve, reject) => {
      if (user && user.tenant !== this._user?.tenant) {
        this._user = user;
        this.organisationService.getUserOrganisation(user, false).subscribe({
          next: organisation => {

            this._businessProfileType = this.cachedOrganisationService.getBusinessProfileType(organisation);

            this.parseUserTenant(user).then(() => {
              if (this._tenant.type === Organisation.TypeEnum.Association
                && organisation.party.localizedParties
                && organisation.party.localizedParties[0]
                && organisation.party.localizedParties[0].location) {
                this._tenant.countryCode = organisation.party.localizedParties[0].location.countryCode;
              }
              this._organisation.next(organisation);

              resolve(null);
            });

          },
          error: err => {

            this.parseUserTenant(user).then(() => {
              if (this._tenant.type === Organisation.TypeEnum.Association) {
                this._tenant.countryCode = undefined;
              }
              this._organisation.error(err);

              reject(null);
            });

          }
        });
      } else {
        resolve(null);
      }
    });
  }

  public parseUserTenant(user): Promise<void> {
    return new Promise(resolve => {
      const t = this._tenant;
      let matches;
      if (matches = /([A-Z]{3})\/([\d]{3})\/([\d]+)/gm.exec(user.tenant)) {

        t.type = Organisation.TypeEnum.Haulier;
        t.tenant = user.tenant;
        t.countryCode = matches[1];
        t.associationId = +matches[2];
        t.tenantId = matches[3];

      } else if (matches = /([A-Z]{2})([\d]{3})([\d]+)([A-Z]{1})/gm.exec(user.tenant)) {

        t.type = Organisation.TypeEnum.ThirdParty;
        t.tenant = user.tenant;
        t.countryCode = matches[1];
        t.associationId = +matches[2];
        t.tenantId = matches[3];
        t.checkDigit = matches[4].charAt(0);

      } else if (matches = /([\d]+)/gm.exec(user.tenant)) {

        t.type = Organisation.TypeEnum.Association;
        t.associationId = +matches[1];
        t.tenant = user.tenant;

      } else if (/(IRU)/gm.exec(user.tenant)) {

        t.type = Organisation.TypeEnum.Iru;
        t.associationId = 1;
        t.tenant = user.tenant;

      } else {
        t.type = Organisation.TypeEnum.Other;
        t.tenant = user.tenant;
      }

      resolve(null);
    });
  }

  get tenant(): UserTenant {
    return this._tenant;
  }

  get organisation(): ReplaySubject<Organisation> {
    return this._organisation;
  }

  get businessProfileType(): BusinessProfileType {
    return this._businessProfileType;
  }

  setOrganisation(organisation: Organisation) {
    this._organisation.next(organisation);
  }

  public reloadOrganisation(): Promise<Organisation> {
    const promise = new Promise<Organisation>(resolve => {
      this.organisationService.getUserOrganisation(this._user, true).subscribe(organisation => {

        this._organisation.next(organisation);

        resolve(organisation);
      });
    });

    return promise;
  }

  hasPermission(permission: string): boolean {
    return this._user.profile && this._user.profile.filter(profile => profile === permission).length > 0;
  }

  isDisabled(): boolean {
    return this._user.profile == null;
  }

  get userProfile(): ReplaySubject<ApplicationUserProfile> {

    if (this._userProfile == null) {
      this._userProfile = new ReplaySubject<ApplicationUserProfile>(1);
      this.settingService.getProfile().subscribe(userProfile => this._userProfile.next(userProfile));
    }
    return this._userProfile;
  }

  /*
  * Update user settings and refresh the cache
  */
  patchAppSettings(userApplicationSettings: UserApplicationSettings) {
    this.settingService.patchAppSettings(userApplicationSettings).subscribe(() => {
      if (this._userProfile == null) {
        this._userProfile = new ReplaySubject<ApplicationUserProfile>(1);
      }
      this.settingService.getProfile().subscribe(userProfile => this._userProfile.next(userProfile));
    });
  }
}
