<ngx-mat-calendar cdkTrapFocus [id]="datepicker.id" [ngClass]="datepicker.panelClass" [startAt]="datepicker.startAt"
    [startView]="datepicker.startView" [minDate]="datepicker._minDate" [maxDate]="datepicker._maxDate"
    [dateFilter]="datepicker._dateFilter" [headerComponent]="datepicker.calendarHeaderComponent"
    [selected]="datepicker._selected" [dateClass]="datepicker.dateClass" [@fadeInCalendar]="'enter'"
    (selectedChange)="datepicker.select($event)" (yearSelected)="datepicker._selectYear($event)"
    (monthSelected)="datepicker._selectMonth($event)">
</ngx-mat-calendar>
<ng-container *ngIf="isViewMonth">
    <div *ngIf="!datepicker._hideTime" class="time-container" [class.disable-seconds]="!datepicker._showSeconds">
        <ngx-mat-timepicker [showSpinners]="datepicker._showSpinners" [showSeconds]="datepicker._showSeconds"
            [disabled]="datepicker._disabled" [stepHour]="datepicker._stepHour" [stepMinute]="datepicker._stepMinute"
            [stepSecond]="datepicker._stepSecond" [(ngModel)]="datepicker._selected" [color]="datepicker._color"
            [enableMeridian]="datepicker._enableMeridian" [disableMinute]="datepicker._disableMinute">
        </ngx-mat-timepicker>
    </div>
    <div class="actions">
        <button mat-button (click)="datepicker.ok()" mat-stroked-button [color]="datepicker._color" cdkFocusInitial
            [disabled]="!valid">
            <mat-icon *ngIf="!datepicker._customIcon">done</mat-icon>
            <ng-template [cdkPortalOutlet]="_templateCustomIconPortal"></ng-template>
        </button>
    </div>
</ng-container>