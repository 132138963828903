import { Component, OnInit } from '@angular/core';
import { DataReferenceService } from '@app/shared/services/data-reference.service';
import { SortService } from '@app/shared/services/sort.service';
import { TranslateService } from '@app/shared/services/translate.service';
import { LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-login-countries',
  templateUrl: './countries.component.html',
  styleUrls: ['./countries.component.scss']
})
export class CountriesComponent implements OnInit {

  public countries: string[] = [];
  public readonly defaultRowNumber: number = 8;
  public totalColumns: number;

  constructor(
    private dataReferenceService: DataReferenceService,
    private sortService: SortService,
    private translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.dataReferenceService.getEpdCountriesForLogin().subscribe((countries) => {
      this.countries = this.sortService.sortCountriesAlphabetically(countries);
      this.totalColumns = Math.ceil(this.countries.length / this.defaultRowNumber);
      this.translateService.onLangChange.subscribe((params: LangChangeEvent) => {
        this.countries = this.sortService.sortCountriesAlphabetically(countries);
        this.totalColumns = Math.ceil(this.countries.length / this.defaultRowNumber);
      });
    });
  }

}

