import { Injectable } from '@angular/core';
import { PackagingType } from '@api/index';
import { TranslateService } from './translate.service';

@Injectable({
    providedIn: 'root'
})
export class SortService {

    constructor(private translateService: TranslateService) { }

    public sortLanguagesAlphabetically(unsortedListOfLanguage: string[]): string[] {
        return this.sortListAlphabetically('locale.', unsortedListOfLanguage);
    }

    public sortCountriesAlphabetically(unsortedListOfCountries: string[]): string[] {
        return this.sortListAlphabetically('country.', unsortedListOfCountries);
    }

    public sortCurrenciesAlphabetically(unsortedListOfCurrencies: string[]): string[] {
        return this.sortListAlphabetically('currency.', unsortedListOfCurrencies);
    }

    public sortPackagingTypesAlphabetically(unsortedListOfPackagingTypes: PackagingType[]): PackagingType[] {
        const sortedList: PackagingType[] = [...unsortedListOfPackagingTypes].sort((a, b) =>
            this.sortAlphabetically(`dynamic.transporthandlingunitpackagingtype.${a.packagingTypeCode}`,
                `dynamic.transporthandlingunitpackagingtype.${b.packagingTypeCode}`)
        );
        return sortedList;
    }

    public sortListAlphabetically(translationPrefix: string, unsortedList: string[]): string[] {
        const sortedList: string[] = [...unsortedList].sort((a, b) =>
            this.sortAlphabetically(`${translationPrefix}${a}`, `${translationPrefix}${b}`)
        );
        return sortedList;
    }

    public sortAlphabetically(a: string, b: string) {
        const atrans = <string>this.translateService.instant(a);
        const btrans = <string>this.translateService.instant(b);
        return atrans.localeCompare(btrans);
    }
}
