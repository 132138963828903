import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@app/shared/services/translate.service';
import { WebApplicationConfigurationService } from '@app/shared/services/web-application-configuration.service';
import { LangChangeEvent } from '@ngx-translate/core';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { forkJoin } from 'rxjs';
import { IdentityProviderService } from '../services/identity-provider.service';

@Injectable()
export class AuthServiceProvider {
    public constructor(private oauthService: OAuthService,
        private webApplicationConfigurationService: WebApplicationConfigurationService,
        private identityProviderService: IdentityProviderService,
        private router: Router,
        private location: Location,
        private translateService: TranslateService) {
    }

    public load(): Promise<void> {
        return new Promise((resolve) => {

            const authCodeFlowConfig: AuthConfig = {};
            forkJoin([
                this.webApplicationConfigurationService.getPublicPropertyValue(this.identityProviderService.getConfigurationKey('revocationEndpoint'), null),
                this.webApplicationConfigurationService.getPublicPropertyValue(this.identityProviderService.getConfigurationKey('tokenEndpoint'), null),
                this.webApplicationConfigurationService.getPublicPropertyValue(this.identityProviderService.getConfigurationKey('issuer'), null),
                this.webApplicationConfigurationService.getPublicPropertyValue(this.identityProviderService.getConfigurationKey('clientId'), null),
                this.webApplicationConfigurationService.getPublicPropertyValue('public.authentication-config.responseType', null),
                this.webApplicationConfigurationService.getPublicPropertyValue('public.authentication-config.showDebugInformation', null),
                this.webApplicationConfigurationService.getPublicPropertyValue('public.authentication-config.timeoutFactor', null),
                this.webApplicationConfigurationService.getPublicPropertyValue('public.authentication-config.requireHttps', null)
            ])
                .subscribe(([revocationEndpoint, tokenEndpoint, issuer, clientId, responseType, showDebugInformation, timeoutFactor, requireHttps]) => {
                    authCodeFlowConfig.issuer = issuer;
                    authCodeFlowConfig.clientId = clientId;
                    authCodeFlowConfig.revocationEndpoint = revocationEndpoint;
                    authCodeFlowConfig.tokenEndpoint = tokenEndpoint;
                    authCodeFlowConfig.redirectUri = this.getRedirectURI();
                    authCodeFlowConfig.responseType = responseType;
                    authCodeFlowConfig.showDebugInformation = showDebugInformation === 'true' ? true : false;
                    authCodeFlowConfig.timeoutFactor = parseFloat(timeoutFactor);
                    authCodeFlowConfig.requireHttps = requireHttps === "true";

                    this.oauthService.configure(authCodeFlowConfig);
                    this.oauthService.setupAutomaticSilentRefresh({}, 'access_token');

                    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
                        this.oauthService.customQueryParams = {
                            'ui_locales': event.lang
                        };
                    });

                    resolve();
                });
        });
    }

    public getRedirectURI(): string {
        const idp = sessionStorage.getItem('idp') ? `?i=${sessionStorage.getItem('idp')}` : '';
        return window.location.origin + this.location.prepareExternalUrl(this.router.createUrlTree([`/home`]).toString()) + idp;
    }

    public getPostLogoutRedirectURI(): string {
        return window.location.origin + this.location.prepareExternalUrl(this.router.createUrlTree([`/public/login`]).toString());
    }
}
